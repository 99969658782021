import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ReportService} from '../../services/report.service';
import { ReportModel, ReportCategoryModel, ReportCategoryListModel,} from '../../models/reports.model';
import { MembershipService } from 'app/core/services/membership.service';
import { SessionModel } from 'app/models/session.model';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { ConfigService } from 'app/services/config.service';
import { EnvironmentType } from 'app/enums/environment-type.enum';
import { faLowVision } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss'],
})

export class ReportsComponent extends NexusComponent {
    public session: SessionModel = null;
    public get_model: any  = [];
    public report_category_list:ReportCategoryListModel;
    public demo_environment: boolean;
    public faLowVision = faLowVision;
    
    constructor(private route: ActivatedRoute, private config: ConfigService, public membership: MembershipService, private reports_service: ReportService) {
       super();
       this.demo_environment = this.config.getEnvironmentType() == EnvironmentType.Demo;
       this.event_service.on('session-start', (model) => {
           this.session = model;
       });
       this.event_service.on('session-end', () => {
           this.session = null;
       });
    }

    ngOnInit() {
        this.membership.GetSession().subscribe(
            data => {
              this.session = data;
            },
            error => {
              this.session = null;
            });
            this.route.data.subscribe(({ report_category_list }) => {
                this.report_category_list = report_category_list;                
            });
    }
}


