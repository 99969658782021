import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardResolver } from './resolvers/dashboard.resolver';
import { ResidentsResolver } from './resolvers/residents.resolver';
import { MoveInResolver } from './resolvers/move-in.resolver';
import { ResidentResolver } from './resolvers/resident.resolver';
import { InvoicesResolver } from './resolvers/invoices.resolver';
import { PreBillHostComponent } from './components/pre-bill-host/pre-bill-host.component';
import { ErrorComponent } from './components/error/error.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { BookingComponent } from './components/booking/booking.component';
import { ResidentsComponent } from './components/residents/residents.component';
import { MoveInComponent } from './components/move-in/move-in.component';
import { ResidentComponent } from './components/resident/resident.component';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { UtilitiesComponent } from './components/utilities/utilities.component';
import { ReportsComponent } from './components/reports/reports.component';
import { ReportComponent } from './components/report/report.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { HomeComponent } from './components/home/home.component';
import { AuthenticationGuard } from './core/guards/authentication.guard';
import { WorkflowHostComponent } from './components/workflow-host/workflow-host.component';
import { PreBillStepsResolver } from './resolvers/pre-bill-steps.resolver';
import { SettingsParentComponent } from './components/setting-parent/setting-parent.component';
import { GroupsComponent } from './components/groups/groups.component';
import { GroupsResolver } from './resolvers/groups.resolver';
import { CreateGroupComponent } from './components/groups/create-group/create-group.component';
import { RegionsResolver } from './core/resolvers/regions.resolver';
import { CreateGroupResolver } from './resolvers/create-group.resolver';
import { GroupComponent } from './components/groups/group/group.component';
import { GroupResolver } from './resolvers/group.resolver';
import { PropertyFilesComponent } from './components/property-files/property-files.component';
import { PropertyFileComponent } from './components/property-files/property-file/property-file.component';
import { PropertyFilesResolver } from './resolvers/property-files.resolver';
import { PropertyFileResolver } from './resolvers/property-file.resolver';
import { FileDownloadComponent } from './components/file-download/file-download.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { InvoiceResolver } from './resolvers/invoice.resolver';
import { ReportsResolver } from './resolvers/reports.resolver';
import { ReportResolver } from './resolvers/report.resolver';
import { ResidentInvoicesComponent } from './components/invoices/resident-invoices/resident-invoices.component';
import { ResidentInvoicesResolver } from './resolvers/resident-invoices.resolver';
import { ResidentInvoiceResolver } from './resolvers/resident-invoice.resolver';
import { ResidentInvoiceComponent } from './components/invoice/resident-invoice/resident-invoice.component';
import { CustomerComponent } from './components/customer/customer.component';
import { CustomerResolver } from './resolvers/customer.resolver';
import { CustomerInformationComponent } from './components/customer/customer-information/customer-information.component';
import { CustomerGroupsComponent } from './components/customer/customer-groups/customer-groups.component';
import { CustomerEmailsComponent } from './components/customer/customer-emails/customer-emails.component';
import { CustomerSmsMessagesComponent } from './components/customer/customer-sms-messages/customer-sms-messages.component';
import { CustomerPhoneCallsComponent } from './components/customer/customer-phone-calls/customer-phone-calls.component';
import { CustomerEmailsResolver } from './resolvers/customer-emails.resolver';
import { CustomerSmsMessagesResolver } from './resolvers/customer-sms-messages.resolver';
import { CustomerPhoneCallsResolver } from './resolvers/customer-phone-calls.resolver';
import { CustomerGroupsResolver } from './resolvers/customer-groups.resolver';
import { CustomerInvoicesComponent } from './components/customer/customer-invoices/customer-invoices.component';
import { CustomerInvoicesResolver } from './resolvers/customer-invoices.resolver';
import { OnDemandEFBsComponent as OnDemandEFBsComponent } from './components/on-demand-efbs/on-demand-efbs.component';
import { OnDemandEFBsResolver } from './resolvers/on-demand-efbs.resolver';
import { GroupInformationComponent } from './components/groups/group/group-information/group-information.component';
import { GroupInvoicesComponent } from './components/groups/group/group-invoices/group-invoices.component';
import { GroupInvoicesResolver } from './resolvers/group-invoices.resolver';
import { GroupResidentsComponent } from './components/groups/group/group-residents/group-residents.component';
import { GroupCustomersResolver } from './resolvers/group-customers.resolver';
import { GroupAccountsComponent } from './components/groups/group/group-accounts/group-accounts.component';
import { GroupAccountsResolver } from './resolvers/group-accounts.resolver';
import { GroupFutureBillingComponent } from './components/groups/group/group-future-billing/group-future-billing.component';
import { GroupFutureBillingCustomerPortionsResolver } from './resolvers/group-future-billing-customer-portions.resolver';
import { GroupIntegrationsComponent } from './components/groups/group/group-integrations/group-integrations.component';
import { GroupIntegrationsResolver } from './resolvers/group-integrations.resolver';
import { OnDemandEFBsPropertyResolver } from './resolvers/on-demand-efbs-property.resolver';
import { ResourcesComponent } from './components/resources/resources.component';
import { ResourcesResolver } from './resolvers/resources.resolver';
import { ResourceComponent } from './components/resources/resource/resource.component';
import { ResourceResolver } from './resolvers/resource.resolver';
import { CustomerInvoiceItemsResolver } from './resolvers/customer-invoice-items.resolver';

export const routes: Routes = [
  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthenticationGuard],
    resolve: {
      dashboard_model: DashboardResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'booking',
    component: BookingComponent,
    canActivate: [AuthenticationGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'residents',
    component: ResidentsComponent,
    canActivate: [AuthenticationGuard],
    resolve: {
      residents_model: ResidentsResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'groups',
    component: GroupsComponent,
    resolve: {
      groups_model: GroupsResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'groups/new',
    component: CreateGroupComponent,
    canActivate: [AuthenticationGuard],
    resolve: {
      regions_model: RegionsResolver,
      create_group_model: CreateGroupResolver
    },
    data: { title: 'Create Unit' },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'groups/:group_id',
    component: GroupComponent,
    canActivate: [AuthenticationGuard],
    resolve: {
      group_model: GroupResolver
    },
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        redirectTo: 'information', pathMatch: 'full'
      },
      {
        path: 'information',
        component: GroupInformationComponent,
        canActivate: [AuthenticationGuard],
        resolve: {
          regions_model: RegionsResolver,
          create_group_model: CreateGroupResolver,
        },
        runGuardsAndResolvers: 'always',
        data: {
          component: 'GroupInformationComponent'
        }
      },
      {
        path: 'invoices',
        component: GroupInvoicesComponent,
        resolve: {
          invoice_model: GroupInvoicesResolver,
        },
        canActivate: [AuthenticationGuard],
        runGuardsAndResolvers: 'always',
        data: {
          component: 'GroupInvoicesComponent'
        }
      },
      {
        path: 'residents',
        component: GroupResidentsComponent,
        resolve: {
          customers_model: GroupCustomersResolver
        },
        canActivate: [AuthenticationGuard],
        runGuardsAndResolvers: 'always',
        data: {
          component: 'GroupResidentsComponent'
        }
      },
      {
        path: 'accounts',
        component: GroupAccountsComponent,
        resolve: {
          expected_bills_model: GroupAccountsResolver
        },
        canActivate: [AuthenticationGuard],
        runGuardsAndResolvers: 'always',
        data: {
          component: 'GroupAccountsComponent'
        }
      },
      {
        path: 'future-billing',
        component: GroupFutureBillingComponent,
        resolve: {
          provider_bill_portions_model: GroupFutureBillingCustomerPortionsResolver
        },
        canActivate: [AuthenticationGuard],
        runGuardsAndResolvers: 'always',
        data: {
            component: 'GroupFutureBillingComponent'
        }
    },
    {
      path: 'integrations',
      component: GroupIntegrationsComponent,
      canActivate: [AuthenticationGuard],
      runGuardsAndResolvers: 'always',
      resolve: {
        group_integrations_model: GroupIntegrationsResolver
      },
      data: {
          component: 'GroupIntegrationsComponent'
      }
  }
    ]
  },
  {
    path: 'move-in',
    component: MoveInComponent,
    canActivate: [AuthenticationGuard],
    resolve: {
      residents_model: MoveInResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'residents/create',
    component: ResidentComponent,
    canActivate: [AuthenticationGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'residents/:id',
    component: ResidentComponent,
    canActivate: [AuthenticationGuard],
    resolve: {
      resident_model: ResidentResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'customer/:id',
    component: CustomerComponent,
    canActivate: [AuthenticationGuard],
    resolve: {
      customer_model: CustomerResolver
    },
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        redirectTo: 'information', pathMatch: 'full'
      },
      {
        path: 'information',
        component: CustomerInformationComponent,
        canActivate: [AuthenticationGuard],
        resolve: {
        },
        runGuardsAndResolvers: 'always',
        data: {
          component: 'CustomerInformationComponent'
        }
      },
      {
        path: 'groups',
        component: CustomerGroupsComponent,
        resolve: {
         groups_model: CustomerGroupsResolver,
        },
        canActivate: [AuthenticationGuard],
        runGuardsAndResolvers: 'always',
        data: {
          component: 'CustomerGroupsComponent'
        }
      },
      {
        path: 'emails',
        component: CustomerEmailsComponent,
        resolve: {
          emails_model: CustomerEmailsResolver
        },
        canActivate: [AuthenticationGuard],
        runGuardsAndResolvers: 'always',
        data: {
          component: 'CustomerEmailsComponent'
        }
      },
      {
        path: 'sms-messages',
        component: CustomerSmsMessagesComponent,
        resolve: {
          sms_messages_model: CustomerSmsMessagesResolver
        },
        canActivate: [AuthenticationGuard],
        runGuardsAndResolvers: 'always',
        data: {
          component: 'CustomerSmsMessagesComponent'
        }
      },
      {
        path: 'phone-calls',
        component: CustomerPhoneCallsComponent,
        resolve: {
           phone_calls_model: CustomerPhoneCallsResolver
        },
        canActivate: [AuthenticationGuard],
        runGuardsAndResolvers: 'always',
        data: {
            component: 'CustomerPhoneCallsComponent'
        }
    },
    {
      path: 'invoices',
      component: CustomerInvoicesComponent,
      canActivate: [AuthenticationGuard],
      runGuardsAndResolvers: 'always',
      resolve: {
         invoices_model: CustomerInvoicesResolver,
         invoice_items_model: CustomerInvoiceItemsResolver
      },
      data: {
          component: 'CustomerInvoicesComponent'
      }
  }
    ]
  },
  {
    path: 'property-invoices',
    component: InvoicesComponent,
    canActivate: [AuthenticationGuard],
    resolve: {
      invoice_model: InvoicesResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'resident-invoices',
    component: ResidentInvoicesComponent,
    canActivate: [AuthenticationGuard],
    resolve: {
      invoice_model: ResidentInvoicesResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'resident-invoice/:id',
    component: ResidentInvoiceComponent,
    canActivate: [AuthenticationGuard],
    resolve: {
      invoice_model: ResidentInvoiceResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'property-invoice/:id',
    component: InvoiceComponent,
    canActivate: [AuthenticationGuard],
    resolve: {
      invoice_model: InvoiceResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'utilities',
    component: UtilitiesComponent,
    canActivate: [AuthenticationGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [AuthenticationGuard],
    resolve: {
      report_category_list: ReportsResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'report/:report_id',
    component: ReportComponent,
    canActivate: [AuthenticationGuard],
    resolve: {
      report_embed_model: ReportResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'files',
    component: PropertyFilesComponent,
    canActivate: [AuthenticationGuard],
    resolve: {
      property_files_model: PropertyFilesResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'files/:file_id',
    component: PropertyFileComponent,
    canActivate: [AuthenticationGuard],
    resolve: {
      property_file_model: PropertyFileResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'login/:email/:impersonation_id',
    component: LoginComponent,
    data: { hide_nav_bar: true }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { hide_nav_bar: true }
  },
  {
    path: 'logout',
    component: LogoutComponent,
    data: { hide_nav_bar: true }
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: { hide_nav_bar: true }
  },
  {
    path: 'reset-password/:user_id/:reset_id',
    component: ResetPasswordComponent,
    data: { hide_nav_bar: true }
  },
  {
    path: 'download-file/:file_id',
    component: FileDownloadComponent,
  },
  {
    path: 'settings',
    component: SettingsParentComponent,
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./settings/setting.module').then(m => m.SettingModule)
  },
  {
    path: 'pre-bill/:pre_bill_id',
    component: PreBillHostComponent,
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./pre-bill/pre-bill.module').then(m => m.PreBillModule),
    resolve: {
      pre_bill_steps_model: PreBillStepsResolver
    }
  },
  {
    path: 'workflows',
    component: WorkflowHostComponent,
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./workflow/workflow.module').then(m => m.WorkflowModule)
  },
  {
    path: 'on-demand-efbs',
    component: OnDemandEFBsComponent,
    resolve: {
      on_demand_efbs: OnDemandEFBsResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
      path: 'on-demand-efbs/:property_id',
      component: OnDemandEFBsComponent,
      canActivate: [AuthenticationGuard],
      resolve: {
        on_demand_efbs: OnDemandEFBsPropertyResolver
      },
      runGuardsAndResolvers: 'always'
  },
  {
    path: 'resources',
    component: ResourcesComponent,
    canActivate: [AuthenticationGuard],
    resolve: {
      resources_model: ResourcesResolver
    },
    runGuardsAndResolvers: 'always'
  },
{
    path: 'resources/:resource_id',
    component: ResourceComponent,
    canActivate: [AuthenticationGuard],
    resolve: {
      resource_model: ResourceResolver
    },
    runGuardsAndResolvers: 'always'
},
  {
    path: '**',
    component: HomeComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
