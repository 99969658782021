<app-page-header title="Property Invoices"></app-page-header>


<div class="navbar navbar-light bg-light navbar-expand-lg"
    *ngIf="invoice_model.current_invoice && invoice_model.current_invoice.id">
    <span class="navbar-brand">Current Invoice #{{ invoice_model.current_invoice.invoice_number }}</span>
    <button class="navbar-toggler" type="button" (click)="currentInvoiceCollapsed = !currentInvoiceCollapsed">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" [ngbCollapse]="currentInvoiceCollapsed">
        <ul class="navbar-nav mr-auto"></ul>
        <ul class="navbar-nav">
            <li class="nav-item">
                <button kendoButton [primary]="true" [icon]="'pdf'"
                    (click)="createPdf(invoice_model.current_invoice)">Invoice Summary</button>
            </li>
            <li class="ml-2 nav-item">
                <button kendoButton [primary]="true" [icon]="'excel'"
                    (click)="createExcel(invoice_model.current_invoice)">Invoice Details</button>
            </li>
        </ul>
    </div>
</div>
<div class="row card-row" *ngIf="invoice_model.current_invoice && invoice_model.current_invoice.id">
    <div class="col-lg-4">
        <div class="card">
            <div class="card-header">Details</div>
            <div class="card-body">
                <dl class="row">
                    <dt class="col-sm-5">Invoice Number</dt>
                    <dd class="col-sm-7">#{{ invoice_model.current_invoice.invoice_number }}</dd>
                    <dt class="col-sm-5">Date Issued</dt>
                    <dd class="col-sm-7">
                        {{ invoice_model.current_invoice.date_sent | amDateFormat:'M/D/YYYY' }}
                    </dd>
                    <dt class="col-sm-5">Billing Period</dt>
                    <dd class="col-sm-7">
                        {{ invoice_model.current_invoice.billing_period_date | amDateFormat:'MMMM YYYY' }}
                    </dd>
                    <dt class="col-sm-5">Due Date</dt>
                    <dd class="col-sm-7">
                        {{ invoice_model.current_invoice.date_due | amDateFormat:'M/D/YYYY' }}</dd>
                    <dt class="col-sm-5">Invoice Amount</dt>
                    <dd class="col-sm-7">{{ invoice_model.current_invoice.item_amount | sbCurrency }}</dd>
                    <dt class="col-sm-5">Invoice Balance</dt>
                    <dd class="col-sm-7">{{ invoice_model.current_invoice.balance | sbCurrency }}&nbsp;&nbsp;<span
                            *ngIf="invoice_model.current_invoice.settled"
                            class="badge badge-success">Settled</span><span
                            *ngIf="!invoice_model.current_invoice.settled" class="badge badge-danger">Unsettled</span>
                    </dd>

                </dl>
            </div>
        </div>

    </div>
    <div class="col-lg-4">
        <div class="card">
            <div class="card-header" *ngIf="invoice_model.ach_enabled">ACH Enabled</div>
            <div class="card-header" *ngIf="!invoice_model.ach_enabled">Automatic Payments</div>
            <div class="card-body">
                <p *ngIf="invoice_model.ach_enabled">Great work. This account is set up for ACH.</p>
                <p *ngIf="!invoice_model.ach_enabled">Simplify your payment each month and avoid late fees by setting up
                    ACH. SimpleBills can draft your
                    Invoice Balance each month on the Due Date. Please reach out to your Account Manager with any
                    questions.</p>
                <p *ngIf="!invoice_model.ach_enabled">
                    <a class="btn btn-primary" href="/assets/simplebills-ach-form.pdf" target="_blank">Enroll Now</a>
                </p>
            </div>
        </div>
    </div>
    <div class="col-lg-4">
        <div class="card">
            <div class="card-header">Aging Summary</div>
            <div class="card-body">
                <dl class="row" style="margin-bottom: 0;">
                    <dt class="col-sm-6">Current Balance</dt>
                    <dd class="col-sm-6">{{ invoice_model.current_balance | sbCurrency }}</dd>
                    <dt class="col-sm-6">30-60 Day Balance</dt>
                    <dd class="col-sm-6">{{ invoice_model.balance_30_day | sbCurrency }}</dd>
                    <dt class="col-sm-6">60-90 Day Balance</dt>
                    <dd class="col-sm-6">{{ invoice_model.balance_60_day | sbCurrency }}</dd>
                    <dt class="col-sm-6">90+ Day Balance</dt>
                    <dd class="col-sm-6">{{ invoice_model.balance_90_day | sbCurrency }}</dd>
                </dl>
                <hr style="margin-top: 6px; margin-bottom: 10px;" />
                <dl class="row">
                    <dt class="col-sm-6">Total Balance</dt>
                    <dd class="col-sm-6">{{ invoice_model.total_balance | sbCurrency }}</dd>
                </dl>
            </div>
        </div>
    </div>
</div>


<div class="navbar navbar-expand-lg navbar-light bg-light"
    *ngIf="invoice_model.current_invoice && invoice_model.current_invoice.id">
    <span class="navbar-brand">Previous Invoices</span>
</div>

<div class="previous-invoices">
    <nexus-grid [data]="invoice_model.invoices">
        <kendo-grid-column [width]="120" field="billing_period_date" title="Billing Period" format="{0:MMMM yyyy}"
            filter="date"></kendo-grid-column>
        <kendo-grid-column [width]="100" field="invoice_number" title="Invoice Number" [filter]="'numeric'">
          <ng-template kendoGridCellTemplate let-dataItem>
            <nexus-invoice-link  [invoice_model]="dataItem"></nexus-invoice-link>
        </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="110" field="item_amount" title="Invoice Amount" format="{0:c}" [filter]="'numeric'">
        </kendo-grid-column>
        <kendo-grid-column [width]="110" field="transaction_amount" title="Amount Paid" format="{0:c}"
            [filter]="'numeric'"></kendo-grid-column>
        <kendo-grid-column [width]="110" field="balance" title="Balance Due" format="{0:c}" [filter]="'numeric'">
        </kendo-grid-column>
        <kendo-grid-column [width]="90" field="date_due" title="Due Date" format="{0:MM/dd/yyyy}" filter="date">
        </kendo-grid-column>
        <kendo-grid-column [width]="100" field="settled" title="Settled" [filter]="'boolean'">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span *ngIf="dataItem.settled" class="badge badge-success">Settled</span>
                <span *ngIf="!dataItem.settled" class="badge badge-danger">Unsettled</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="100" field="settled_date" title="Settled On" format="{0:MM/dd/yyyy}"
            filter="date"></kendo-grid-column>

        <kendo-grid-column [width]="150" title="Export" [filterable]="false" [sortable]="false" [columnMenu]="false">
            <ng-template kendoGridCellTemplate let-dataItem>
                <kendo-splitbutton
                [data]="invoice_button_list"
                icon="download"
                (itemClick)="select_invoice_download_type($event,dataItem)"
                (buttonClick)="download_invoice_files(dataItem)"
              >
                Download
              </kendo-splitbutton>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column [width]="150" title="Entrata" [filterable]="false" [sortable]="false" [columnMenu]="false"
            *ngIf="invoice_model.defaults_export_enabled">
            <ng-template kendoGridCellTemplate let-dataItem>
                <kendo-splitbutton
                [data]="entrata_button_list"
                icon="excel"
                (itemClick)="select_entrata_download_type($event,dataItem)"
                (buttonClick)="download_entrata_files(dataItem)"
              >
                Download
              </kendo-splitbutton>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" title="OneSite Defaults" [filterable]="false" [sortable]="false" [columnMenu]="false"
            *ngIf="invoice_model.onesite_export_enabled">
            <ng-template kendoGridCellTemplate let-dataItem>
                <button kendoButton
                icon="excel"
                (click)="oneSiteDefaultsExport(dataItem)"
              >
                Download
              </button>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="100" title="Bill Images" [filterable]="false" [sortable]="false"
        [columnMenu]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
            <ngb-alert [type]="'success'" [dismissible]="false" *ngIf="download_invoice_ids.includes(dataItem.id)">
                Your file is being created, you will receive a link in your e-mail shortly!
            </ngb-alert>
            <button kendoButton [icon]="'pdf'" *ngIf="!download_invoice_ids.includes(dataItem.id)"
                    (click)="downloadBills(dataItem)"></button>
        </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="100" field="default_only" title="Type" [filter]="'boolean'">
          <ng-template kendoGridCellTemplate let-dataItem>
              <span *ngIf="dataItem.default_only" class="badge badge-secondary">Defaults</span>
          </ng-template>
      </kendo-grid-column>
    </nexus-grid>
</div>
