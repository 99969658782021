import { Component, OnInit, Input, ContentChildren, ViewChild, ViewEncapsulation, QueryList, ContentChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import { process, State, GroupDescriptor, FilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { GridComponent, GridDataResult, DataStateChangeEvent, GroupableSettings, ColumnBase, ColumnComponent, RowClassArgs, SelectionEvent } from '@progress/kendo-angular-grid';

const resolvedPromise = Promise.resolve(null); //fancy setTimeout

@Component({
  selector: 'nexus-grid',
  templateUrl: './nexus-grid.component.html',
  styleUrls: ['./nexus-grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NexusGridComponent implements OnInit {
  @ContentChildren(ColumnBase, { descendants: true }) public all_columns: QueryList<ColumnComponent>;
  @ContentChildren(ColumnBase) public columns: QueryList<ColumnComponent>;
  @ViewChild(GridComponent) public grid: GridComponent;

  @ContentChild('detailTemplate') public detailTemplate: TemplateRef<any>;

  @Input() public key_field: string;
  @Input() public selector_field: string;
  @Input() public initial_sort_state = null;
  @Input() public date_range_filter_key: string;
  @Input() public show_toggle:boolean =false;
  @Input() public toggle_label: string;
  @Input() public start_date_place_holder_text: string= "From"
  @Input() public end_date_place_holder_text: string = "To"
  @Input() public action_button_text: string;
  @Input() public action_button_tooltip: string;
  public toggle_value = false;

  @Output() public toggleValueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public actionButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  public excel_columns: any[];
  public pdf_export_tooltip_txt : string = "";
  public grid_data: GridDataResult;
  public button_count: number = 10;
  public state: State = {
    skip: 0,
    take: 50,
    filter: {
      logic: 'and',
      filters: []
    },
    sort: [],
    group: []
  };
  @Input() public multi_sort = false;

  public dynamic_grid_tooltip = false;
  public startRecord() {
    return this.state.skip + 1;
  }

  public endRecord() {
    return this.state.skip + this.grid_data.data.length;
  }

  public model_data: Array<any>;
  @Input('data') set data(data: Array<any>) {
    if (data) {
      this.model_data = data;
      //reset state by skipping to 0
      this.state.skip = 0;
      this.grid_data = process(this.model_data, this.state);
      resolvedPromise.then(() => {
        this.grid.columns.reset(this.columns.toArray());
      });
      if (this.dynamic_grid_tooltip) {
        this.getHeaderElements();
        this.setPdfExportToolTip();
      }
    }
    else if (data === undefined) {
      this.dynamic_grid_tooltip = true;
    }
  }

  @Input('sort') set sort(data: Array<SortDescriptor>) {
    this.state.sort = data;
    this.initial_sort_state = this.state.sort;
    if (this.model_data) {
      this.grid_data = process(this.model_data, this.state);
    }
  }

  @Input("group") public set group(g: Array<GroupDescriptor>) {
    this.state.group = g;
    if (this.model_data) {
      this.grid_data = process(this.model_data, this.state);
    }
  }

  @Input("filters") public set filters(f: Array<FilterDescriptor>) {
    this.state.filter = {
      logic: 'and',
      filters: f
    }
    if (this.model_data) {
      this.grid_data = process(this.model_data, this.state);
    }
  }

  @Input("page_size") public set page_size(s: number) {
    this.state.take = s;
    if (this.model_data) {
      this.grid_data = process(this.model_data, this.state);
    }
  }

  @Input("groupable") public groupable: GroupableSettings;
  @Input("rowClass") public rowClass = (args: RowClassArgs) => ({});
  @Input("selectable") public selectable: any;
  @Output("selectionChange") public selectionChange: EventEmitter<SelectionEvent> = new EventEmitter<SelectionEvent>();

  @Input("showHeader") public showHeader: boolean = true;
  @Input("showFooter") public showFooter: boolean = true;

  @Input("navigable") public navigable: boolean = true;

  constructor() { }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.getHeaderElements();
    this.setPdfExportToolTip();
  }

  public getHeaderElements = function() {
    setTimeout(() => {
      var elements = document.querySelectorAll('.k-header .k-link');
        for (var i = 0; i < elements.length; i++) {
          elements[i].addEventListener('mouseenter',this.getToolTipTitle);
        }
      },100);
  };

  public getToolTipTitle = function(event) {
    this.setAttribute("title",event.currentTarget.innerText);
  };

  public setPdfExportToolTip()
  {
    if(this.isPageUnResponsive())
    {
      this.pdf_export_tooltip_txt = "The contents of the table are too large to export to PDF within the browser, either export the data to Excel or contact your Account Manager for assistance.";
    }
    else
    {
      this.pdf_export_tooltip_txt = "";
    }
  }
  @Output("stateChange") public stateChange: EventEmitter<State> = new EventEmitter<State>();
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    if (this.model_data) {
      if ((this.toggled_page || this.toggled_all) && this.selector_field) {
        for (let item of this.model_data) {
          item[this.selector_field] = false;
        }
        this.toggled_page = false;
        this.toggled_all = false;
      }
      this.grid_data = process(this.model_data, this.state);
      // verify if there are custom sort descriptors and emit state to perform custom sort
      if(this.state.sort.some(x=>x['custom']==true)){
        this.stateChange.emit(this.state);
      }
    }
  }

  public excelData = () => {
    return process(this.model_data, {
      filter: this.state.filter,
      sort: this.state.sort
    });
  }

  public onExcelExport(e: any): void {
    this.excel_columns = [];
    const rows = e.workbook.sheets[0].rows;
    const cols = e.workbook.sheets[0].columns;

    cols.forEach((col) => {
      col.width = 200;
      col.autoWidth = false;
    });

    rows.forEach((row) => {
      if (row.type === 'header') {
        row.cells.forEach((cell) => {
          var column = this.all_columns.find(f => f.title == cell.value && f.hidden == false);
          if (column?.leafIndex || column?.leafIndex == 0)
            this.excel_columns.splice(column?.leafIndex, 0, column);
        });
      }

      if (row.type === 'data') {
        let i = 0;
        row.cells.forEach((cell) => {
          if (this.excel_columns[i]?.format == "{0:c}") {
            cell.format = "$#,##0.00";
          }

          if (typeof cell.value === 'object') {
            var keys = Object.keys(cell.value).sort();
            if (keys.length > 0) {
              var length_keys = keys.length;
              var value = '';
              keys.forEach(key => {
                if (cell.value[key] !== null && cell.value[key] !== undefined) {
                  value += this.getKey(key) + " : null"
                } else {
                  value += this.getKey(key) + " : "
                    + cell.value[key]
                      .replace(/<p>(.*)<\/p>/g, "$1 ")
                      .replace(/<br>(.*)<\/br>/g, "$1 ")
                      .replace(/<br ?\/?>/g, " ")
                      .replace(/<strong>(.*)<\/strong>/g, '$1 ');
                }
                length_keys--;
                value += length_keys > 0 ? ',  ' : '';
              });
              cell.value = value;
              cell.wrap = true;
            }
          }
          i++;
        });
      }
    });
  }

  private getKey(key: string) {
    return key.replace(/_/g, ' ').replace(/-/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase());
  }

  private toggled_all: boolean = false;
  public toggleAll() {
    if (this.model_data && this.selector_field) {
      this.toggled_all = !this.toggled_all;

      for (let item of this.model_data) {
        item[this.selector_field] = this.toggled_all;
      }
    }
  }

  public isPageUnResponsive()
  {
    return this.model_data?.length >= 1000 ? true : false;
  }

  private toggled_page: boolean = false;
  public togglePage() {
    if (this.model_data && this.selector_field) {
      this.toggled_page = !this.toggled_page;
      for (let item of this.grid_data.data) {
        this.model_data.find(f => f[this.key_field] == item[this.key_field])[this.selector_field] = this.toggled_page;
      }
    }
  }

  public multSortChange(event) {
    this.multi_sort = event;
    if (this.initial_sort_state) {
      this.state.sort = this.initial_sort_state;
      this.grid_data=process(this.model_data, this.state)
    }
    if(!this.multi_sort && !this.initial_sort_state){
      this.state.sort=[];
      this.grid_data=process(this.model_data, this.state)
    }
  }

  public onSelectionChange(event) {
    this.selectionChange.emit(event);
  }

  public onToggleValueChange(event){
    this.toggleValueChange.emit(event);
  }

  onActionButtonClicked(event) {
    this.actionButtonClicked.emit(event);
  }
}
