import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerGroupModel } from 'app/models/customer-group.model';
import { CustomerModel } from 'app/models/customer.model';

@Component({
  selector: 'customer-groups',
  templateUrl: './customer-groups.component.html',
  styleUrls: ['./customer-groups.component.scss']
})
export class CustomerGroupsComponent implements OnInit {

  public groups_model: Array<CustomerGroupModel>
  public customer_model: CustomerModel
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.parent.data.subscribe(({ customer_model }) => {
      this.customer_model = customer_model;
      
    });
    this.route.data.subscribe(({ groups_model }) => {
      this.groups_model = groups_model;
    });
  }


}
