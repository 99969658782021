export class ProviderModel
{
    public id: string;
    public name: string;
    public contact: string;
    public email: string;
    public phone: string;
    public utility_url: string;
    public electric_type: string;
    public import_file_type: string;
    public receive_type: string;
    public customer_breakdown_enabled: boolean = false;
    public files_default_hidden: boolean = false;
    public retrieval_wait: number;
    public engie_vendor: string;
    public urjanet_provider: string;
    public default_payment_instructions: string;
    
}