import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ResidentService } from '../services/resident.service';
import { ResidentsModel } from 'app/models/residents.model';

@Injectable()
export class MoveInResolver implements Resolve<ResidentsModel> {

    constructor(private service: ResidentService) 
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<ResidentsModel> {
        return this.service.GetMoveIn();
    }
}
