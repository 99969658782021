import { Component,NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment/moment';
import { saveAs } from 'file-saver';

import { EventService } from '../../core/services/event.service';
import { InvoiceService } from '../../services/invoice.service';
import { DashboardModel } from '../../models/dashboard.model';
import { InvoiceModel } from '../../models/invoices.model';
import { faCalendar, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { ConfigService } from 'app/services/config.service';
import { OrderByPipe } from 'ngx-pipes';
import { SessionModel } from 'app/models/session.model';
import { MembershipService } from '../../core/services/membership.service';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { UserModel } from 'app/models/user.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public dashboard_model: DashboardModel;
  public customer_service_data = [];
  public customer_service_chart: any;
  public faCalendar = faCalendar;
  public faEnvelope = faEnvelope;
  public session: SessionModel = null;
  public viewInit: Boolean =false;

  constructor(private route: ActivatedRoute, private config: ConfigService, private event_service: EventService, private invoice_service: InvoiceService, public membership: MembershipService,private zone: NgZone,private router:Router) {
    this.event_service.on('session-start', (model) => {
      this.session = model;
    });
    this.event_service.on('session-end', () => {
      this.session = null;
    });
  }

  ngOnInit() {
    this.route.data.subscribe(({ dashboard_model }) => {
      this.dashboard_model = dashboard_model;
      this.drawCharts();
    });

    this.membership.GetSession().subscribe(
      data => {
        this.session = data;
      },
      error => {
        this.session = null;
      });
  }

  ngAfterViewInit() {
    this.viewInit=true;
    this.drawCharts();
  }

  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
        if (this.customer_service_chart) {
          this.customer_service_chart.dispose();
        }
    });
  }
  sendUserModel(user_model:UserModel){
      if(user_model){
         this.router.navigate(['/booking',{booking_url:user_model?.booking_url}],{skipLocationChange: true})
      }
  }
  createPdf() {
    this.event_service.loadingStart();

    this.invoice_service.CreatePdf(this.dashboard_model.current_invoice.id).subscribe(
      data => {
        this.event_service.loadingFinish();
        saveAs(data.url, "invoice_" + this.dashboard_model.current_invoice.invoice_number + ".pdf");
      },
      err => {
        this.event_service.loadingFinish();
      });
  }

  addChartSeries(chart :am4charts.XYChart, valuey :string,color :string,labely :string){
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = valuey;
    series.dataFields.categoryX = "day";
    series.tooltip.autoTextColor = false;
    series.tooltip.label.fill = am4core.color(color);
    series.fill = am4core.color(color);
    series.strokeWidth = 0;
    series.clustered = false;
    series.sequencedInterpolation = true;
    series.stacked = true;
    series.tooltip.fontSize=11;
    series.tooltip.fontFamily="Verdana";
    series.tooltip.autoTextColor = false;
    series.tooltip.label.fill = am4core.color('#ffffff');
    series.tooltipHTML = labely+", {categoryX}: <b>{valueY}<b>";
  }

  drawCharts() {
    if (this.viewInit && this.dashboard_model.customer_service && this.dashboard_model.customer_service.day_summaries && this.dashboard_model.customer_service.day_summaries.length > 0) {
      this.customer_service_data = [];

      for (let day of new OrderByPipe().transform(this.dashboard_model.customer_service.day_summaries, ['summary_date'])) {
        this.customer_service_data.push({
          'day': moment(day.summary_date).startOf('day').format('M/D'),
          'call_count': day.call_count,
          'email_count': day.email_count,
          'chat_count': day.chat_count
        });
      }
      
      this.zone.runOutsideAngular(() => {
        let chart = am4core.create("customerservice-chart", am4charts.XYChart);
        chart.data=this.customer_service_data;
        chart.fontSize=11;
        chart.fontFamily="Verdana";
        chart.paddingBottom=0;
        chart.marginBottom=0;
        chart.paddingLeft=0;
        chart.marginLeft=0;
        chart.height=am4core.percent(100);

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = "none";
        chart.cursor.lineY.disabled = true;
        chart.cursor.lineX.disabled = true;

        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "day";
        categoryAxis.renderer.baseGrid.disabled = false;
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.renderer.ticks.template.disabled = false;
        categoryAxis.renderer.ticks.template.strokeOpacity = 1;
        categoryAxis.renderer.ticks.template.stroke = am4core.color("#b2b2b2");
        categoryAxis.renderer.ticks.template.strokeWidth = 1;
        categoryAxis.renderer.ticks.template.length = 20;
        categoryAxis.renderer.ticks.template.location = 0; 
        categoryAxis.renderer.line.strokeOpacity = 1;
        categoryAxis.renderer.line.strokeWidth = 1;
        categoryAxis.renderer.line.stroke = am4core.color("#808080");
        categoryAxis.tooltip.label.fontSize = 11;
        categoryAxis.tooltip.label.fontFamily="Verdana";
        categoryAxis.tooltip.disabled = true;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;
        categoryAxis.cursorTooltipEnabled = false;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min=0;
        valueAxis.renderer.baseGrid.disabled = false;
        valueAxis.renderer.grid.template.disabled = true;
        valueAxis.renderer.ticks.template.disabled = false;
        valueAxis.renderer.ticks.template.strokeOpacity = 1;
        valueAxis.renderer.ticks.template.stroke = am4core.color("#b2b2b2");
        valueAxis.renderer.ticks.template.strokeWidth = 1;
        valueAxis.renderer.ticks.template.length = 5;
        valueAxis.renderer.line.strokeOpacity = 1;
        valueAxis.renderer.line.strokeWidth = 1;
        valueAxis.renderer.line.stroke = am4core.color("#808080");
        valueAxis.tooltip.label.fontSize = 11;
        valueAxis.tooltip.label.fontFamily="Verdana";
        valueAxis.renderer.minGridDistance = 30;
        valueAxis.cursorTooltipEnabled = false;
        
        this.addChartSeries(chart,"call_count","#67b7dc","Calls");
        this.addChartSeries(chart,"email_count","#fdd400","Emails"); 
        this.addChartSeries(chart,"chat_count","#84b761","Chats");

        this.customer_service_chart=chart;
    });

    }
  }

  checkPastDue(invoice: InvoiceModel): boolean {
    var today_date = this.config.getCurrentMoment().startOf('day');
    return today_date.isAfter(invoice.date_due) && !invoice.settled;
  }

  isBookingUrlAvailable(booking_url : string) : boolean
  {
    let available : boolean = true;
    if(booking_url === null || booking_url === undefined || booking_url === "")
    {
      available = false;
    }
    return available;
  }
}
