import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { ProviderBillPortionModel } from 'app/models/provider-bill-portion.model';
import { GroupService } from 'app/services/group.service';
import { Observable } from 'rxjs';

@Injectable()
export class GroupFutureBillingCustomerPortionsResolver implements Resolve<Array<ProviderBillPortionModel>> {

    constructor(private service: GroupService, private router: Router) 
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<Array<ProviderBillPortionModel>> {
        return this.service.GetGroupFutureBilling(route.parent.params.group_id);                
    }
}
