import { Component } from '@angular/core';
import { GroupModel } from 'app/models/group.model';
import { NexusFormResultModel } from 'app/models/nexus-form-result.model';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupService } from 'app/services/group.service';
import { RegionModel } from 'app/models/region.model';
import { GroupOwnerType } from 'app/enums/group-owner-type.enum';
import { EnumModel } from 'app/models/enum.model';
import * as moment from 'moment-business-days';
import { Observable } from 'rxjs';
import { EnumService } from 'app/core/services/enum.service';
import { CreateGroupGetModel } from 'app/models/create-group.model';
import { EventService } from 'app/core/services/event.service';
import { MembershipService } from 'app/core/services/membership.service';
import { SessionModel } from 'app/models/session.model';

@Component({
  selector: 'app-group',
  templateUrl: './group-information.component.html',
  styleUrls: ['./group-information.component.scss']
})
export class GroupInformationComponent extends NexusComponent {
  public regions_model: Array<RegionModel>;
  public create_group_model: CreateGroupGetModel;
  public group_model: GroupModel = new GroupModel();
  public group_result: NexusFormResultModel = new NexusFormResultModel();
  public GroupOwnerType = GroupOwnerType;
  public group_owner_types: Observable<Array<EnumModel>>;
  public is_priority: boolean;
  public session: SessionModel = null;

  public group_form = new UntypedFormGroup({
    address1: this.CreateRequiredFormControl(),
    address2: this.CreateFormControl(),
    city: this.CreateRequiredFormControl(),
    region_id: this.CreateRequiredFormControl(),
    zip5: this.CreateRequiredFormControl(),
    transition_start_date: this.CreateFormControl(),
    notes: this.CreateFormControl(),
    priority: this.CreateFormControl(),
    group_owner_id: this.CreateFormControl(),
    floor_plan_id: this.CreateFormControl(),
    zip4: this.CreateFormControl(),
  });

  constructor(private route: ActivatedRoute, private router: Router, private enum_service: EnumService, private group_service: GroupService, private membership: MembershipService, public event_service: EventService) {
    super();
    this.event_service.on('session-start', (model) => {
      this.session = model;
    });
    this.event_service.on('session-end', () => {
      this.session = null;
    });
    this.group_owner_types = this.enum_service.GetEnum('GroupOwnerType');
  }

  disableForms()
  {
    this.group_form.get('address1').disable();
    this.group_form.get('address1').clearValidators();
    this.group_form.get('address2').disable();
    this.group_form.get('city').disable();
    this.group_form.get('city').clearValidators();
    this.group_form.get('region_id').disable();
    this.group_form.get('region_id').clearValidators();
    this.group_form.get('zip5').disable();
    this.group_form.get('zip5').clearValidators();
    this.group_form.get('zip4').disable();
    this.group_form.get('zip4').clearValidators();
    this.group_form.get('floor_plan_id').disable();
    this.group_form.get('floor_plan_id').clearValidators();
  }

  ngOnInit(): void {

    this.membership.GetSession().subscribe(
      data => {
        this.session = data;
      },
      error => {
        this.session = null;
      });

    this.route.data.subscribe(({regions_model, create_group_model}) => {
      this.regions_model = regions_model;
      this.create_group_model = create_group_model;
     
    });

    this.route.parent.data.subscribe(({ group_model  }) => {
      if(group_model){
        this.group_model = group_model;
        this.group_form.patchValue(this.group_model);
        this.disableForms();
      }
    });

    this.group_form.valueChanges.subscribe((form: any) => {
      this.PopulateModelFromForm(form, this.group_model);
    });

    if(this.session.current_property.market.group_transitions_enabled)
    {     
      this.SetFormControlValidator(this.group_form.get('transition_start_date'), Validators.required);
    }
    
    this.group_form.get('transition_start_date').valueChanges.subscribe((usd: Date) =>{
     
      this.is_priority = usd < moment(this.create_group_model.priority_date).startOf('day').toDate();
      if(this.is_priority)
      {
        this.SetFormControlValidator(this.group_form.get('priority'), Validators.requiredTrue);
      }
      else
      {
        this.SetFormControlValidator(this.group_form.get('priority'), Validators.nullValidator);
      }
    });

  }

  submit(event) {
    this.group_result.Reset();

    this.SetFormTouched(this.group_form);
    if (this.group_form.invalid) {
      this.group_result.Incomplete();
      return;
    }

    this.StartSpinner();
    this.group_service.SaveGroup(this.group_model).subscribe(
      data => {
        this.group_model = data;
        this.StopSpinner();
        this.group_result.Success('Group Saved!');
      },
      err => {
        this.group_result.Error(err);
        this.StopSpinner();
      });
  }
}