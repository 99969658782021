import { Component, OnInit, Input } from '@angular/core';
import { InvoiceModel } from 'app/models/invoices.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'nexus-resident-invoice-link',
  templateUrl: './nexus-resident-invoice-link.component.html',
  styleUrls: ['./nexus-resident-invoice-link.component.scss']
})
export class NexusResidentInvoiceLinkComponent implements OnInit {
  @Input() public invoice_model: InvoiceModel;

  constructor() { }

  ngOnInit() {
    //console.log(this.invoice_model);
  }

}
