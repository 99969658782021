import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, UntypedFormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { EventService } from '../../core/services/event.service';
import { faAt, faKey } from '@fortawesome/free-solid-svg-icons';
import { LoginModel } from 'app/models/login.model';
import { MembershipService } from 'app/core/services/membership.service';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { NexusFormResultModel } from 'app/models/nexus-form-result.model';
import { TokenModel } from 'app/models/token.model';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent extends NexusComponent {
    public login_model: LoginModel = new LoginModel();
    public token_model: TokenModel;
    public form = new UntypedFormGroup({
        email: this.CreateRequiredFormControl(),
        password: this.CreateRequiredFormControl()
    });
    public form_result: NexusFormResultModel = new NexusFormResultModel();


    public faAt = faAt;
    public faKey = faKey;

    constructor(public membership_service: MembershipService, private router: Router, private route: ActivatedRoute) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.form.valueChanges.subscribe((form: any) => {
            this.PopulateModelFromForm(form, this.login_model);
        });

        this.route.paramMap.subscribe(params => {
            if (params.has('email') && params.has('impersonation_id')) {
                this.login_model.email=params.get('email');
                this.login_model.impersonation_id = params.get('impersonation_id');
                this.submit(false);
            }
            
        });
    }
    
    submit(validate=true) {
        this.form_result.Reset();

        if (validate && this.form.invalid) {
            this.form_result.Incomplete();
            return;
        }

        this.StartSpinner();
        this.membership_service.Login(this.login_model).subscribe(
            data => {
                this.token_model = data;
                this.StopSpinner();

                if (data.token) {
                    this.form_result.Success();
                    this.membership_service.SetToken(data);
                    if (this.membership_service.redirectUrl) {
                        var route =this.membership_service.redirectUrl;
                        this.membership_service.redirectUrl=null;
                        this.router.navigate([route]);
                        return;
                    }
                    this.router.navigate(['/']);
                }
            },
            error => {
                this.form_result.Error(error);
                this.StopSpinner();
            });

    }

}
