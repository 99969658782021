import { Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RowClassArgs, SelectionEvent } from '@progress/kendo-angular-grid';
import { NexusGridComponent } from 'app/core/components/nexus-grid/nexus-grid.component';
import { OnDemandEFBChargeModel, OnDemandEFBChargeHistoryDataPointModel, OnDemandEFBModel } from 'app/models/on-demand-efb.model';
import { faChevronLeft, faChevronRight, faFlag } from '@fortawesome/free-solid-svg-icons';
import { OnDemandEFBsService } from 'app/services/on-demand-efbs.service';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { NexusFormResultModel } from 'app/models/nexus-form-result.model';
import { PropertyCustomerModel } from 'app/models/property-customer.model';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SessionModel } from 'app/models/session.model';
import { MembershipService } from 'app/core/services/membership.service';

@Component({
  selector: 'app-on-demand-efbs',
  templateUrl: './on-demand-efbs.component.html',
  styleUrls: ['./on-demand-efbs.component.scss']
})
export class OnDemandEFBsComponent extends NexusComponent {
  @ViewChild(NexusGridComponent) efb_grid: NexusGridComponent;
  @ViewChildren('detailGrid') efb_child_grids: QueryList<NexusGridComponent>;

  public on_demand_efbs: Array<OnDemandEFBModel>;
  public on_demand_efb: OnDemandEFBModel;
  public reject_clicked : boolean = false;

  public chevronLeft = faChevronLeft;
  public chevronRight = faChevronRight;
  public flag = faFlag;

  public nav_position: string;

  public chart_palette: Array<string> = ["#999999", "#003f7d", "#2a9aff", "#018a94"];

  public get allowAllActions(): boolean {
    return this.on_demand_efb?.charges?.filter(x=>x.approval_action == "Pending").length > 0;
  }

  public report_form_result: NexusFormResultModel = new NexusFormResultModel();
  public report_note: string = "";
  session: SessionModel;

  constructor(private route: ActivatedRoute, private service: OnDemandEFBsService, private membership: MembershipService) {
    super();
    this.event_service.on('session-start', (model) => {
      this.session = model;
    });
    this.event_service.on('session-end', () => {
      this.session = null;
    });
  }

  ngOnInit() {
    this.route.data.subscribe(({ on_demand_efbs }) => {
        this.on_demand_efbs = on_demand_efbs;
        this.on_demand_efb = null;

        this.on_demand_efbs.forEach(efb=> {
          efb.resident_count = efb.charges.map(x=>x.portions).map(x=>x.map(y=>y.customer_id)).reduce((p,c)=>{return p.concat(c);}).filter((value, index, self) => { return self.indexOf(value) === index; }).length;

          efb.charges.forEach(charge => {
            charge.approval_note = "";
            charge.portions.forEach(portion => {
              portion.approved_amount = portion.amount;
            });
            charge.form_result = new NexusFormResultModel();

            this.prepChartData(charge);
          });
        });
        if (this.on_demand_efbs?.length > 0) {
          this.on_demand_efbs.sort((a,b) => {
            return this.customerSortString(a.customer_info) > this.customerSortString(b.customer_info) ? 1 :  this.customerSortString(a.customer_info) < this.customerSortString(b.customer_info) ? -1 : 0;
          });
          setTimeout(() => {
            this.selectNext();
            }, 1000);
        }
    });
    this.membership.GetSession().subscribe(
      data => {
        this.session = data;
      },
      error => {
        this.session = null;
      });
  }

  public prepChartData(charge: OnDemandEFBChargeModel) {
    if (charge.chart_data?.length <= 0) {
      return;
    }

    charge.chart_data.sort((a, b) => { return (a.year > b.year || (a.year == b.year && a.month > b.month)) ? 1 : (a.year < b.year || (a.year == b.year && a.month < b.month)) ? -1 : 0; });

    charge.chart_categories = [];
    charge.chart_data.reverse().forEach(x=> {
      if (charge.chart_categories[0] != x.month_label && charge.chart_categories.indexOf(x.month_label) < 0) {
        charge.chart_categories.splice(0,0,x.month_label);
      }
    });

    charge.chart_data.reverse();

    charge.chart_data.sort((a, b) => { return charge.chart_categories.indexOf(a.month_label) > charge.chart_categories.indexOf(b.month_label) ? 1 : (charge.chart_categories.indexOf(a.month_label) < charge.chart_categories.indexOf(b.month_label) ? -1 : 0); });

    charge.cy_chart_data = charge.chart_data.filter(x => x.set_label == 'Current Year');
    charge.py_chart_data = charge.chart_data.filter(x => x.set_label == 'Prior Year');

    charge.cy_chart_data_estimated = charge.cy_chart_data.filter(x => x.estimated);
    charge.cy_chart_data_actual = charge.cy_chart_data.filter(x => !(x.estimated || x.actual_invoiced_unpaid || x.actual_uninvoiced));
    charge.cy_chart_data_actual_invoiced_unpaid = charge.cy_chart_data.filter(x => x.actual_invoiced_unpaid);
    charge.cy_chart_data_actual_uninvoiced = charge.cy_chart_data.filter(x => x.actual_uninvoiced);

    //ensures that the chart column width is not affected by adding empty history periods
     charge.cy_chart_data.filter(x => x.amount == 0).forEach(x => {
      charge.py_chart_data?.push(x);
      charge.cy_chart_data_estimated?.push(x);
      charge.cy_chart_data_actual_invoiced_unpaid?.push(x);
      charge.cy_chart_data_actual_uninvoiced?.push(x);
     });

    charge.py_chart_data.forEach(x => x.value_color = this.chart_palette[0]);
    charge.cy_chart_data_actual.forEach(x => x.value_color = this.chart_palette[1]);
    charge.cy_chart_data_estimated.forEach(x => x.value_color = this.chart_palette[2]);
    charge.cy_chart_data_actual_invoiced_unpaid.forEach(x => x.value_color = this.chart_palette[3]);
    charge.cy_chart_data_actual_uninvoiced.forEach(x => x.value_color = this.chart_palette[3]);

    var cym_chart_data = charge.chart_data[charge.chart_data.length - 1];
    if (charge.chart_data.filter(x => x.year == (cym_chart_data.year - 1) && x.month == cym_chart_data.month && x.amount > 0).length) {
      var cy_average = charge.cy_chart_data.filter(x => x != cym_chart_data).map(x => x.amount).reduce((sum, current) => sum + current, 0) / (charge.cy_chart_data.length - 1);
      if (cym_chart_data.amount > cy_average) {
        charge.increase_warning = true;
      }
    }

    // //apply sample data.  FOR UI TESTING ONLY!!!!!
    // {
    //   charge.py_chart_data = [
    //     { amount: 75.68, year: 2021, month: 10, set_label: 'Prior Year', month_label: 'October', estimated: false, value_color: '' },
    //     { amount: 58.98, year: 2021, month: 11, set_label: 'Prior Year', month_label: 'November', estimated: false, value_color: '' },
    //     { amount: 60.00, year: 2021, month: 12, set_label: 'Prior Year', month_label: 'December', estimated: false, value_color: '' },
    //     { amount: 90.27, year: 2022, month: 1, set_label: 'Prior Year', month_label: 'January', estimated: false, value_color: '' }
    //   ];
    //   charge.cy_chart_data = [
    //     { amount: 71.28, year: 2022, month: 10, set_label: 'Current Year', month_label: 'October', estimated: false, value_color: '' },
    //     { amount: 65.89, year: 2022, month: 11, set_label: 'Current Year', month_label: 'November', estimated: false, value_color: '' },
    //     { amount: 55.27, year: 2022, month: 12, set_label: 'Current Year', month_label: 'December', estimated: false, value_color: '' },
    //     { amount: 98.72, year: 2023, month: 1, set_label: 'Current Year', month_label: 'January', estimated: true, value_color: this.chart_palette[2] }
    //   ];
    // }
  }

  public customerSortString(customer_info: PropertyCustomerModel): string {
    return (customer_info.group.address1 ?? "") + (customer_info.group.address2 ?? "") + (customer_info.customer.name ?? "");
  }

  public rowClass=(args:RowClassArgs) => ({
    'k-deemphasize': (args.dataItem.charges.every(x=>x.approval_action != "Pending"))
  });
  public detailRowClass = (args:RowClassArgs) => ({
    'k-deemphasize': (args.dataItem.approval_action != "Pending")
  });

  public flagLine(arg:OnDemandEFBModel) {
    return arg.charges.filter(x=>x.approval_action == 'Reject').length > 0;
  }


  public selectionChange(sender:NexusGridComponent, e:SelectionEvent) {
    if (e?.selectedRows?.length) {
      this.on_demand_efb = e.selectedRows[0].dataItem;
      this.nav_position = (e.selectedRows[0].index + 1) + " of " + this.on_demand_efbs.length;
    }
    else {
      this.on_demand_efb = null;
      this.nav_position = "";
    }
  }

  public distributeChargeApproval(approval_charge:OnDemandEFBChargeModel) {
    this.on_demand_efbs.forEach(efb=> {
      efb.charges.filter(charge=>charge.id == approval_charge.id).forEach(charge => {
        charge.approval_note = approval_charge.approval_note;
        charge.approval_action = approval_charge.approval_action;
        charge.portions = approval_charge.portions;
        charge.form_result = approval_charge.form_result;
      });
    });
  }

  public resetConfirmAction() {
    this.reject_clicked = false;

    this.on_demand_efbs.forEach(efb=> {
      efb.charges.filter(charge=>charge.confirm_choice).forEach(charge => {
        charge.confirm_choice = false;
      });
    });
  }

  public rejectOnDemandEFB()
  {
    this.reject_clicked = true;
  }

  public confirmRejectClicked()
  {
    if (!this.report_note) {
      this.report_form_result.Error("Please provide a rejection note before continuing.");
      return;
    }

    this.reject_clicked = false;
    this.on_demand_efb.charges?.forEach(charge => {
      charge.approval_note = this.report_note;
      charge.confirm_choice = true;
    });
    this.recordAllAction('Reject');

    this.closeDialog();
  }

  public containsActualCharges(charge : OnDemandEFBChargeModel)
  {
    return charge.portions?.filter(x => !x.estimated)?.length > 0 ? true : false;
  }

  private processChargeAction(action:string, charge:OnDemandEFBChargeModel): Observable<OnDemandEFBChargeModel> {
    if (charge.approval_processed) {
      return of(charge);
    }

    return this.service.ProcessOnDemandEFBCharge(charge).pipe(
      map(data => {
        charge = data;
        this.prepChartData(charge);
        charge.form_result = new NexusFormResultModel();
        if (charge.approval_processed == true) {
          if (charge.approval_action == 'Reject') {
            charge.form_result.Error((charge.portions.length > 1 ? 'These Residents ' : 'This Resident ') + 'will not receive an Estimated Final Bill until approved.  Your AM will reach out to reconcile this rejection.');
          }
          else {
            charge.form_result.Success((charge.approval_action + 'ed').replace('eed','ed'));
          }

          if(charge.approval_action == 'Approve' && this.containsActualCharges(charge))
          {
            charge.form_result.Success('Both the Estimated Charge and the preceding Actual Charges have been approved for invoicing.');
          }

          if(charge.approval_action == 'Default' && this.containsActualCharges(charge))
          {
            charge.form_result.Success('Both the Estimated Charge and the preceding Actual Charges will be defaulted to the next Property Invoice.');
          }
        }
        else {
          charge.approval_action = "Pending";
          charge.form_result.Error(charge.approval_error);
        }
        return charge;
      }),
      catchError(err => {
        charge.approval_action = "Pending";
        charge.form_result = new NexusFormResultModel();
        charge.form_result.Error(err);
        return of(charge);
      }));
  }

  public applyActionChanges(charge:OnDemandEFBChargeModel) {
    //carry over the approval_processed value...
    this.on_demand_efb.charges.filter(x=> x.id == charge.id)[0].approval_processed = charge.approval_processed;

    this.prepChartData(charge);
    this.distributeChargeApproval(charge);
    this.resetConfirmAction();
  }

  public recordAllAction(action: string) {
    if (!this.on_demand_efb) {
      return;
    }

    this.StartSpinner();

    this.on_demand_efb.charges.forEach(charge => {
      if (!charge.approval_processed) {
        charge.form_result.Reset();
        charge.approval_action = action;
      }
    });

    forkJoin(
      this.on_demand_efb.charges
        .map(charge => this.processChargeAction(action, charge)))
      .subscribe(
        data => {
          this.on_demand_efb.charges = data;
          this.on_demand_efb.charges.forEach(chg => this.applyActionChanges(chg));
          this.StopSpinner();
          //this.selectNext(true);
        },
        err => {
          //there should not be an error.  processChargeAction does not throw errors...
        }
      );
  }

  public selectNext(next_group: boolean = false) {
    this.resetConfirmAction();
    var select_index = 0;

    if (this.on_demand_efb) {
      var current_index = this.efb_grid.grid_data.data.indexOf(this.on_demand_efb);

      select_index = current_index + 1;
      if (select_index >= this.efb_grid.grid_data.data.length)
        select_index = 0;

      while (next_group && this.efb_grid.grid_data.data[current_index].charges[0].provider_bill_id == this.efb_grid.grid_data.data[select_index].charges[0].provider_bill_id) {
        select_index++;
        if (select_index >= this.efb_grid.grid_data.data.length)
          select_index = 0;
      }
    }

    let element: HTMLElement = this.efb_grid.grid.wrapper.nativeElement.getElementsByTagName('tr')[select_index+1].getElementsByTagName('td')[0] as HTMLElement;
    element.click();
  }
  public selectPrevious() {
    this.resetConfirmAction();
    var select_index = 0;

    if (this.on_demand_efb) {
      var current_index = this.efb_grid.grid_data.data.indexOf(this.on_demand_efb);
      select_index = (current_index - 1);
      if (select_index < 0)
        select_index = (this.efb_grid.grid_data.data.length - 1);
    }

    let element: HTMLElement = this.efb_grid.grid.wrapper.nativeElement.getElementsByTagName('tr')[select_index+1].getElementsByTagName('td')[0] as HTMLElement;
    element.click();
  }



  public closeDialog() {
    this.reject_clicked = false;
    this.report_note = "";
  }
}
