import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TokenPropertyModel } from 'app/models/token-property.model';
import { ConfigService } from 'app/services/config.service';

@Injectable({
  providedIn: 'root'
})
export class PropertyService {

  constructor(private http: HttpClient, private config: ConfigService) { }

  public GetProperties(): Observable<Array<TokenPropertyModel>> {
    return this.http.get<Array<TokenPropertyModel>>(this.config.getApiRoute('api/v3/client-portal/properties')).pipe(
      map(data => {
        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return observableThrowError(err.error.message);
      }));
  }

}
