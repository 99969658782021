<app-page-header title="Future Move-Ins"></app-page-header>

<nexus-grid [data]="residents_model.residents">
    <kendo-grid-column [width]="100" field="customer.first_name" title="First Name">
        <ng-template kendoGridCellTemplate let-dataItem>
            <nexus-customer-link  [customer_model]="dataItem.customer" [text]="dataItem.customer.first_name"></nexus-customer-link>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="100" field="customer.last_name" title="Last Name">
        <ng-template kendoGridCellTemplate let-dataItem>
            <nexus-customer-link  [customer_model]="dataItem.customer" [text]="dataItem.customer.last_name"></nexus-customer-link>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="140" field="customer.email" title="E-Mail"></kendo-grid-column>
    <kendo-grid-column [width]="120" field="customer.phone" title="Phone"></kendo-grid-column>
    <kendo-grid-column [width]="140" field="group.address1" title="Address 1"></kendo-grid-column>
    <kendo-grid-column [width]="110" field="group.address2" title="Address 2"></kendo-grid-column>
    <kendo-grid-column [width]="100" field="start_date" title="Start Date" format="{0:MM/dd/yyyy}" filter="date">
    </kendo-grid-column>
    <kendo-grid-column [width]="130" field="last_confirmation_code" title="Confirmation Code" [hidden]="false">
    </kendo-grid-column>
    <kendo-grid-column [width]="80" field="good_standing" title="Good Standing" [filter]="'boolean'" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span *ngIf="dataItem.good_standing == true" class="badge badge-success">True</span>
            <span *ngIf="dataItem.good_standing == false" class="badge badge-danger">False</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="120" field="id" title="" [filterable]="false" [sortable]="false" [columnMenu]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
            <button kendoButton *ngIf="recoveries_sent.indexOf(dataItem.id) < 0" [icon]="'reset-sm'"
                (click)="sendRecovery(dataItem)">Send Password Reset</button>
            <div *ngIf="recoveries_sent.indexOf(dataItem.id) >= 0" class="badge badge-primary">E-Mail Sent!</div>
        </ng-template>
    </kendo-grid-column>
</nexus-grid>
<br />
<div class="text-center">
    <h1>Can't find the resident you're looking for?</h1>
    <p>TIP: Avoid creating duplicate accounts by searching for residents in the Residents table before creating a
        new resident account.</p>
    <p><button kendoButton icon="plus" [routerLink]="['/residents', 'create']">Create New Resident Account</button></p>
</div>