<div class="bg-light">
    <div class="container">
        <div class="row">
            <div id="login-art" class="col-md-6 d-none d-md-flex">

            </div>
            <div class="col-md-6 text-center">
                <div id="login-wrapper">
                <h2>Are You a Property Manager?<br/>Sign In.</h2>

                <form [formGroup]="form" method="post" (ngSubmit)="submit()">
                    <div class="input-group mb-4">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><fa-icon [icon]="faAt" [fixedWidth]="true"></fa-icon></span>
                        </div>
                        <input type="text" class="form-control" placeholder="Email" formControlName="email">
                    </div>
                    <div class="input-group mb-4">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><fa-icon [icon]="faKey" [fixedWidth]="true"></fa-icon></span>
                        </div>
                        <input type="password" class="form-control" placeholder="Password" formControlName="password" autocomplete="off">
                    </div>
                    <p>
                        <button kendoButton [primary]="true" icon="check">Sign In</button>
                    </p>
                    
                    <div class="alert alert-warning" *ngIf="token_model && token_model.user_id && token_model.reset_id">
                        <p>Your password has expired, please click the following button to set a new password.</p>
                        <p><button kendoButton [primary]="true" [routerLink]="['/reset-password', token_model.user_id, token_model.reset_id]">Reset Password</button></p>
                    </div>
                    <nexus-form-result [model]="form_result"></nexus-form-result>

                    <p class="font-weight-lighter">Forgot your password? <a [routerLink]="['/forgot-password']">Reset</a></p>

                    <hr class="green-rule" />
                    <p class="font-weight-bolder">Are you looking for the resident portal? <a href="https://app.simplebills.com">Sign in Here.</a></p>

                </form>
            </div>
            </div>
        </div>
    </div>
</div>