import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { GroupOwnerModel } from 'app/models/group-owner.model';
import { GroupService } from '../services/group.service';

@Injectable()
export class GroupOwnersResolver implements Resolve<Array<GroupOwnerModel>> {

    constructor(private service: GroupService) 
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<Array<GroupOwnerModel>> {
        return this.service.GetGroupOwners();
    }
}
