import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { NexusComponent } from '../nexus/nexus.component';
import { NexusFormResultModel } from 'app/models/nexus-form-result.model';
import { GroupOwnerModel } from 'app/models/group-owner.model';
import { SearchModel } from 'app/models/search.model';
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { GroupOwnerService } from 'app/core/services/group-owner.service';

@Component({
  selector: 'nexus-form-group-owner-select',
  templateUrl: './nexus-form-group-owner-select.component.html',
  styleUrls: ['./nexus-form-group-owner-select.component.scss'],
  providers: [
    { 
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => NexusFormGroupOwnerSelectComponent),
    }
  ]
})
export class NexusFormGroupOwnerSelectComponent extends NexusComponent implements ControlValueAccessor {

  public group_owners_model: Array<GroupOwnerModel>;
  public search_argument: SearchModel = new SearchModel();
  public search_result: NexusFormResultModel = new NexusFormResultModel();
  public group_owner_id: string;
  public is_disabled: boolean = false;
  public current_page: number = 1;
  public group_owners_page: Array<GroupOwnerModel>;
  public faTimesCircle = faTimesCircle;
  public faSearch = faSearch;

  constructor(public group_owner_service: GroupOwnerService) { 
    super();
  }

  ngOnInit() {
  super.ngOnInit();
  }

  clear()
  {
    this.group_owner_id = null;
    this.propagateChange(null);
    this.search_argument = new SearchModel();
    this.group_owners_model = new Array<GroupOwnerModel>();
    this.page_changed();
  }

  search(argument: SearchModel = this.search_argument) {
    this.search_result.Reset();
    this.StartSpinner();
    this.group_owner_service.SearchGroupOwners(argument).subscribe(
      data => {
        this.group_owners_model = data;
        this.current_page = 1;
        this.page_changed();
        this.StopSpinner();
      },
      error => {
        this.search_result.Error(error);
        this.StopSpinner();
      });
  }
  public propagateChange = (value: any) => { };

  writeValue(obj: any): void {
    this.group_owner_id = obj;
    if(this.group_owner_id)
    {
      let argument = new SearchModel();
      argument.search = this.group_owner_id;
      this.search(argument);
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    
  }

  setDisabledState?(isDisabled: boolean): void {
    this.is_disabled = isDisabled;
  }

  public page_changed() {
    const startItem = (this.current_page - 1) * 10;
    const endItem = this.current_page * 10;
    this.group_owners_page = this.group_owners_model.slice(startItem, endItem);
  }
}
