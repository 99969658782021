<div class="row">
    <div class="col-sm-6">
        <nexus-card card_title="Filter">
            <nexus-card-body>
                <nexus-form-row control_id="show-archived-input" row_label="Show Archived">
                    <kendo-switch id="show-archived-input" [(ngModel)]="show_archived" (valueChange)="filter()">
                    </kendo-switch>
                </nexus-form-row>
            </nexus-card-body>
        </nexus-card>
    </div>
</div>
<nexus-grid [data]="expected_bills_model" [sort]="[{ 'field': 'provider.name', 'dir': 'asc' }]" [filters]="filters">
    <kendo-grid-column field="provider.name" title="Provider">
    </kendo-grid-column>
    <kendo-grid-column field="provider.electric_type" title="Type"></kendo-grid-column>
    <kendo-grid-column field="summary_account_number" title="Summary Account">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{dataItem.summary_account_description}}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="account_number" title="Account Number"></kendo-grid-column>
    <kendo-grid-column field="meter_number" title="Meter Number"></kendo-grid-column>
    <kendo-grid-column field="archived_date" title="Archived" format="G" filter="date" [hidden]="!show_archived"></kendo-grid-column>
    <kendo-grid-column field="last_provider_bill_charge.provider_bill.statement_date" title="Last Statement" format="d"
        filter="date">
    </kendo-grid-column>
</nexus-grid>
