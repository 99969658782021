import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { CustomerService } from 'app/services/customer.service';
import { InvoiceItemModel } from 'app/models/invoice-item.model';

@Injectable()
export class CustomerInvoiceItemsResolver implements Resolve<Array<InvoiceItemModel>> {

    constructor(private service: CustomerService, private router: Router) 
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<Array<InvoiceItemModel>> {
        return this.service.GetCustomerInvoiceItems(route.parent.params.id);
    }
}