import { Component, Input, OnInit } from '@angular/core';
import { CustomerModel } from 'app/models/customer.model';

@Component({
  selector: 'nexus-customer-link',
  templateUrl: './nexus-customer-link.component.html',
  styleUrls: ['./nexus-customer-link.component.scss']
})
export class NexusCustomerLinkComponent implements OnInit {

  constructor() { }
@Input() customer_model : CustomerModel
@Input() text: string
  ngOnInit(): void {
  }

}
