<kendo-grid *ngIf="grid_data" [data]="grid_data" [pageSize]="state.take" [skip]="state.skip" [sort]="state.sort"
  [filter]="state.filter" [pageable]="true" (excelExport)="onExcelExport($event)" [sortable]="{mode:multi_sort?'multiple':'single'}"
  [filterable]="'menu'" [columnMenu]="{ columnChooser: true, filter: true, sort: true }" [group]="state.group"
  [groupable]="groupable" [rowClass]="rowClass" (dataStateChange)="dataStateChange($event)"
  [selectable]="selectable" (selectionChange)="onSelectionChange($event)"
  [navigable]="navigable">
  <ng-template kendoGridToolbarTemplate *ngIf="showHeader">
    <app-nexus-grid-filter [columns]="all_columns" [state]="state"></app-nexus-grid-filter>
    <nexus-grid-date-range-filter [columns]="all_columns" [state]="state" [date_range_filter_key]="date_range_filter_key"
    [start_date_place_holder_text]="start_date_place_holder_text"
    [end_date_place_holder_text]="end_date_place_holder_text">
    </nexus-grid-date-range-filter>
    <div class="ml-auto">
      <button kendoButton [primary]="false" [title]="action_button_tooltip" class="mr-2" *ngIf="action_button_text" (click)="onActionButtonClicked($event)">{{action_button_text}}</button>
      <span style="margin:auto;" class="mr-2" *ngIf="show_toggle">
        <small class="mr-1">{{toggle_label}}</small>
        <kendo-switch
        size="small"
        [(ngModel)]="toggle_value"
        (valueChange)="onToggleValueChange($event)"></kendo-switch>
      </span>
      <span style="margin:auto;">
        <small class="mr-1">MultiSort</small>
        <kendo-switch
        size="small"
        [(ngModel)]="multi_sort"
        (valueChange)="multSortChange($event)"></kendo-switch>
      </span>
    </div>
    <button class="btn btn-secondary ml-1" [primary]="false" [icon]="'excel'" kendoGridExcelCommand>Export</button>
    <span kendoTooltip [title] ="pdf_export_tooltip_txt">
      <button class="btn btn-secondary ml-1" [primary]="false" [icon]="'pdf'" [disabled]="isPageUnResponsive()" kendoGridPDFCommand>Export</button>
    </span>
  </ng-template>
  <ng-content></ng-content>

  <kendo-grid-excel fileName="Export.xlsx" [fetchData]="excelData">
  </kendo-grid-excel>
  <kendo-grid-pdf fileName="Export.pdf" [allPages]="true">
    <kendo-grid-pdf-margin top="1cm" left="1cm" right="1cm" bottom="1cm"></kendo-grid-pdf-margin>
  </kendo-grid-pdf>
  <ng-template kendoPagerTemplate *ngIf="showFooter">
    <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
    <kendo-pager-numeric-buttons [buttonCount]="button_count"></kendo-pager-numeric-buttons>
    <kendo-pager-next-buttons></kendo-pager-next-buttons>
    <span class="row-count" *ngIf="grid_data">Showing {{ startRecord() }} - {{ endRecord() }} of {{ grid_data.total }}
      Rows</span>
  </ng-template>

  <ng-template *ngIf="detailTemplate" kendoGridDetailTemplate let-dataItem>
    <ng-container [ngTemplateOutlet]="detailTemplate" [ngTemplateOutletContext]="{ detailDataItem: dataItem }">
    </ng-container>
  </ng-template>
</kendo-grid>
