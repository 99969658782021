import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { CustomerModel } from 'app/models/customer.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent extends NexusComponent {

  constructor(protected route: ActivatedRoute) {
    super();
   }
  public customer_model: CustomerModel;
  public menu_collapsed = false;
  public current_component: string;
  public items : Array<NavItem>;
  ngOnInit(): void {
    this.route.data.subscribe(({ customer_model }) => {
      this.customer_model = customer_model;
      //console.log(this.route.url);
    this.items = [
      {
        name: 'Information',
        route: ['/customer', this.customer_model.id,'information'],
        component: 'CustomerInformationComponent',
      },
      {
        name: 'Invoices',
        route: ['/customer', this.customer_model.id, 'invoices'],
        component: 'CustomerInvoicesComponent',
      },
      {
        name: 'Units',
        route: ['/customer', this.customer_model.id, 'groups'],
        component: 'CustomerGroupsComponent',
      },
      {
        name: 'E-Mails',
        route: ['/customer', this.customer_model.id, 'emails'],
        component: 'CustomerEmailsComponent',
      },
      {
        name: 'SMS Messages',
        route: ['/customer', this.customer_model.id, 'sms-messages'],
        component: 'CustomerSmsMessagesComponent',
      },
      {
        name: 'Phone Calls',
        route: ['/customer', this.customer_model.id, 'phone-calls'],
        component: 'CustomerPhoneCallsComponent',
      },
     
    
     
    ];
  });
  }


}


export class NavItem {
  public name: string;
  public route: Array<string>;
  public component: string;
}