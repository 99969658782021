import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { CustomerService } from 'app/services/customer.service';
import { SmsMessageModel } from 'app/models/sms-message.model';

@Injectable()
export class CustomerSmsMessagesResolver implements Resolve<Array<SmsMessageModel>> {

    constructor(private service: CustomerService, private router: Router) 
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<Array<SmsMessageModel>> {
        return this.service.GetCustomerSmsMessages(route.parent.params.id);
    }
}