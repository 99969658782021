import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventService } from '../../core/services/event.service';
import { ResidentService } from '../../services/resident.service';
import { faChartPie, faBed, faPencilAlt,faEnvelope,faMailBulk,faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { ResidentsModel } from 'app/models/residents.model';
import { PropertyCustomerModel } from 'app/models/property-customer.model';
import { SessionModel } from 'app/models/session.model';
import { MembershipService } from '../../core/services/membership.service';
import { MarketType } from 'app/enums/market-type.enum';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { NexusFormResultModel } from 'app/models/nexus-form-result.model';

@Component({
    selector: 'app-residents',
    templateUrl: './residents.component.html',
    styleUrls: ['./residents.component.scss']
})
export class ResidentsComponent extends NexusComponent implements OnInit {
  public residents_model: ResidentsModel;
  public session: SessionModel = null;
  public show_dialog:boolean=false;
  public intro_mail_model:IntroMailModel=new IntroMailModel();
  public form_result: NexusFormResultModel = new NexusFormResultModel();
  public form = new UntypedFormGroup({
    future_max_date: this.CreateRequiredFormControl(),
  });

    public colors: any[] = [{
        to: 25,
        color: '#f31700'
    }, {
        from: 25,
        to: 75,
        color: '#ffc000'
    }, {
        from: 75,
        to: 100,
        color: '#37b400'
    }];

    public recoveries_sent: string[] = [];

    public faChartPie = faChartPie;
    public faBed = faBed;
    public faPencilAlt = faPencilAlt;
    public faMailBulk = faMailBulk;
    public faEnvelope = faEnvelope;
    public faCaretDown=faCaretDown;
  constructor(private route: ActivatedRoute, private router: Router, public event_service: EventService, private resident_service: ResidentService, public membership: MembershipService) {
    super();  
    this.event_service.on('session-start', (model) => {
        this.session = model;
      });
      this.event_service.on('session-end', () => {
        this.session = null;
      });
    }

    get measurement_type() : string{
      // measurement_type property is used to provide measurement based on the property market type
      if(this.residents_model.bed_count >=1 &&
        (this.session.current_property.market.type.toString() ==
         MarketType.Student.toString() ))
      {
        return "Bed"+ (this.residents_model.bed_count != 1 ? "s" : "");
      }
      else
      {
        // for multi family/singlefamily market types, we are hiding the row
        // if it has zero bed count also we are hiding the row
        return null;
      }
  }

    ngOnInit() {
        this.route.data.subscribe(({ residents_model }) => {
            this.residents_model = residents_model;
        });

      this.membership.GetSession().subscribe(
        data => {
          this.session = data;
        },
        error => {
          this.session = null;
        });

        this.SetFormControlValidator(this.form.get('future_max_date'),this.futureDateValidator() );
        this.form.valueChanges.subscribe((form: any) => {
          this.PopulateModelFromForm(form, this.intro_mail_model);
        });
    
    }
    futureDateValidator() {
      return (control) => {
        const selectedDate = control.value;
        const currentDate = new Date();
        if (selectedDate && selectedDate <= currentDate) {
          return { futureDate: true };
        }
        return null;
      };
    }
    sendRecovery(resident_model: PropertyCustomerModel) {
        this.event_service.loadingStart();

        this.resident_service.SendRecovery(resident_model).subscribe(
            data => {
                this.recoveries_sent.push(resident_model.id);
                this.event_service.loadingFinish();
            },
            err => {
                this.event_service.loadingFinish();
            });
    }
    
  sendIntroEmails(status:string) {
    var argument= new IntroMailModel();
    argument.status=status;
    if(status.toLowerCase()=="future"){
      this.SetFormTouched(this.form);
      argument.future_max_date=this.intro_mail_model.future_max_date;
      this.show_dialog=false;
    }
    this.event_service.loadingStart();
    this.resident_service.SendIntroEmailToAll(argument).subscribe(
      data => {
        if (data && Array.isArray(data) && data.every(it => typeof it === 'string')) {
          this.recoveries_sent = this.recoveries_sent.concat(data);
        }
        this.event_service.loadingFinish();
      },
      err => {
        this.event_service.loadingFinish();
      });
  }

  sendFutureIntroEmails(){
    this.show_dialog=true;
    this.form.reset();
  }
  public button_list: Array<any> = ["Email All Current Residents", "Email Future Residents"];
			   
  public select_type(item: string) {
switch (item) {
    case "Email All Current Residents":
        this.sendIntroEmails("Current");
        break;
    case "Email Future Residents":
        this.sendFutureIntroEmails();
        break;
    default:
        break;
}
}

public closeDialog() {
  this.show_dialog = false;
}
}
 export class IntroMailModel{
  public status:string;
  public future_max_date:Date;
}
