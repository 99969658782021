import { Component, Input, OnInit, QueryList } from '@angular/core';
import { CompositeFilterDescriptor, State } from '@progress/kendo-data-query';
import { FilterService, BaseFilterCellComponent, ColumnComponent } from '@progress/kendo-angular-grid';
import * as moment from 'moment/moment';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SearchModel } from 'app/models/search.model';


@Component({
  selector: 'nexus-grid-date-range-filter',
  templateUrl: './nexus-grid-date-range-filter.component.html',
  styleUrls: ['./nexus-grid-date-range-filter.component.scss']
})
export class NexusGridDateRangeFilterComponent extends BaseFilterCellComponent implements OnInit{

  @Input('columns') public columns: QueryList<ColumnComponent>;
  @Input('state') public state: State;
  @Input() date_range_filter_key: string;
  @Input() start_date_place_holder_text: string
  @Input() end_date_place_holder_text:string
  public model:  DateSearchModel= new DateSearchModel();
  public form = new UntypedFormGroup({
    from_date: new UntypedFormControl(),
    to_date: new UntypedFormControl(),
  });

  constructor(filterService: FilterService) { super(filterService); }

  ngOnInit(): void {
    
    this.form.valueChanges.subscribe((form_data: any) => {
     this.model.from_date= this.form.get('from_date').value
     this.model.to_date= this.form.get('to_date').value
    });
  }
  search(){
    this.filterTable();
  }

  validateInterval(){
    if(!this.model.from_date && this.model.to_date){
      this.form.get('from_date').setValidators([Validators.required]);
    } else if(this.model.from_date && !this.model.to_date) {
      this.form.get('to_date').setValidators([Validators.required]);
    }
    else{
      this.form.get('from_date').clearValidators();
      this.form.get('to_date').clearValidators();  
    }
    this.form.get('from_date').updateValueAndValidity();
    this.form.get('to_date').updateValueAndValidity();
    
  }

  public filterTable(): void {
    this.validateInterval()
    this.form.markAllAsTouched()
    if (this.form.invalid ||(this.model.from_date==null && this.model.to_date==null))
      return;
    
    this.remove_filters();
    if (this.date_range_filter_key && this.columns) {

      let filter_list = [];
      var column = this.columns.toArray().filter(x=>x.field==this.date_range_filter_key)[0];
      if (column.field && !column.hidden && column.filterable) {
          filter_list.push({ field: column.field, operator: this.dateRangeSearch, value: this.model });
        }
  
      const composite: CompositeFilterDescriptor = { logic: 'and', filters: [{ logic: 'or', filters: filter_list }] };
      this.applyFilter(composite);
    }
  }

  private dateRangeSearch = function (item: any, value: DateSearchModel) {
    if (item == null || item == undefined) {
      return false;
    }
    let match: boolean = false;
    if (item instanceof Date && item>= value.from_date && item<= value.to_date) {
      match = true;
      } 
    return match;
  }

  public clearFilters(): void {

    this.remove_filters();
  }

  private remove_filters(): void {
    const composite: CompositeFilterDescriptor = { logic: 'and', filters: [] };
    this.applyFilter(composite);
  }

}

export class DateSearchModel {
  public from_date: Date;
  public to_date: Date;
}