import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators, UntypedFormGroup, FormControl } from '@angular/forms';
import { ResidentService } from '../../services/resident.service';
import { NexusFormResultModel } from 'app/models/nexus-form-result.model';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { PropertyCustomerModel } from 'app/models/property-customer.model';
import { GroupModel } from 'app/models/group.model';
import { GroupSearchFormModel } from 'app/models/group-search-form.model';

@Component({
    selector: 'app-resident',
    templateUrl: './resident.component.html',
    styleUrls: ['./resident.component.scss']
})
export class ResidentComponent extends NexusComponent {
    public resident_model: PropertyCustomerModel = new PropertyCustomerModel();
    public resident_result: NexusFormResultModel = new NexusFormResultModel();

    public groups_search_model: Array<GroupModel> = new Array<GroupModel>();
    public groups_search_argument: GroupSearchFormModel = new GroupSearchFormModel();
    public groups_search_result: NexusFormResultModel = new NexusFormResultModel();

    public recovery_result: NexusFormResultModel = new NexusFormResultModel();
    public current_page: number = 1;
    public pageSize : number = 5;

    public groups_search_page: Array<GroupModel>;
    public resident_form = new UntypedFormGroup({
        group_id: this.CreateRequiredFormControl(),
        first_name: this.CreateRequiredFormControl(),
        last_name: this.CreateRequiredFormControl(),
        email: this.CreateRequiredFormControl(),
        phone: this.CreateRequiredFormControl(),
        secondary_email: this.CreateFormControl(),
        secondary_phone: this.CreateFormControl(),
        birth_date: this.CreateFormControl(),
        credit_reporting_enabled: this.CreateFormControl(),
        start_date: this.CreateRequiredFormControl(),
        end_date: this.CreateRequiredFormControl()
    });

    public groups_search_form = new UntypedFormGroup({
        search: this.CreateRequiredFormControl(),
        include_customers: this.CreateFormControl()
    });

    constructor(private route: ActivatedRoute, private router: Router, private resident_service: ResidentService) {
        super();
    }

    ngOnInit() {
        this.resident_form.get('credit_reporting_enabled').valueChanges.subscribe(credit_reporting=>{
            if(credit_reporting)
            {
                this.SetFormControlValidator(this.resident_form.get('birth_date'), Validators.required);
            }
            else
            {
                this.ClearFormControlValidator(this.resident_form.get('birth_date'));
            }
        });
        
        this.route.data.subscribe(({ resident_model }) => {
            if (resident_model) {
                this.resident_model = resident_model;
                if (this.resident_model) {
                    this.resident_form.patchValue(this.resident_model);
                    this.resident_form.controls['email'].disable();
                    this.resident_form.controls['phone'].disable();
                    this.groups_search_argument.search = this.resident_model.group_id;
                    this.groupsSearch();
                }
            }
            this.groups_search_form.patchValue({
                include_users: true
            });
        });

        this.resident_form.valueChanges.subscribe((form: any) => {
            this.PopulateModelFromForm(form, this.resident_model);
            this.PopulateModelFromForm(form, this.resident_model.customer);
        });


        this.groups_search_form.valueChanges.subscribe((form: any) => {
            this.PopulateModelFromForm(form, this.groups_search_argument);
        });
    }

    public formatAddress(group: GroupModel): string {
        var address = group.address1;
        if (group.address2) {
            address += ", " + group.address2;
        }
        return address;
    }

    submit(event) {
        this.resident_result.Reset();
        this.StartSpinner();

        this.resident_service.SaveResident(this.resident_model).subscribe(
            data => {
                this.resident_model = data;

                this.StopSpinner();
                this.resident_result.Success('Resident Saved!');
                this.router.navigate(['/residents']);
            },
            err => {
                this.resident_result.Error(err);
                this.StopSpinner();
            });
    }

    groupsSearch() {
        this.groups_search_result.Reset();
        this.StartSpinner();

        this.resident_service.SearchGroups(this.groups_search_argument).subscribe(
            data => {
                this.groups_search_model = data;
                this.StopSpinner();
            },
            err => {
                this.groups_search_result.Error(err);
                this.StopSpinner();
            });

    }

    sendRecovery() {
        this.recovery_result.Reset();
        this.StartSpinner();

        this.resident_service.SendRecovery(this.resident_model).subscribe(
            data => {
                this.recovery_result.Success("Recovery E-Mail Sent!");
                this.StopSpinner();
            },
            err => {
                this.recovery_result.Error(err);
                this.StopSpinner();
            });
    }
}
