import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { CustomerService } from 'app/services/customer.service';
import { PhoneCallModel } from 'app/models/phone-call.model';

@Injectable()
export class CustomerPhoneCallsResolver implements Resolve<Array<PhoneCallModel>> {

    constructor(private service: CustomerService, private router: Router) 
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<Array<PhoneCallModel>> {
        return this.service.GetCustomerPhoneCalls(route.parent.params.id);
    }
}