
import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ResidentsModel } from 'app/models/residents.model';
import { PropertyCustomerModel } from 'app/models/property-customer.model';
import { CurrentDateModel } from 'app/models/current-date.model';
import { ConfigService } from './config.service';
import { GroupSearchFormModel } from 'app/models/group-search-form.model';
import { GroupModel } from 'app/models/group.model';
import { IntroMailModel } from 'app/components/residents/residents.component';


@Injectable()
export class ResidentService {

    constructor(private http: HttpClient, private config: ConfigService) { }

    public SearchGroups(argument: GroupSearchFormModel): Observable<Array<GroupModel>> {

        return this.http.post<Array<GroupModel>>(this.config.getApiRoute('api/v3/client-portal/residents/groups-search'), argument).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse)  => {
                return throwError(err.error.message);
            }));
    }

    public GetResidents(): Observable<ResidentsModel> {
        let model = new CurrentDateModel();
        model.current_date = this.config.getCurrentMoment().startOf('day').toDate();

        return this.http.post<ResidentsModel>(this.config.getApiRoute('api/v3/client-portal/residents'), model).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return throwError(err.error.message);
            }));
    }

    public GetMoveIn(): Observable<ResidentsModel> {
        let model = new CurrentDateModel();
        model.current_date = this.config.getCurrentMoment().startOf('day').toDate();

        return this.http.post<ResidentsModel>(this.config.getApiRoute('api/v3/client-portal/residents/move-in'), model).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return throwError(err.error.message);
            }));
    }

    public GetResident(user_in_grup_id: string): Observable<PropertyCustomerModel> {
        return this.http.get<PropertyCustomerModel>(this.config.getApiRoute('api/v3/client-portal/residents/' + user_in_grup_id)).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return throwError(err.error.message);
            }));
    }

    public SaveResident(argument: PropertyCustomerModel): Observable<PropertyCustomerModel> {

        if (argument.id) {
            return this.http.put<PropertyCustomerModel>(this.config.getApiRoute('api/v3/client-portal/residents/' + argument.id), argument).pipe(
                map(data => {
                    return data;
                }),
                catchError((err: HttpErrorResponse) => {
                    return throwError(err.error.message);
                }));
        }
        else {
            return this.http.put<PropertyCustomerModel>(this.config.getApiRoute('api/v3/client-portal/residents'), argument).pipe(
                map(data => {
                    return data;
                }),
                catchError((err: HttpErrorResponse) => {
                    return throwError(err.error.message);
                }));
        }
    }

    public SendRecovery(argument: PropertyCustomerModel): Observable<{}> {
        return this.http.put(this.config.getApiRoute('api/v3/client-portal/residents/' + argument.id + '/forgot-password'), {}).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return throwError(err.error.message);
            }));
  }
  public SendIntroEmailToAll( argument:IntroMailModel): Observable<string[]> {
    return this.http.put<string[]>(this.config.getApiRoute('api/v3/client-portal/residents/send-intro-email-to-property-customers'),argument).pipe(
        map(data => {
            return data;
        }),
        catchError((err: HttpErrorResponse) => {
            return throwError(err.error.message);
        }));
}

}
