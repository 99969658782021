<app-page-header title="Residents"></app-page-header>

<div class="row card-row mb-4">
  <div class="col-lg-4">
    <div class="card">
      <div class="card-header">
        <fa-icon [icon]="faChartPie" [fixedWidth]="true"></fa-icon> Enrollment Status
      </div>
      <div class="card-body">
        <p>
          <strong>{{ residents_model.percent_complete }}%</strong> of residents are enrolled at {{
          session.current_property.name }}.
        </p>
        <ngb-progressbar [value]="residents_model.percent_complete" type="success" height="20px"><span
            *ngIf="residents_model.percent_complete > 5"><strong>{{residents_model.percent_complete}}%</strong></span>
        </ngb-progressbar>
      </div>
    </div>
  </div>
  <div class="col-lg-4">
    <div class="card">
      <div class="card-header">
        <fa-icon [icon]="faBed" [fixedWidth]="true"></fa-icon> Residents
      </div>
      <div class="card-body">
        <p *ngIf="measurement_type"><strong>{{ residents_model.bed_count }}</strong>
          {{ measurement_type }}</p>
        <p><strong>{{ residents_model.resident_count }}</strong> Current & Future Residents</p>
        <p><strong>{{ residents_model.good_standing_count }}</strong> Enrollments Completed</p>
      </div>
    </div>
  </div>
  <div class="col-lg-4">
    <div class="card">
      <div class="card-header">
        <fa-icon [icon]="faPencilAlt" [fixedWidth]="true"></fa-icon> Create Resident Account
      </div>
      <div class="card-body">
        <p>Quickly create an account for a resident by clicking below.</p>
        <p><button kendoButton [primary]="true" [routerLink]="['/residents', 'create']" icon="plus">Create
            Resident</button></p>
      </div>
    </div>
  </div>
</div>
<nexus-grid [data]="residents_model.residents">
  <kendo-grid-column [width]="120" field="customer.first_name" title="First Name">
    <ng-template kendoGridCellTemplate let-dataItem>
      <nexus-customer-link [customer_model]="dataItem.customer"
        [text]="dataItem.customer.first_name"></nexus-customer-link>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [width]="120" field="customer.last_name" title="Last Name">
    <ng-template kendoGridCellTemplate let-dataItem>
      <nexus-customer-link [customer_model]="dataItem.customer"
        [text]="dataItem.customer.last_name"></nexus-customer-link>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [width]="220" field="customer.email" title="E-Mail"></kendo-grid-column>
  <kendo-grid-column [width]="130" field="customer.phone" title="Phone">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.customer?.phone | phone}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [width]="200" field="customer.secondary_email" title="Secondary E-Mail" [hidden]="true">
  </kendo-grid-column>
  <kendo-grid-column [width]="130" field="customer.secondary_phone" title="Secondary Phone" [hidden]="true">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.customer?.secondary_phone | phone}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [width]="220" field="group.address1" title="Address">
    <ng-template kendoGridCellTemplate let-dataItem>
      <nexus-group-link [group_model]="dataItem.group" [text]="dataItem.group?.address1">
      </nexus-group-link>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [width]="130" field="group.address2" title="Unit">
    <ng-template kendoGridCellTemplate let-dataItem>
      <nexus-group-link [group_model]="dataItem.group" [text]="dataItem.group?.address2">
      </nexus-group-link>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [width]="90" field="start_date" title="Start" format="{0:MM/dd/yyyy}" filter="date">
  </kendo-grid-column>
  <kendo-grid-column [width]="90" field="end_date" title="End" format="{0:MM/dd/yyyy}" filter="date">
  </kendo-grid-column>
  <kendo-grid-column [width]="140" field="last_confirmation_code" title="Confirmation Code" [hidden]="true">
  </kendo-grid-column>
  <kendo-grid-column [width]="140" field="good_standing" title="Good Standing" [filter]="'boolean'">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span *ngIf="dataItem.good_standing == true" class="badge badge-success">True</span>
      <span *ngIf="dataItem.good_standing == false" class="badge badge-danger">False</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [width]="120" field="completed_date" title="Completed" format="{0:MM/dd/yyyy}" filter="date">
  </kendo-grid-column>
  <kendo-grid-column [width]="120" field="status" title="Status">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="badge" [class.past]="dataItem.status=='Past'" [class.current]="dataItem.status=='Current'" [class.future]="dataItem.status=='Future'"> {{dataItem.status}}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [width]="140" field="id" title="" [filterable]="false" [sortable]="false" [columnMenu]="false">
    <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">

     <kendo-dropdownbutton [data]="button_list" (itemClick)="select_type($event)">
        <fa-icon [icon]="faMailBulk" [fixedWidth]="true"></fa-icon> Email All  <fa-icon class="ml-3" [icon]="faCaretDown" [fixedWidth]="true"></fa-icon>
      </kendo-dropdownbutton>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <button kendoButton *ngIf="recoveries_sent.indexOf(dataItem.id) < 0 && (dataItem.good_standing == false)"
              (click)="sendRecovery(dataItem)">
              <fa-icon [icon]="faEnvelope" [fixedWidth]="true"></fa-icon>
              Intro Email
      </button>
      <div *ngIf="recoveries_sent.indexOf(dataItem.id) >= 0" class="badge badge-primary">E-Mail Sent!</div>
    </ng-template>
  </kendo-grid-column>
</nexus-grid>

<kendo-dialog title="Email Future Residents" *ngIf="show_dialog" (close)="closeDialog()" [minWidth]="400">
  <nexus-card-body>
  <div class="col-md-12 mb-3">Select the maximum Start Date of the future Residents you would like to email:</div>
  <form [formGroup]="form">
  <div class="col-md-12 mb-3">
    <kendo-datepicker class="form-control" [format]="'MM/dd/yyyy'"
        formControlName="future_max_date" class="form-element"></kendo-datepicker>
  </div>
    </form>
  <div class="col-md-12 mb-3">
    <button kendoButton [primary]="true"  type="button" [disabled]="!form.valid || !intro_mail_model.future_max_date"
        (click)="sendIntroEmails('Future')" >Email Residents</button>
  </div>
  <div *ngIf= "form.get('future_max_date').errors && form.get('future_max_date').errors.futureDate " class="alert alert-warning" role="alert">Please select future date.</div>
<div class="alert alert-warning" role="alert">Emails will only be sent out to Residents that are currently present on the Client Portal Residents Page</div>
</nexus-card-body>
</kendo-dialog>