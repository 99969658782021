import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { ResetPasswordModel } from 'app/models/reset-password.model';
import { NexusFormResultModel } from 'app/models/nexus-form-result.model';
import { MembershipService } from 'app/core/services/membership.service';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { MustMatch } from 'app/core/validators/must-match.validator';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends NexusComponent {

  public reset_model: ResetPasswordModel = new ResetPasswordModel();
  public form_result: NexusFormResultModel = new NexusFormResultModel();

  public form: UntypedFormGroup;

  public faKey = faKey;

  constructor(private route: ActivatedRoute, private membership_service: MembershipService, private formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.form = this.formBuilder.group({
      password: ['', Validators.required],
      confirm_password: ['', Validators.required]
    },
      {
        validator: MustMatch('password', 'confirm_password')
      });

    this.form.valueChanges.subscribe((form_data: any) => {
      this.PopulateModelFromForm(form_data, this.reset_model);
    });
    this.route.params.subscribe(params => {
      if (params.user_id && params.reset_id) {
        this.reset_model.user_id = params.user_id;
        this.reset_model.reset_id = params.reset_id;
      }
    });
  }

  submit() {
    this.form_result.Reset();

    if (!this.validatePasswordConfirmation()) {
      this.form_result.Error('Passwords Must Match');
      return;
    }

    if (this.form.invalid) {
      this.form_result.Incomplete();
      return;
    }

    this.StartSpinner();
    this.membership_service.ResetPassword(this.reset_model).subscribe(
      data => {
        this.form_result.Success("Reset Successful, please navigate to <a href=\"/login\">Login</a> to proceed.");
        this.StopSpinner();
      },
      error => {
        this.form_result.Error(error);
        this.StopSpinner();
      });
  }

  public validatePasswordConfirmation(): boolean {
    let valid = false;
    if (this.form) {
      var newPassword = this.form.get('password').value;
      var confirmPassword = this.form.get('confirm_password').value;

      if (newPassword && confirmPassword && newPassword == confirmPassword) {
        valid = true;
      }
    }
    return valid;
  }
}
