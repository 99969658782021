import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { ResidentService } from '../services/resident.service';
import { PropertyCustomerModel } from 'app/models/property-customer.model';

@Injectable()
export class ResidentResolver implements Resolve<PropertyCustomerModel> {

    constructor(private service: ResidentService, private router: Router) 
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<PropertyCustomerModel> {
        return this.service.GetResident(route.params.id);
    }
}
