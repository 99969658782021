import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { ExpectedBillModel } from 'app/models/expected-bill.model';
import { GroupService } from 'app/services/group.service';
import { Observable } from 'rxjs';


@Injectable()
export class GroupAccountsResolver implements Resolve<Array<ExpectedBillModel>> {

    constructor(private service: GroupService, private router: Router) 
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<Array<ExpectedBillModel>> {
        return this.service.GetGroupExpectedBills(route.parent.params.group_id);                
    }
}
