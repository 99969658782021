import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'sbCurrency'
})
export class SbCurrencyPipe extends CurrencyPipe implements PipeTransform {

  transform(value: any, args?: any): any {
      if (value > -.01 && value < .01) {
          value = 0.00;
      }
      return super.transform(value, 'USD', 'symbol');
  }

}
