import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionModel } from 'app/models/session.model';
import { EventService } from 'app/core/services/event.service';
import { MembershipService } from 'app/core/services/membership.service';
import { GroupsModel } from 'app/models/groups.model';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {
  public session: SessionModel = null;
  public groups_model: GroupsModel;

  constructor(private route: ActivatedRoute, private membership: MembershipService, private event_service: EventService) {
    this.event_service.on('session-start', (model) => {
      this.session = model;
    });
    this.event_service.on('session-end', () => {
      this.session = null;
    });
  }

  ngOnInit(): void {
    this.membership.GetSession().subscribe(
      data => {
        this.session = data;
      },
      error => {
        this.session = null;
      });
    this.route.data.subscribe(({ groups_model }) => {
      this.groups_model = groups_model;
    });
  }
}
