import { GroupModel } from "./group.model";

export class CustomerModel
{
    public id: string;
    public first_name: string;
    public last_name: string;
    public email: string;
    public secondary_email: string;
    public phone: string;
    public secondary_phone: string;
    public birth_date: Date;
    public credit_reporting_enabled: boolean;
    public password: string;
    public name: string;
    public auto_pay: boolean;
    public force_pay: boolean;
    public service_fee_disabled: boolean;
    public late_fee_disabled: boolean;
    public checking_disabled: boolean;
    public credit_disabled: boolean;
    public zendesk_id: number;
    public group_id: string;
    public start_date: Date;
    public end_date: Date;
    public deposit_amount: number;
    public deposit_paid: Date;
    public create_invoice_lock: Date;
    public sales_force_id: string;
    public block_signup: boolean;
    public title: string;
    public group: GroupModel;

}

