import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { EventService } from 'app/core/services/event.service';
import { InvoiceTypeArgumentModel } from 'app/models/invoice-type-argument.model';
import { InvoiceModel, InvoicesModel } from 'app/models/invoices.model';
import { ResidentInvoicesSearchArgument } from 'app/models/resident-invoices.model';
import { ConfigService } from 'app/services/config.service';
import { InvoiceService } from 'app/services/invoice.service';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
interface Item {
  text: string,
  value: number
}
@Component({
  selector: 'app-resident-invoices',
  templateUrl: './resident-invoices.component.html',
  styleUrls: ['./resident-invoices.component.scss']
})
export class ResidentInvoicesComponent extends NexusComponent {

  public invoice_type: string = "resident";
  public months: Array<Item> = [
    { text: "January", value: 1 },
    { text: "February", value: 2 },
    { text: "March", value: 3 },
    { text: "April", value: 4 },
    { text: "May", value: 5 },
    { text: "June", value: 6 },
    { text: "July", value: 7 },
    { text: "August", value: 8 },
    { text: "September", value: 9 },
    { text: "October", value: 10 },
    { text: "November", value: 11 },
    { text: "December", value: 12 }
  ];
  public fileDownload = faFileDownload;

  public billing_period_date: Date;
  public invoice_model: InvoicesModel;
  public resident_invoices_search_argument: ResidentInvoicesSearchArgument = new ResidentInvoicesSearchArgument();
  public download_invoice_ids: Array<String> = new Array<String>();
  public billing_period_search_form = new UntypedFormGroup({
    billing_month: this.CreateRequiredFormControl(),
    billing_year: this.CreateRequiredFormControl()
  });
  constructor(private config: ConfigService, private route: ActivatedRoute, private router: Router, protected event_service: EventService, private invoice_service: InvoiceService) {
    super();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.setFormData();
  }
  private setFormData() {
    this.billing_period_search_form.reset();
    this.resident_invoices_search_argument.billing_month = this.config.getCurrentMoment().month() + 1;
    this.resident_invoices_search_argument.billing_year = this.config.getCurrentMoment().year();
    this.billing_period_date = moment([this.resident_invoices_search_argument.billing_year, this.resident_invoices_search_argument.billing_month - 1, 1]).toDate();
    this.billing_period_search_form.patchValue(this.resident_invoices_search_argument);
    this.billing_period_search_form.valueChanges.subscribe((form: any) => {
      this.PopulateModelFromForm(form, this.resident_invoices_search_argument);
    });
  }
  ngOnInit(): void {
    this.route.data.subscribe(({ invoice_model }) => {
      this.invoice_model = invoice_model;
    });
    this.setFormData();
    this.billingPeriodSearchSubmit();
  }

  billingPeriodSearchSubmit() {
    let selected_month: number = this.billing_period_search_form.get('billing_month').value;
    let selected_year: number = this.billing_period_search_form.get('billing_year').value;
    let selected_billing_period_date: Date = moment([selected_year, selected_month - 1, 1]).toDate();
    let model: InvoiceTypeArgumentModel = new InvoiceTypeArgumentModel();
    this.billing_period_date = selected_billing_period_date
    model.current_date = selected_billing_period_date;
    model.invoice_type = this.invoice_type;
    this.event_service.loadingStart();

    this.invoice_service.SearchResidentInvoices(model).subscribe(
      data => {
        this.invoice_model = data;
        console.log(this.invoice_model);
        this.event_service.loadingFinish();
      },
      err => {
        this.event_service.loadingFinish();
      });
  }


  downloadBills(invoice: InvoiceModel) {
    this.event_service.loadingStart();
    this.invoice_service.DownloadPDFs(invoice.id,false).subscribe(
      data => {
        this.download_invoice_ids.push(invoice.id);
        saveAs(data.url, data.file_name);
        this.event_service.loadingFinish();
      },
      err => {
        this.event_service.loadingFinish();
      });
  }
}
