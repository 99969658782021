import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { RegionService } from '../services/region.service';
import { RegionModel } from 'app/models/region.model';

@Injectable()
export class RegionsResolver implements Resolve<Array<RegionModel>> {

    constructor(private service: RegionService, private router: Router) 
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<Array<RegionModel>> {
        return this.service.GetRegions();                
    }
}
