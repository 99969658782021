<nexus-card card_title="Unit Integration Mappings">
    <nexus-grid [data]="group_integrations_model?.group_integration_mappings">
        <kendo-grid-column field="created_date" title="Created" format="G" filter="date"></kendo-grid-column>
        <kendo-grid-column field="reference_id" title="Reference"></kendo-grid-column>
        <kendo-grid-column field="group_bed_space.name" title="Bed Space Name"></kendo-grid-column>
    </nexus-grid>
</nexus-card>
<br>
<nexus-card card_title="Resident Integration Mappings">
    <nexus-grid [data]="group_integrations_model?.resident_integration_mappings">
        <kendo-grid-column field="created_date" title="Created" format="G" filter="date" [width]="100">
        </kendo-grid-column>
        <kendo-grid-column title="Resident Mapping Reference" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span *ngIf="dataItem.user_id">{{ dataItem.reference_id}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Lease Mapping Reference" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span *ngIf="(dataItem.user_in_group_id || dataItem.user_in_group_bed_space_id)">{{
                    dataItem.reference_id}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="customer_group_bed_space.bed_space.name" title="Bed Space Name" [width]="100">
        </kendo-grid-column>
        <kendo-grid-column field="customer_group.customer.first_name" title="First Name" [width]="100">
            <ng-template kendoGridCellTemplate let-dataItem>
                <nexus-customer-link  [customer_model]="dataItem.customer_group.customer" [text]="dataItem.customer_group.customer.first_name"></nexus-customer-link>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="customer_group.customer.last_name" title="Last Name" [width]="100">
            <ng-template kendoGridCellTemplate let-dataItem>
                <nexus-customer-link  [customer_model]="dataItem.customer_group.customer" [text]="dataItem.customer_group.customer.last_name"></nexus-customer-link>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="customer_group.customer.email" title="Email" [width]="150"></kendo-grid-column>
        <kendo-grid-column field="customer_group.start_date" title="Start Date" format="d" filter="date" [width]="100">
        </kendo-grid-column>
        <kendo-grid-column field="customer_group.end_date" title="End Date" format="d" filter="date" [width]="100">
        </kendo-grid-column>
    </nexus-grid>
</nexus-card>