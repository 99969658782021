import { Injectable } from '@angular/core';  
import { Subject, Observable } from 'rxjs';
import { SessionModel } from 'app/models/session.model';
import { TokenPropertyModel } from 'app/models/token-property.model';

@Injectable()
export class EventService {
    private listeners = {};
    private eventsSubject: Subject<Event>;
    private events: Observable<Event>;

    constructor() {
        this.eventsSubject = new Subject();
        this.events = this.eventsSubject.asObservable()
        this.events.subscribe(
            ({ name, args }) => {
                if (this.listeners[name]) {
                    for (let listener of this.listeners[name]) {
                        listener(...args);
                    }
                }
            });
    }

    on(name, listener) {
        if (!this.listeners[name]) {
            this.listeners[name] = [];
        }

        this.listeners[name].push(listener);
    }

    

    broadcast(name, ...args) {
        this.eventsSubject.next({
            name,
            args
        });
    }

    loadingStart(process: string = 'default') {
        this.broadcast('loading-start', process);
    }

    loadingFinish(process: string = 'default') {
        this.broadcast('loading-finish', process);
    }

    sessionStart(model: SessionModel)
    {
        this.broadcast('session-start', model);
    }
    
    sessionEnd()
    {
        this.broadcast('session-end');
    }

    propertyChanged(model: TokenPropertyModel)
    {
        this.broadcast('property-changed', model);
    }
    
}

export class Event
{
    public name: string;
    public args: string[];
}