<div class="report-div">
    <span *ngIf="hidden" class='report-hidden'>
        <fa-icon [icon]="faLowVision" [fixedWidth]="true"></fa-icon>
    </span>
    <ng-container *ngIf="report_title">
        <app-page-header [report_title]="report_title" [title]="report_title"></app-page-header>
    </ng-container>
    <div id="dashboardContainer"></div>
    <div *ngIf="t_embed">
        <ng-container  *ngIf="!t_embed.portfolioReport && t_embed.showPropertySelector" control_id="property-input" row_label="Property">     
          <form class="property-select">
            <nexus-form-row row_label="Property">
              <kendo-dropdownlist class="dropdown"
        [data]="properties_list_model"
        textField="name"
        valueField="propertyId"
        [defaultItem]="TSelectedProperty()"
        [valuePrimitive]="true"
      >
        <ng-template kendoDropDownListItemTemplate let-dataItem>
          <span (click)="setCurrentProperty(dataItem.propertyId)">{{ dataItem.name }}</span> 
        </ng-template>
      </kendo-dropdownlist>
              </nexus-form-row>
            </form>
        </ng-container>
          {{ Tlocalization() }}
          <tr-viewer #viewer1 [containerStyle]="viewerContainerStyle"
          [authenticationToken]="t_embed.accessToken"
          [reportServer]="{
              url: t_embed.reportServer
          }" [reportSource]="{
              report: t_embed.report,
              parameters: {
                  CultureID: 'en',
                  PropertyId: t_embed.propertyId,
                  CurrentUserId: t_embed.userId
              }
          }" [viewMode]="'INTERACTIVE'" [scaleMode]="'FIT_PAGE_WIDTH'" [scale]="1.0"
          [error]="THandler">
          </tr-viewer>
      </div>
      <div>
      <ng-container *ngIf="loaded && access_denied" >
        <div class="default-content">
          <p>
            You do not have access to this Report.
          </p>
        </div>
      </ng-container>
      </div>
      <div>
      <ng-container *ngIf="loaded && report_unavailable">
        <div class="default-content">
          <p>
            Report is currently unavailable.
          </p>
        </div>
      </ng-container>
      </div>
</div>
<ng-container *ngIf="qs_embed && false">
  <div class="row"></div>
  <div class="row">
      <span class="col-md-6">
      <input type="text" class="form-control" id="propertyid" placeholder="property-id OR blank" [(ngModel)]="propertyid" />
      </span>
      <button kendoButton [icon]="'wrench'" (click)="checkSecurity()">Check RLS</button>
    </div>
</ng-container>
<p style="margin-top: 6px;">
    <button kendoButton [icon]="'arrow-left'" [primary]="true" [routerLink]="['/reports']">Back</button>
</p>