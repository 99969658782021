
import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { UtilitiesSummaryModel, UtilitiesBillsArgument, UtilitiesSummaryArgument } from '../models/utilities.model';
import { ProviderBillFileModel } from 'app/models/provider-bill-file.model';
import { FileModel } from 'app/models/file.model';
import { ConfigService } from './config.service';
import { ProviderModel } from 'app/models/provider.model';
import { ProviderBillChargeModel } from 'app/models/provider-bill-charge.model';
import { UtilityDetailsModel } from 'app/models/utility-details.model';

@Injectable()
export class UtilityService {

    constructor(private http: HttpClient, private config: ConfigService) { }

    public GetUtilitiesBills(argument: UtilitiesBillsArgument): Observable<Array<UtilityDetailsModel>> {
        return this.http.post<Array<UtilityDetailsModel>>(this.config.getApiRoute('api/v3/client-portal/utilities'), argument).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return throwError(err.error.message);
            }));
    }

    public GetUtilitiesSummary(argument: UtilitiesSummaryArgument): Observable<UtilitiesSummaryModel> {
        return this.http.post<UtilitiesSummaryModel>(this.config.getApiRoute('api/v3/client-portal/utilities/summary'), argument).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return throwError(err.error.message);
            }));
    }

    public CreateExport(argument: UtilityDetailsModel): Observable<ProviderBillFileModel> {
        return this.http.get<ProviderBillFileModel>(this.config.getApiRoute('api/v3/client-portal/utilities/' + argument.provider_bill_charge_id + '/files/' + argument.provider_bill_file_id)).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return throwError(err.error.message);
            }));
    }

    public DownloadPDFs(argument: UtilitiesBillsArgument): Observable<UtilitiesBillsArgument> {
        return this.http.put(this.config.getApiRoute('api/v3/client-portal/utilities/export-files'), argument).pipe(
            map(data => {
                return null;
            }),
            catchError((err: HttpErrorResponse) => {
                return throwError(err.error.message);
            }));
    }

    public DownloadExport(export_model: FileModel): Observable<Blob> {
        let headers = new HttpHeaders();

        if (export_model.content_type) {
            headers.set('Accept', export_model.content_type);
        }

        return this.http.get(export_model.url, { responseType: 'blob', headers: headers }).pipe(
            map(data => {
                return new Blob([data], { type: export_model.content_type })
            }),
            catchError((err: HttpErrorResponse) => {
                return throwError(err.error.message);
            }));

    }

    public GetProviders(): Observable<Array<ProviderModel>> {
        return this.http.get(this.config.getApiRoute('api/v3/client-portal/utilities/providers')).pipe(
            map(data => {
                return <Array<ProviderModel>>data;
            }),
            catchError((err: HttpErrorResponse) => {
                return throwError(err.error.message);
            }));
    }
}
