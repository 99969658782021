<app-page-header title="Units"></app-page-header>

<nexus-grid [data]="groups_model?.groups">
    <kendo-grid-column field="address1" title="Address 1">
        <ng-template kendoGridCellTemplate let-dataItem>
            <nexus-group-link  [group_model]="dataItem" [text]="dataItem.address1">
            </nexus-group-link>
          </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="address2" title="Address 2">
        <ng-template kendoGridCellTemplate let-dataItem>
            <nexus-group-link  [group_model]="dataItem" [text]="dataItem.address2">
            </nexus-group-link>
          </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="city" title="City"></kendo-grid-column>
    <kendo-grid-column field="region.name" title="State"></kendo-grid-column>
    <kendo-grid-column field="zip5" title="Zip"></kendo-grid-column>
    <kendo-grid-column field="start_date" title="Start" format="{0:MM/dd/yyyy}" filter="date" [hidden]="true">
    </kendo-grid-column>
    <kendo-grid-column field="end_date" title="End" format="{0:MM/dd/yyyy}" filter="date" [hidden]="true">
    </kendo-grid-column>
    <kendo-grid-column field="current_transition.group_transition_type.status" title="Status"
        *ngIf="session.current_property.market.group_transitions_enabled"></kendo-grid-column>
    <kendo-grid-column field="current_transition.transition_date" title="Status Date" format="d" filter="date"
        *ngIf="session.current_property.market.group_transitions_enabled">
    </kendo-grid-column>

    <kendo-grid-column field="id" title="" [filterable]="false" [sortable]="false" [width]="60"
        *ngIf="groups_model.create_group_enabled">
        <ng-template kendoGridCellTemplate let-dataItem>
            <button kendoButton icon="pencil" [routerLink]="['/groups', dataItem.id]"></button>
        </ng-template>
    </kendo-grid-column>
</nexus-grid>

<p class="mt-3" *ngIf="groups_model.create_group_enabled">
    <button kendoButton icon="plus" [routerLink]="['/groups', 'new']">Add Unit</button>
</p>
