import { Injectable } from '@angular/core';
import * as moment from 'moment/moment';

@Injectable()
export class LocalizationService {

  constructor() { }

  private json_date_time = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;
  private json_date = /^\d{4}-\d\d-\d\dT00:00:00$/;

  public Deserialize<T>(json: string) : T
  {
    let object = JSON.parse(json) as T;
    this.LocalizeObjectFromAPI(object);
    return object;
  }

  public Serialize(object: any) : string
  {
    this.LocalizeObjectToAPI(object);
    return JSON.stringify(object);
  }

  
  public LocalizeObjectFromAPI(object: Object) {
      if (!object || !(object instanceof Object)) {
          return;
      }

      if (object instanceof Array) {
          for (const item of object) {
              this.LocalizeObjectFromAPI(item);
          }
      }

      for (const key of Object.keys(object)) {
          const value = object[key];

          if (value instanceof Array) {
              for (const item of value) {
                  this.LocalizeObjectFromAPI(item);
              }
          }

          if (value instanceof Object) {
              this.LocalizeObjectFromAPI(value);
          }
          
          if(this.json_date.test(value))
          {
              object[key] = moment(value).toDate();
          }
          else if(this.json_date_time.test(value))
          {
              object[key] = moment.utc(value).toDate();
          }
      }
  }

  public LocalizeObjectToAPI(object: Object) {
    if (!object || !(object instanceof Object)) {
      return;
    }

    if (object instanceof Array) {
      for (const item of object) {
        this.LocalizeObjectToAPI(item);
      }
    }

    for (const key of Object.keys(object)) {
      const value = object[key];

      if (value instanceof Array) {
        for (const item of value) {
          this.LocalizeObjectToAPI(item);
        }
      }

      if (value instanceof Object) {
        this.LocalizeObjectToAPI(value);
      }

      if (value instanceof Date) {
        if (moment(value).format('HH:mm:ss') == '00:00:00') {
          object[key] = moment(value).format('YYYY-MM-DD');
        }
        else {
          object[key] = moment(value).toISOString();
        }
      }
    }
  }
}
