import { Component } from '@angular/core';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss']

})
export class AppFooterComponent { 
  public faFacebookF = faFacebookF;
  public faInstagram = faInstagram;
  public faTwitter = faTwitter;
  public currentYear: number = new Date().getFullYear();
    constructor() {
       
    }

}
