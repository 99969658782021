import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { PropertyFileModel } from 'app/models/property-file.model';
import { PropertyFilesService } from 'app/services/property-files.service';

@Component({
  selector: 'property-file-delete-modal',
  templateUrl: './property-file-delete-modal.component.html',
  styleUrls: ['./property-file-delete-modal.component.scss']
})
export class PropertyFileDeleteModalComponent extends NexusComponent {
  @Input() property_file_model: PropertyFileModel;

  constructor(private router: Router, private propertyFilesService: PropertyFilesService) {
    super();
  }
  ngOnInit(): void {

  }

  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() delete: EventEmitter<any> = new EventEmitter<any>();

  public onDelete(e) {
    e.preventDefault();
    this.StartSpinner();
    this.propertyFilesService.DeletePropertyFile(this.property_file_model.id).subscribe(
      data => {
        this.StopSpinner();
        this.delete.emit(data);
        this.closeDialog();
      },
      error => {
        this.StopSpinner();
      }
    );
  }

  public onCancel(e) {
    e.preventDefault();
    this.closeDialog();
  }

  public closeDialog():void{
    this.cancel.emit();
  }
}
