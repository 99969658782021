
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { DashboardModel } from '../models/dashboard.model';
import { UserModel } from 'app/models/user.model';
import { CurrentDateModel } from 'app/models/current-date.model';
import { ConfigService } from './config.service';

@Injectable()
export class DashboardService {

  constructor(private http: HttpClient, private config: ConfigService) { }

  public GetDashboard(): Observable<DashboardModel> {
    let model = new CurrentDateModel();
    model.current_date = this.config.getCurrentMoment().startOf('day').toDate();

    return this.http.post<DashboardModel>(this.config.getApiRoute('api/v3/client-portal/dashboard'), model).pipe(
      map(data => {
        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err.error.message);
      }));
  }

}
