<app-page-header title="Resources"></app-page-header>

<nexus-grid [data]="resources_model">
  <kendo-grid-column field="thumbnail" title="Thumbnail" [width]="350">
    <ng-template kendoGridCellTemplate let-dataItem>
      <img [src]="dataItem.file_thumbnail_file_id?.url" style="max-width:250px; max-height: 100px; min-height: 100px;" />
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="title" title="Title"></kendo-grid-column>
  <kendo-grid-column field="description" title="Description"></kendo-grid-column>
  <kendo-grid-column field="resource_category.name" title="Category" [width]="180"></kendo-grid-column>
  <kendo-grid-column field="resource_type" title="Resource Type" [width]="150"></kendo-grid-column>
  <kendo-grid-column field="id" title="" [filterable]="false" [sortable]="false" [columnMenu]="false"
     [width]="75">
    <ng-template kendoGridCellTemplate let-dataItem>
      <a class="btn btn-secondary" (click)="navigate(dataItem)">Open</a>
    </ng-template>
  </kendo-grid-column>
</nexus-grid>
