import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { EventService } from '../../core/services/event.service';
import { faAt } from '@fortawesome/free-solid-svg-icons';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { ForgotPasswordModel } from 'app/models/forgot-password.model';
import { NexusFormResultModel } from 'app/models/nexus-form-result.model';
import { MembershipService } from 'app/core/services/membership.service';
@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent extends NexusComponent {

    public forgot_model: ForgotPasswordModel = new ForgotPasswordModel();
    public form_result: NexusFormResultModel = new NexusFormResultModel();
    public form = new UntypedFormGroup({
        email: new UntypedFormControl('', Validators.required)
    });

    public faAt = faAt;

    constructor(private router: Router, private membership_service: MembershipService) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.form.valueChanges.subscribe((form: any) => {
            this.PopulateModelFromForm(form, this.forgot_model);
        });
    }

    submit() {
        this.form_result.Reset();

        if (this.form.invalid) {
            this.form_result.Incomplete();
            return;
        }

        this.StartSpinner();
        this.membership_service.ForgotPassword(this.forgot_model).subscribe(
            data => {
                this.form_result.Success("Please check your e-mail for the password reset link.");
                this.StopSpinner();
            },
            error => {
                this.form_result.Error(error);
                this.StopSpinner();
            });
    }

}
