import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventService } from '../../core/services/event.service';
import { ResidentService } from '../../services/resident.service';
import { ResidentsModel } from 'app/models/residents.model';
import { PropertyCustomerModel } from 'app/models/property-customer.model';

@Component({
    selector: 'app-move-in',
    templateUrl: './move-in.component.html',
    styleUrls: ['./move-in.component.scss']
})
export class MoveInComponent implements OnInit {
    public residents_model: ResidentsModel;
    public recoveries_sent: string[] = [];

    constructor(private route: ActivatedRoute, private router: Router,  private event_service: EventService, private resident_service: ResidentService) { 
    }

    ngOnInit() {
        this.route.data.subscribe(({ residents_model }) => {
            this.residents_model = residents_model;
        });
    }

    sendRecovery(resident: PropertyCustomerModel) {
        this.event_service.loadingStart();

        this.resident_service.SendRecovery(resident).subscribe(
            data => {
                this.recoveries_sent.push(resident.id);
                this.event_service.loadingFinish();                      
            },
            err => {
                this.event_service.loadingFinish();
            });
    }
}
