import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { CustomerModel } from 'app/models/customer.model';
import { InvoiceItemModel } from 'app/models/invoice-item.model';
import { InvoiceModel } from 'app/models/invoices.model';


@Component({
  selector: 'app-customer-invoices',
  templateUrl: './customer-invoices.component.html',
  styleUrls: ['./customer-invoices.component.scss']
})
export class CustomerInvoicesComponent extends NexusComponent {

  public invoices_model: Array<InvoiceModel>
  public customer_model: CustomerModel
  public invoice_items_model: Array<InvoiceItemModel>;
  public ledger_details:boolean = false;
  constructor(private route: ActivatedRoute) {
    super();
   }


  ngOnInit(): void {
    this.route.parent.data.subscribe(({ customer_model }) => {
      this.customer_model = customer_model;
      
    });
    this.route.data.subscribe(({ invoices_model,invoice_items_model }) => {
      this.invoices_model = invoices_model;
      this.invoice_items_model = invoice_items_model;
    });

    
  }
  public ledgerDetailChange(value:boolean){
      this.ledger_details=value;
  }
}
