<app-page-header title="Property File"></app-page-header>

<div class="row">

  <div class="col-sm-6 offset-lg-3">

    <div class="card">
      <div class="card-header">Edit Property File</div>
      <div class="card-body">
        <form [formGroup]="form">
          <nexus-form-row control_id="name-input" row_label="Name">
            <input kendoTextBox type="text" class="form-control" id="name-input" formControlName="name" />
          </nexus-form-row>

          <nexus-form-row control_id="file-input" row_label="File">
            <div class="mt-2">
              <nexus-file-link [file_model]="property_file_model.file" [session_model]="session" class="grid-link"></nexus-file-link>
            </div>
          </nexus-form-row>

          <nexus-form-row control_id="notes-input" row_label="Notes">
            <textarea kendoTextArea class="form-control w-100" id="notes-input" formControlName="notes" >
            </textarea>
          </nexus-form-row>

          <nexus-form-row>
            <button kendoButton icon="check" (click)="submit()" [primary]="true">Submit</button>
          </nexus-form-row>
        </form>
        <nexus-form-result [model]="form_result"></nexus-form-result>
      </div>
    </div>
  </div>
</div>
