import { Injector } from '@angular/core';

export class NexusInjector {

  private static injector: Injector;

  static setInjector(injector: Injector) {

    NexusInjector.injector = injector;

  }

  static getInjector(): Injector {

    return NexusInjector.injector;

  }

}     