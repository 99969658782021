import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError , Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EnumModel } from '../../models/enum.model';
import { ConfigService } from 'app/services/config.service';

@Injectable({
  providedIn: 'root'
})
export class EnumService {

  constructor(private http: HttpClient, private config: ConfigService) { }

  public GetEnum(enum_type: string): Observable<Array<EnumModel>> {
    return this.http.get<Array<EnumModel>>(this.config.getApiRoute('api/v3/common/enums/' + enum_type)).pipe(
      map(data => {
        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err.error.message);
      }));
  }

}
