import { GroupOwnerType } from "app/enums/group-owner-type.enum";
import { AssetManagerModel } from "./asset-manager.model";

export class GroupOwnerModel
{
	public group_owner_id: string;
    public created_date: Date;
    public name: string;
    public type: GroupOwnerType;
    public legal_name: string;
    public tax_id_number: string;
    public birth_date: Date;
    public asset_manager:AssetManagerModel;
}