<div class="input-group">
  <input type="text" class="form-control" placeholder="Search Group Owners" aria-label="Search Group Owners"
    aria-describedby="group-owner-search-button" [disabled]="is_disabled" [(ngModel)]="search_argument.search">
  <div class="input-group-append">
    <button class="btn btn-outline-secondary" type="button" id="group-owner-search-button" (click)="search()"
      [disabled]="is_disabled">
      <fa-icon [icon]="faSearch"></fa-icon>
    </button>
    <button class="btn btn-outline-warning" type="button" id="group-owner-search-button" (click)="clear()" *ngIf="group_owner_id" [disabled]="is_disabled">
      <fa-icon icon="times-circle"></fa-icon>
    </button>
  </div>
</div>
<nexus-form-result [model]="search_result"></nexus-form-result>
<div *ngIf="group_owners_model && group_owners_model.length > 0">
  <div class="card mt-1 mb-1" *ngFor="let group_owner of group_owners_page">
    <div class="card-body">
      <input type="radio" name="group_owner_id" [value]="group_owner.group_owner_id" [id]="group_owner.group_owner_id" [disabled]="is_disabled"
        [(ngModel)]="group_owner_id" (change)="propagateChange(group_owner_id)">
      <label class="ml-1 mb-0" [for]="group_owner.group_owner_id">
        {{ group_owner.name }} <br /> <i>{{ group_owner.asset_manager?.name }}</i>
      </label>
    </div>
  </div>
  <ngb-pagination [collectionSize]="group_owners_model.length" [(page)]="current_page" (pageChange)="page_changed()"
    [maxSize]="5"></ngb-pagination>
</div>