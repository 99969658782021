import { CustomerModel } from "./customer.model";
import { GroupModel } from "./group.model";

export class PropertyCustomerModel
{
    public id: string;
    public customer_id: string;
    public customer: CustomerModel;
    public group_id: string;
    public group: GroupModel;
    public start_date: Date;
    public end_date: Date;
    public active: boolean;
    public good_standing: boolean;
    public completed_date: Date;
    public last_service_fee_date: Date;
    public last_service_fee_amount: number;
    public last_confirmation_code: string;

    constructor()
    {
        this.customer = new CustomerModel();
    }
}