import { AfterContentInit, AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventService } from 'app/core/services/event.service';
import { FilesService } from 'app/core/services/files.service';
import { MembershipService } from 'app/core/services/membership.service';
import { SessionModel } from 'app/models/session.model';

@Component({
  selector: 'app-file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['./file-download.component.scss']
})
export class FileDownloadComponent implements OnInit,AfterViewInit,AfterContentInit {


  file_id: string;
  error_message:string;
  message: string;
  session: SessionModel;
  constructor(private route: ActivatedRoute, private file_service: FilesService,private event_service: EventService,private membership:MembershipService) {
    this.event_service.on('session-start', (model) => {
      this.session = model;
    });
    this.event_service.on('session-end', () => {
      this.session = null;
    });
  }



  ngOnInit(): void {
    this.membership.GetSession().subscribe(
      data => {
        this.session = data;
      },
      error => {
        this.session = null;
      });
    this.route.params.subscribe(params => {
      if (params.file_id) {
        this.file_id = params.file_id;
      }
    });
  }

  ngAfterViewInit(): void {

  }

  ngAfterContentInit(): void {
    this.event_service.loadingStart();
    this.error_message = "";
    this.message = "Locating file...";
    this.handleLink();
  }

  handleLink() {
    const a: HTMLAnchorElement = document.createElement("a") as HTMLAnchorElement;

    a.href = this.file_service.GetPublicFilePath(this.file_id,this.session?.user_id);
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();

    setTimeout(function() {
      document.body.removeChild(a);
    }, 100);

    this.message = "Your file should be ready to view or download.";
    this.event_service.loadingFinish();
  }

}
