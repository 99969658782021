import { Pipe, PipeTransform } from '@angular/core';
import { format, ParsedNumber, NationalNumber, parsePhoneNumberFromString } from 'libphonenumber-js';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return value;
    }
    let phoneNumber = parsePhoneNumberFromString(value, 'US');
    if(!phoneNumber)
    {
      return value;
    }
    return phoneNumber.formatNational();
  }

}