<app-page-header title="Resident Invoices"></app-page-header>
<div class="navbar navbar-light bg-light navbar-expand-md">
    <span class="navbar-brand">{{billing_period_date | amDateFormat:'MMMM YYYY' }}</span>


    <div class="collapse navbar-collapse">
        <ul class="navbar-nav mr-auto"></ul>

        <form [formGroup]="billing_period_search_form" (ngSubmit)="(billingPeriodSearchSubmit())">
            <kendo-dropdownlist placeholder="Billing Month" formControlName="billing_month" [data]="months"
                textField="text" valueField="value" [valuePrimitive]="true" class="filter-control">
            </kendo-dropdownlist>
            <kendo-numerictextbox placeholder="Billing Year" formControlName="billing_year" [min]="2000" [max]="2050"
                [autoCorrect]="true" [format]="'0'" class="filter-control"></kendo-numerictextbox>
            <button kendoButton [primary]="true" [icon]="'search'" type="submit"
                [disabled]="!billing_period_search_form.valid">Search</button>
        </form>
    </div>
</div>
<div class="navbar navbar-expand-lg navbar-light bg-light"
    *ngIf="invoice_model.current_invoice && invoice_model.current_invoice.id">
    <span class="navbar-brand">Resident Invoices</span>
</div>
<div class="resident-invoices">
  <nexus-grid [data]="invoice_model.invoices">
    <kendo-grid-column [width]="100" field="invoice_number" title="Invoice #">
      <ng-template kendoGridCellTemplate let-dataItem>
        <nexus-resident-invoice-link [invoice_model]="dataItem"></nexus-resident-invoice-link>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="120" field="billing_period_date" title="Billing Period" format="{0:MMMM yyyy}"
                       filter="date">
    </kendo-grid-column>
    <kendo-grid-column field="customer.first_name" title="First Name" [width]="110">
      <ng-template kendoGridCellTemplate let-dataItem>
        <nexus-customer-link [customer_model]="dataItem.customer" [text]="dataItem.customer.first_name"></nexus-customer-link>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="customer.last_name" title="Last Name" [width]="110">
      <ng-template kendoGridCellTemplate let-dataItem>
        <nexus-customer-link [customer_model]="dataItem.customer" [text]="dataItem.customer.last_name"></nexus-customer-link>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="group.address1" title="Address 1" [width]="120">
      <ng-template kendoGridCellTemplate let-dataItem>
        <nexus-group-link [group_model]="dataItem.group" [text]="dataItem.group?.address1">
        </nexus-group-link>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="group.address2" title="Unit" [width]="120">
      <ng-template kendoGridCellTemplate let-dataItem>
        <nexus-group-link [group_model]="dataItem.group" [text]="dataItem.group?.address2">
        </nexus-group-link>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="item_amount" title="Amount" filter="numeric" format="{0:c}" [width]="100">
    </kendo-grid-column>
    <kendo-grid-column field="balance" title="Balance" filter="numeric" format="{0:c}" [width]="100">
    </kendo-grid-column>
    <kendo-grid-column field="date_due" title="Date Due" [width]="100" format="d" filter="date"></kendo-grid-column>
    <kendo-grid-column [width]="100" field="settled" title="Settled On" [filter]="'boolean'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span *ngIf="dataItem.settled" class="badge badge-success">{{ dataItem.settled_date | amDateFormat:'M/D/YYYY' }}</span>
        <span *ngIf="!dataItem.settled" class="badge badge-danger">Unsettled</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [sticky]="true" [width]="100" [filterable]="false" [sortable]="false"
                       [columnMenu]="false">
      <ng-template kendoGridCellTemplate let-dataItem>
        <button kendoButton title="Download all Provider Bill Images for this Resident Invoice"
                (click)="downloadBills(dataItem)" *ngIf="dataItem.contains_provider_bill_file">
          <fa-icon [icon]="fileDownload"></fa-icon>
        </button>
      </ng-template>
    </kendo-grid-column>
  </nexus-grid>
</div>
