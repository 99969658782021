import { Component, ViewEncapsulation } from '@angular/core';
import { GroupModel } from 'app/models/group.model';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GroupComponent extends NexusComponent {

  constructor(protected route: ActivatedRoute) {
    super();
   }
  public group_model: GroupModel;
  public menu_collapsed = false;
  public current_component: string;
  public items : Array<NavItem>;
  ngOnInit(): void {
    this.route.data.subscribe(({ group_model }) => {
      this.group_model = group_model;
    this.items = [
      {
        name: 'Information',
        route: ['/groups', this.group_model.id,'information'],
        component: 'GroupInformationComponent',
        enabled:true
      },
      {
        name: 'Invoices',
        route: ['/groups', this.group_model.id,'invoices'],
        component: 'GroupInvoicesComponent',
        enabled:true
      },
      {
        name: 'Residents',
        route: ['/groups', this.group_model.id,'residents'],
        component: 'GroupResidentsComponent',
        enabled:true
      },
      {
        name: 'Accounts',
        route: ['/groups', this.group_model.id,'accounts'],
        component: 'GroupAccountsComponent',
        enabled:true
      },
      {
        name: 'Future Billing',
        route: ['/groups', this.group_model.id,'future-billing'],
        component: 'GroupFutureBillingComponent',
        enabled:true
      },
      {
        name: 'Integrations',
        route: ['/groups', this.group_model.id,'integrations'],
        component: 'GroupIntegrationsComponent',
        enabled: this.group_model.group_integration_enabled
      }
    ];
  });
  }


}


export class NavItem {
  public name: string;
  public route: Array<string>;
  public component: string;
  public enabled: boolean;
}