<nexus-grid  [data]="!ledger_details?invoices_model:invoice_items_model" [date_range_filter_key]="'created_date'" (toggleValueChange)="ledgerDetailChange($event)" [show_toggle]="true" 
[toggle_label]="'Ledger Details'" [start_date_place_holder_text]="'Created From'" [end_date_place_holder_text]="'Created To'">
<ng-container *ngIf="!ledger_details">
  <kendo-grid-column field="created_date" title="Created" format="G" filter="date"></kendo-grid-column>
  <kendo-grid-column  field="invoice_number" title="Invoice #">
      <ng-template kendoGridCellTemplate let-dataItem>
          <nexus-resident-invoice-link  [invoice_model]="dataItem"></nexus-resident-invoice-link>
      </ng-template>
  </kendo-grid-column>
  <kendo-grid-column  field="billing_period_date" title="Period" format="d"
      filter="date"></kendo-grid-column>
  <kendo-grid-column field="item_amount" title="Amount" filter="numeric" format="{0:c}" >
  </kendo-grid-column>
  <kendo-grid-column field="balance" title="Balance" filter="numeric" format="{0:c}" >
  </kendo-grid-column>
  <kendo-grid-column field="date_due" title="Date Due"  format="d" filter="date"></kendo-grid-column>
  <kendo-grid-column field="settled" title="Date Settled" [filter]="'boolean'">
      <ng-template kendoGridCellTemplate let-dataItem>
          <span *ngIf="dataItem.settled" class="badge badge-success">{{ dataItem.settled_date | amDateFormat:'M/D/YYYY' }}</span>
          <span *ngIf="!dataItem.settled" class="badge badge-danger">Unsettled</span>
      </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="late_fee_charged" title="Late Fee Charged">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span *ngIf="dataItem.late_fee_charged" class="badge badge-pill badge-danger">Late Fee</span>
      </ng-template>
    </kendo-grid-column>
  <kendo-grid-column field="defaulted_date" title="Defaulted">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span *ngIf="dataItem.defaulted_date" class="badge badge-pill badge-danger">Defaulted</span>
      </ng-template>
    </kendo-grid-column>
</ng-container>  

<ng-container *ngIf="ledger_details">
  <kendo-grid-column  field="invoice.invoice_number" title="Invoice #" [width]="100"> 
    <ng-template kendoGridCellTemplate let-dataItem>
        <nexus-resident-invoice-link  [invoice_model]="dataItem.invoice"></nexus-resident-invoice-link>
    </ng-template>
</kendo-grid-column>
  <kendo-grid-column field="created_date" title="Date Created" format="G" filter="date" [width]="140">
  </kendo-grid-column>
  <kendo-grid-column field="invoice_item_type.name" title="Type" [width]="100"></kendo-grid-column>
  <kendo-grid-column field="expected_bill.provider.name" title="Provider" [width]="100">
  </kendo-grid-column>
  <kendo-grid-column field="provider_category.name" title="Category" [width]="100">
  </kendo-grid-column>
  <kendo-grid-column field="group.address1" title="Address" [width]="120">
    <ng-template kendoGridCellTemplate let-dataItem>
      <nexus-group-link *ngIf="dataItem.group"  [group_model]="dataItem.group"
        [text]="dataItem.group.address1"></nexus-group-link>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="group.address2" title="Unit" [width]="100">
    <ng-template kendoGridCellTemplate let-dataItem>
      <nexus-group-link  [group_model]="dataItem.group" [text]="dataItem.group?.address2">
      </nexus-group-link>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="amount" title="Amount" filter="numeric" format="{0:c}" [width]="100">
  </kendo-grid-column>
  <kendo-grid-column field="percent" title="Percent" filter="numeric" format="{0:p}" [width]="100">
  </kendo-grid-column>
  <kendo-grid-column field="start_date" title="Start" [width]="100" format="d" filter="date"></kendo-grid-column>
  <kendo-grid-column field="end_date" title="End" [width]="100" format="d" filter="date"></kendo-grid-column>
  <kendo-grid-column field="usage_days" title="Usage Days" filter="numeric" [width]="100"></kendo-grid-column>
  <kendo-grid-column field="ledger_code.name" title="Ledger Code" [width]="120"></kendo-grid-column>
  <kendo-grid-column field="reference" title="Reference" [width]="100"></kendo-grid-column>
  <kendo-grid-column field="defaulted_date" title="Defaulted" format="G" filter="date" [width]="100">
  </kendo-grid-column>
  <kendo-grid-column field="voided" title="Voided" [width]="100">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span *ngIf="dataItem.voided" class="badge badge-pill badge-danger">Voided</span>
    </ng-template>
  </kendo-grid-column>
</ng-container>
</nexus-grid>
