import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { EnumService } from 'app/core/services/enum.service';
import { MembershipService } from 'app/core/services/membership.service';
import { EnumModel } from 'app/models/enum.model';
import { NexusFormResultModel } from 'app/models/nexus-form-result.model';
import { PropertyFileModel } from 'app/models/property-file.model';
import { SessionModel } from 'app/models/session.model';
import { PropertyFilesService } from 'app/services/property-files.service';

@Component({
  selector: 'app-property-files',
  templateUrl: './property-files.component.html',
  styleUrls: ['./property-files.component.scss']
})
export class PropertyFilesComponent extends NexusComponent {

  property_files_model: Array<PropertyFileModel> = [];
  public session: SessionModel = null;
  public delete_item: PropertyFileModel;
  public form_result: NexusFormResultModel = new NexusFormResultModel();
  public property_file_model: PropertyFileModel = new PropertyFileModel();

  public file_types: Observable<Array<EnumModel>>;
  public form = new UntypedFormGroup({
    name: this.CreateRequiredFormControl(),
    notes: this.CreateRequiredFormControl(),
    file: this.CreateRequiredFormControl(),
    file_type: this.CreateFormControl()
  });

  constructor(private route: ActivatedRoute, private router: Router, private membership: MembershipService, private property_files_service: PropertyFilesService,private enum_service: EnumService) {
    super();
    this.event_service.on('session-start', (model) => {
      this.session = model;
    });
    this.event_service.on('session-end', () => {
      this.session = null;
    });
  }

  ngOnInit(): void {
    this.file_types = this.enum_service.GetEnum('PropertyFileType');

    this.route.data.subscribe(({ property_files_model }) => {
      this.property_files_model = property_files_model;
    });

    this.membership.GetSession().subscribe(
      data => {
        this.session = data;
      },
      error => {
        this.session = null;
      });


    this.form.valueChanges.subscribe((form_data: any) => {
      this.PopulateModelFromForm(form_data, this.property_file_model);
    });

  }

  getPropertyFiles() {
    this.StartSpinner();
    this.property_files_service.GetPropertyFiles().subscribe(
      data => {
        this.StopSpinner();
        this.property_files_model = data;
      }, error => {
        this.StopSpinner();
      }
    );
  }

  public deleteHandler(dataItem: PropertyFileModel) {
    this.delete_item = dataItem;
  }

  public cancelHandler() {
    this.delete_item = undefined;

  }

  public confirmDeleteHandler(item: PropertyFileModel) {
    this.delete_item = undefined;

    this.getPropertyFiles();
  }

  submit() {
    this.form_result.Reset();

    this.SetFormTouched(this.form);
    if (this.form.invalid) {
      this.form_result.Incomplete();
      return;
    }
    this.StartSpinner();
    this.property_files_service.SavePropertyFile(this.property_file_model).subscribe(
      data => {
        this.property_file_model = new PropertyFileModel();
        this.form.reset();
        this.form_result.Success();
        this.StopSpinner();
        this.getPropertyFiles();
      },
      error => {
        this.form_result.Error(error);
        this.StopSpinner();
      }
    );
  }
}
