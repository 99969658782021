import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { GroupModel } from 'app/models/group.model';
import { ProviderBillPortionModel } from 'app/models/provider-bill-portion.model';

@Component({
  selector: 'app-group-future-billing',
  templateUrl: './group-future-billing.component.html',
  styleUrls: ['./group-future-billing.component.scss']
})
export class GroupFutureBillingComponent extends NexusComponent {

  public group_model: GroupModel;
  public provider_bill_portions_model: Array<ProviderBillPortionModel>;

  constructor(protected route: ActivatedRoute) {
   super();
  }

  ngOnInit() {
    this.route.parent.data.subscribe(({ group_model }) => {
      this.group_model = group_model;
    });

    this.route.data.subscribe(({ provider_bill_portions_model }) => {
      this.provider_bill_portions_model = provider_bill_portions_model;
    });

  }
}

