import { PropertyFileType } from 'app/enums/property-file-type.enum';
import { FileModel } from './file.model';
import { UserModel } from './user.model';

export class  PropertyFileModel{
  public id: string;
  public name: string;
  public notes:string;
  public file_id:string;
  public file:FileModel;
  public property_file_id:string;
  public date_created: Date;
  public created_by_user: UserModel;
  public file_type: PropertyFileType;
}
