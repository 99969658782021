<div class="row">
  <div class="col-lg-5">
      <h1>{{ title }}</h1>
  </div>
  <div class="col-lg-7 address-column" *ngIf="session?.current_property && show_property_context && !report_title">
      <p><strong>{{ session.current_property.name }}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;<span>{{ session.current_property.address1 }}</span>&nbsp;&nbsp;|&nbsp;&nbsp;<span>{{ session.current_property.city }}<span *ngIf="session.current_property.region && session.current_property.city">,</span> {{ session.current_property.region?.code }} {{ session.current_property.zip5 }}</span>
      </p>
  </div>
<div class="col-lg-7 address-column" *ngIf="show_resident_invoice_group_info">
    <p><strong>{{ group_model.property.name }}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;<span>{{ group_model.address1
            }}</span><span *ngIf="group_model.address2">&nbsp;&nbsp;|&nbsp;&nbsp;</span><span>{{ group_model.address2 }}</span>&nbsp;&nbsp;|&nbsp;&nbsp;<span>{{
            group_model.city }}<span *ngIf="group_model.region && group_model.city">,</span> {{ group_model.region?.code
            }} {{ group_model.zip5 }}</span>
    </p>
</div>
</div>