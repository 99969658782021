import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ReportEmbedModel } from 'app/models/report-embed.model';
import { ReportEmbedService } from 'app/services/report-embed.service';

@Injectable()
export class ReportResolver implements Resolve<ReportEmbedModel> {

    constructor(private service: ReportEmbedService) 
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<ReportEmbedModel> {
        return this.service.GetEmbed(route.params.report_id);
    }
}
