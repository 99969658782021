<app-page-header title="Property Files"></app-page-header>

<div class="row mb-4">
    <div class="col-sm-5">
        <div class="card">
            <div class="card-header">Upload Property File</div>
            <div class="card-body">
                <form [formGroup]="form">
                    <nexus-form-row row_label="File">
                        <nexus-form-file-input [multiple]="false" [valuePrimitive]="false" formControlName="file">
                        </nexus-form-file-input>
                    </nexus-form-row>
                    <nexus-form-row control_id="name-input" row_label="Name">
                        <input kendoTextBox type="text" class="form-control" id="name-input" formControlName="name" />
                    </nexus-form-row>
                    <nexus-form-row control_id="type-input" row_label="Type">
                      <kendo-dropdownlist class="form-control" [data]="file_types | async" textField="name" valueField="value"
                        [defaultItem]="{ name: 'Select Type', id: null }" formControlName="file_type"
                        [valuePrimitive]="true">
                      </kendo-dropdownlist>
                    </nexus-form-row>
                    <nexus-form-row control_id="notes-input" row_label="Notes">
                        <textarea kendoTextArea class="form-control w-100" id="notes-input" formControlName="notes">
                        </textarea>
                    </nexus-form-row>
                    <nexus-form-row>
                        <button kendoButton icon="check" (click)="submit()" [primary]="true">Submit</button>
                    </nexus-form-row>
                </form>
                <nexus-form-result [model]="form_result"></nexus-form-result>
            </div>
        </div>
    </div>
</div>
<h3>Files</h3>
<nexus-grid [data]="property_files_model">
    <kendo-grid-column field="name" title="Name"></kendo-grid-column>
    <kendo-grid-column field="file_type" title="Type"></kendo-grid-column>
    <kendo-grid-column field="notes" title="Notes"></kendo-grid-column>
    <kendo-grid-column field="file.filename" title="File Name">
        <ng-template kendoGridCellTemplate let-dataItem>
            <nexus-file-link class="grid-link" [session_model]="session" [file_model]="dataItem.file"></nexus-file-link>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="date_created" title="Date Created" format="G" filter="date"></kendo-grid-column>
    <kendo-grid-column field="created_by_user.name" title="Created By"></kendo-grid-column>

    <kendo-grid-column [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem>
            <button kendoButton icon="pencil" [routerLink]="[dataItem.id]"></button>
            &nbsp;
            <button kendoButton icon="trash" (click)="deleteHandler(dataItem)"></button>
        </ng-template>
    </kendo-grid-column>
</nexus-grid>


<property-file-delete-modal (cancel)="cancelHandler()" [property_file_model]="delete_item"
    (delete)="confirmDeleteHandler($event)">

</property-file-delete-modal>
