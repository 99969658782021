<app-page-header title="Dashboard"></app-page-header>

<div class="row">
  <div class="col-12 col-md-4 col-lg-3">
    <div class="card mb-4" *ngIf="dashboard_model.current_invoice">
      <div class="card-header" [ngClass]="{'bg-danger':checkPastDue(dashboard_model.current_invoice)}">
        Current
        Invoice<span *ngIf="checkPastDue(dashboard_model.current_invoice)"> - PAST DUE</span>
      </div>
      <div class="card-body">
        <dl class="row">
          <dt class="col-sm-5">Invoice Number</dt>
          <dd class="col-sm-7">
            <a class="button-link"
               (click)="createPdf()">#{{ dashboard_model.current_invoice.invoice_number }}</a>
          </dd>
          <dt class="col-sm-5">Date Issued</dt>
          <dd class="col-sm-7">
            {{ dashboard_model.current_invoice.date_sent | amDateFormat:'M/D/YYYY' }}
          </dd>
          <dt class="col-sm-5">Billing Period</dt>
          <dd class="col-sm-7">
            {{ dashboard_model.current_invoice.billing_period_date | amDateFormat:'MMMM YYYY' }}
          </dd>
          <dt class="col-sm-5">Due Date</dt>
          <dd class="col-sm-7">
            {{ dashboard_model.current_invoice.date_due | amDateFormat:'M/D/YYYY' }}
          </dd>
          <dt class="col-sm-5">Invoice Amount</dt>
          <dd class="col-sm-7">{{ dashboard_model.current_invoice.item_amount | sbCurrency }}</dd>
          <dt class="col-sm-5">Invoice Balance</dt>
          <dd class="col-sm-7">
            {{ dashboard_model.current_invoice.balance | sbCurrency }}&nbsp;&nbsp;<span *ngIf="dashboard_model.current_invoice.settled"
                                                                                        class="badge badge-success">Settled</span><span *ngIf="!dashboard_model.current_invoice.settled" class="badge badge-danger">Unsettled</span>
          </dd>
        </dl>
      </div>
    </div>

    <div class="card mb-4" *ngFor="let outstanding_invoice of dashboard_model.outstanding_invoices">
      <div class="card-header" [ngClass]="{'bg-danger':checkPastDue(outstanding_invoice)}">
        {{ outstanding_invoice.billing_period_date | amDateFormat:'MMMM' }} Invoice <span *ngIf="checkPastDue(outstanding_invoice)"> - PAST DUE</span>
      </div>
      <div class="card-body">
        <dl class="row">
          <dt class="col-sm-5">Invoice Number</dt>
          <dd class="col-sm-7">#{{ outstanding_invoice.invoice_number }}</dd>
          <dt class="col-sm-5">Date Issued</dt>
          <dd class="col-sm-7">
            {{ outstanding_invoice.date_sent | amDateFormat:'M/D/YYYY' }}
          </dd>
          <dt class="col-sm-5">Billing Period</dt>
          <dd class="col-sm-7">
            {{ outstanding_invoice.billing_period_date | amDateFormat:'MMMM YYYY' }}
          </dd>
          <dt class="col-sm-5">Due Date</dt>
          <dd class="col-sm-7">
            {{ outstanding_invoice.date_due | amDateFormat:'M/D/YYYY' }}
          </dd>
          <dt class="col-sm-5">Invoice Amount</dt>
          <dd class="col-sm-7">{{ outstanding_invoice.item_amount | sbCurrency }}</dd>
          <dt class="col-sm-5">Invoice Balance</dt>
          <dd class="col-sm-7">
            {{ outstanding_invoice.balance | sbCurrency }}&nbsp;&nbsp;<span *ngIf="outstanding_invoice.settled" class="badge badge-success">Settled</span><span *ngIf="!outstanding_invoice.settled" class="badge badge-danger">Unsettled</span>
          </dd>
        </dl>
      </div>
    </div>

    <div class="card client-portal-card" *ngFor="let client_portal_card of dashboard_model.internal_property_team;first as isFirst">
      <div *ngIf="isFirst" class="card-header">Need Help?</div>
      <div class="card-body text-center">
        <p class="card-text">Your personal {{client_portal_card.position?.name}} is standing by.</p>
        <div *ngIf="client_portal_card.user?.image_file" class="profile-image-wrapper">
          <p>
            <img [src]="client_portal_card.user.image_file.url" class="img-fluid rounded-circle"
                 [alt]="client_portal_card.user.image_file.file_name" />
          </p>
        </div>
        <h6 class="card-subtitle">{{ client_portal_card.user.name }}</h6>
        <p class="card-text">
          <span>
            <a *ngIf="client_portal_card.user?.email" href="mailto:{{ client_portal_card.user?.email }}">{{ client_portal_card.user.email }}</a>
          </span><br />
          <span *ngIf="client_portal_card.user?.phone"> {{ client_portal_card.user.phone | phone }}</span>
           <span *ngIf="client_portal_card.user?.phone_extension">
            Ext.
            {{ client_portal_card.user.phone_extension }}
          </span>
        </p>
        <p>
          <button *ngIf="isBookingUrlAvailable(client_portal_card.user?.booking_url)" kendoButton (click)="sendUserModel(client_portal_card.user)" icon="calendar" [primary]="true">
            Schedule
            Meeting
          </button>
          <button *ngIf="!isBookingUrlAvailable(client_portal_card.user?.booking_url) && client_portal_card.user?.email" kendoButton type="button" [primary]="true">
            <fa-icon [icon]="faEnvelope" [fixedWidth]="true"></fa-icon>
            <a href="mailto:{{ client_portal_card.user?.email }}" style="color: #ffffff">Email {{client_portal_card.position?.name}}</a>
          </button>
        </p>
      </div>
    </div>

  </div>

    <div class="col-12 col-md-8 col-lg-9">
        <div class="row card-row">
            <div class="col-12 col-lg-6" *ngIf="dashboard_model.resident_progress">
                <div class="card">
                    <div class="card-header">Enrollment Status</div>
                    <div class="card-body">
                        <p><strong>{{ dashboard_model.resident_progress.percent_complete }}%</strong> of residents are enrolled at {{ session.current_property.name }}.</p>
                        <ngb-progressbar [value]="dashboard_model.resident_progress.percent_complete"
                            type="success"><span
                                *ngIf="dashboard_model.resident_progress.percent_complete > 5"><strong>{{dashboard_model.resident_progress.percent_complete}}%</strong></span>
                        </ngb-progressbar>
                    </div>
                </div>
            </div>

            <div class="col-12 col-lg-6" *ngIf="dashboard_model.billing_progress && session.current_property.market?.type != 'SingleFamily'">
                <div class="card">
                  <div class="card-header">Billing Status</div>
                  <div class="card-body">
                    <p>
                      <strong>{{ dashboard_model.billing_progress.percent_received }}%</strong> of bills have been
                      received for
                      {{ dashboard_model.billing_progress.billing_period_date | amDateFormat:'MMMM YYYY' }}.
                    </p>
                    <ngb-progressbar [value]="dashboard_model.billing_progress.percent_received"
                                 type="success">
                      <span *ngIf="dashboard_model.billing_progress.percent_received > 5"><strong>{{dashboard_model.billing_progress.percent_received}}%</strong></span>
                    </ngb-progressbar>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-12" *ngIf="dashboard_model.customer_service?.day_summaries.length > 0">
                <div class="card customer-service-card">
                  <div class="card-header">Customer Support</div>
                  <div class="card-body">
                    <div id="customerservice-chart" class="dashboard-chart"></div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6" *ngIf="dashboard_model.current_pre_bill">
                <div class="card pre-bill-card">
                  <div class="card-header">Pre Bill</div>
                  <div class="card-body">
                    <div *ngIf="!dashboard_model.current_pre_bill.completed_date">
                      <p>
                        You have an outstanding pre-bill to complete for
                        {{ dashboard_model.current_pre_bill.billing_period_date | amDateFormat:'MMMM YYYY' }}.
                      </p>
                      <p>
                        <button kendoButton icon="search"
                                [routerLink]="['/pre-bill', dashboard_model.current_pre_bill.id]">
                          Review
                          Now
                        </button>
                      </p>
                    </div>
                    <div *ngIf="dashboard_model.current_pre_bill.completed_date">
                      <p>
                        Pre-bill for
                        {{ dashboard_model.current_pre_bill.billing_period_date | amDateFormat:'MMMM YYYY' }}
                        was completed on
                        {{ dashboard_model.current_pre_bill.completed_date | amDateFormat:'M/D/YYYY' }}.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 col-lg-6" *ngIf="dashboard_model.workflows_count > 0">
                <div class="card workflow-card">
                  <div class="card-header">
                    Outstanding Task<span *ngIf="dashboard_model.workflows_count > 1">s</span>
                  </div>
                  <div class="card-body">
                    <p>
                      You have {{ dashboard_model.workflows_count }} outstanding task<span *ngIf="dashboard_model.workflows_count > 1">s</span> to complete.
                    </p>
                    <p>
                      <button kendoButton icon="play" [routerLink]="['/workflows','start']">
                        <span *ngIf="dashboard_model.workflows_count <= 1">Begin Task</span>
                        <span *ngIf="dashboard_model.workflows_count > 1">Begin Tasks</span>
                      </button>
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-12 col-lg-6" *ngIf="dashboard_model.pending_on_demand_efb_count > 0 && session.current_property.final_billing_approval">
                <div class="card workflow-card">
                  <div class="card-header">
                    Final Billing Approval
                  </div>
                  <div class="card-body">
                    <p>
                      You have {{ dashboard_model.pending_on_demand_efb_count }} outstanding EFB<span *ngIf="dashboard_model.pending_on_demand_efb_count > 1">s</span> to approve.
                    </p>
                    <p>
                      <button kendoButton icon="check" [routerLink]="['/on-demand-efbs']">
                        Review
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
    </div>

</div>
