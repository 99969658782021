import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerModel } from 'app/models/customer.model';
import { SmsMessageModel } from 'app/models/sms-message.model';

@Component({
  selector: 'app-customer-sms-messages',
  templateUrl: './customer-sms-messages.component.html',
  styleUrls: ['./customer-sms-messages.component.scss']
})
export class CustomerSmsMessagesComponent implements OnInit {

  public sms_messages_model: Array<SmsMessageModel>
  public customer_model: CustomerModel
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.parent.data.subscribe(({ customer_model }) => {
      this.customer_model = customer_model;
      
    });
    this.route.data.subscribe(({ sms_messages_model }) => {
      this.sms_messages_model = sms_messages_model;
    });
  }
}
