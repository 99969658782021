import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { faUserCircle, faHome, faExclamationCircle, faUser, faWrench, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { SessionModel } from 'app/models/session.model';
import { MembershipService } from 'app/core/services/membership.service';
import { EventService } from 'app/core/services/event.service';
import { TokenPropertyModel } from 'app/models/token-property.model';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']

})
export class AppHeaderComponent {
  public session: SessionModel = null;
  public is_nav_collapsed: boolean = true;
  public faUserCircle = faUserCircle;
  public faHome = faHome;
  public faExclamationCircle = faExclamationCircle;
  public faUser = faUser;
  public faWrench = faWrench;
  public faSignOutAlt= faSignOutAlt;
  public co_brand_img_url = null;
    constructor(public membership: MembershipService, private router: Router, private event_service: EventService) {
      this.event_service.on('session-start', (model) => {
        this.session = model;
        if(this.session)
          {
            this.set_co_brand();
          }
      });
      this.event_service.on('session-end', () => {
        this.session = null;
      });
    }

    ngOnInit() {
      this.membership.GetSession().subscribe(
        data => {
          this.session = data;
          if(this.session)
            {
              this.set_co_brand();
            }
        },
        error => {
          this.session = null;
        });
    }
    set_co_brand(){
       const token_property = this.membership.GetProperty();
       if(token_property?.file_white_logo_file?.url){
        this.co_brand_img_url = token_property.file_white_logo_file.url;
       } else{
        this.co_brand_img_url = null;
       }
    }

    toggle_nav_collapse() {
      this.is_nav_collapsed = !this.is_nav_collapsed;
    }

    sign_out() {
      this.membership.ClearToken();
        this.router.navigate(['/login']);
    }

    isAuthenticated() {
      return this.session && this.session.user_id;
    }

}
