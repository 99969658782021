<div class="row">
    <div class="col-lg-6">
        <nexus-card card_title="Unit Information">
            <nexus-card-body>
                <form [formGroup]="group_form" (ngSubmit)="submit($event)">
                    <nexus-form-row control_id="group-owner-input" row_label="Group Owner">
                        <input kendoTextBox class="form-control" id="group-owner-input"  [value]="group_model?.group_owner?.name" [disabled]="true" class="form-element"/>
                      </nexus-form-row>
                      <nexus-form-row control_id="floor-plan-input" row_label="Floor Plan">
                        <kendo-dropdownlist class="form-control"  textField="name" valueField="id"
                                            [defaultItem]="{ name:group_model?.floor_plan?.name , id: group_model?.floor_plan?.id }" formControlName="floor_plan_id"
                                            [valuePrimitive]="true" [filterable]="true" class="form-element"></kendo-dropdownlist>
                      </nexus-form-row>
                    <nexus-form-row control_id="address1-input" row_label="Address 1">
                        <input kendoTextBox class="form-control" id="address1-input" formControlName="address1"  class="form-element"/>
                    </nexus-form-row>
                    <nexus-form-row control_id="address2-input" row_label="Unit">
                        <input kendoTextBox class="form-control" id="address2-input" formControlName="address2" class="form-element" />
                    </nexus-form-row>
                    <nexus-form-row control_id="city-input" row_label="City">
                        <input kendoTextBox class="form-control" id="city-input" formControlName="city"  class="form-element"/>
                    </nexus-form-row>
                    <nexus-form-row control_id="region-input" row_label="State">
                        <kendo-dropdownlist class="form-control" [data]="regions_model" textField="name" valueField="id"
                            [defaultItem]="{ name: 'Select State', id: null }" formControlName="region_id"
                            [valuePrimitive]="true" class="form-element">
                        </kendo-dropdownlist>
                    </nexus-form-row>
                    <nexus-form-row control_id="zip5-input" row_label="Zip 5">
                        <kendo-maskedtextbox class="form-control" id="zip5-input" mask="00000" formControlName="zip5" class="form-element">
                        </kendo-maskedtextbox> 
                    </nexus-form-row>
                    <nexus-form-row control_id="zip4-input" row_label="Zip 4">
                        <kendo-maskedtextbox class="form-control" id="zip4-input" mask="0000" formControlName="zip4" class="form-element" >
                        </kendo-maskedtextbox>
                      </nexus-form-row>
                    <nexus-form-row row_label="Utilities Start Date" *ngIf="group_model.create_group_enabled && session.current_property.market.group_transitions_enabled">
                        <kendo-datepicker class="form-control" [format]="'MM/dd/yyyy'"
                            formControlName="transition_start_date" class="form-element"></kendo-datepicker>
                    </nexus-form-row>
                    <nexus-form-row row_label="Notes" *ngIf="group_model.create_group_enabled">
                        <nexus-editor formControlName="notes"></nexus-editor>
                    </nexus-form-row>
                    <nexus-form-row *ngIf="group_model.create_group_enabled && is_priority && create_group_model.priority_connection_fee">
                        Requesting utilities to be turned on earlier than {{ create_group_model.priority_date | amDateFormat:'M/D/YYYY' }} will result in an additional service
                        fee of {{ create_group_model.priority_connection_fee | currency }}
                    </nexus-form-row>
                    <nexus-form-row *ngIf="is_priority" control_id="priority" row_label="">
                        <kendo-switch id="priority" formControlName="priority"></kendo-switch> 
                        <label for="priority" class="ml-2" [class.priority-required]="group_form.controls.priority.invalid">I agree to pay the priority connection fee.</label>
                    </nexus-form-row>

                    <nexus-form-row *ngIf="group_model.create_group_enabled && !is_priority">
                        Please allow 3 business days for utility conversions to complete.
                    </nexus-form-row>
                </form>
                <nexus-form-row>
                    <button kendoButton [primary]="true" [icon]="'check'" type="button" [disabled]="!group_form.valid"
                        (click)="submit($event)" *ngIf="group_model.create_group_enabled" >Submit</button>
                </nexus-form-row>
                <nexus-form-result [model]="group_result"></nexus-form-result>

            </nexus-card-body>
        </nexus-card>
    </div>
    <div class="col-lg-6">

    </div>
</div>
