<app-page-header title="Reports"></app-page-header>

<ng-container *ngIf="!demo_environment && report_category_list.report_categorys">
    <ng-container *ngIf="report_category_list.report_categorys && report_category_list.report_categorys.length>0">
        <ng-container *ngFor="let report_category of report_category_list.report_categorys">
            <ng-container *ngIf="report_category.reports.length>0">
                <nav class="navbar navbar-light bg-light">
                    <span class="navbar-brand">{{report_category.name}}</span>
                </nav>

                <div class="row">
                    <div class="col-md-4 report-column" *ngFor="let report of report_category.reports">
                        <a class="card" [class.disabled]="demo_environment" [routerLink]="['/report',report.report_id]" (mouseout)="report.hovered=false"
                        (mouseover)="report.hovered=true" [class.border-primary]="report.hovered">
                            <div class="card-body">
                                <span *ngIf="report.hidden" class='report-hidden'>
                                    <fa-icon [icon]="faLowVision" [fixedWidth]="true"></fa-icon>
                                </span>
                                <h5 class="card-title">
                                    {{report.name}}
                                </h5>
                                <p class="card-text">{{report.description}}</p>
                            </div>
                        </a>
                    </div>
                </div>
            </ng-container>    
        </ng-container> 
    </ng-container> 
    <div class="row bottom-pad"></div>
</ng-container>