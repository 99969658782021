<app-page-header [title]="title"></app-page-header>


<div class="navbar navbar-light bg-light navbar-expand-lg mt-3">
  <button class="navbar-toggler" type="button" (click)="currentInvoiceCollapsed = !currentInvoiceCollapsed">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" [ngbCollapse]="currentInvoiceCollapsed">
    <ul class="navbar-nav mr-auto"></ul>
    <ul class="navbar-nav">
      <li class="nav-item" *ngIf="invoice_model.onesite_export_enabled">
        <button kendoButton
                icon="excel"
                (click)="oneSiteDefaultsExport(invoice_model)"
              >
                OneSite Defaults
              </button>
      </li>
      <li class="nav-item ml-2" *ngIf="invoice_model.defaults_export_enabled">
        <kendo-splitbutton
                [data]="entrata_button_list"
                icon="excel"
                (itemClick)="select_entrata_download_type($event,invoice_model)"
                (buttonClick)="download_entrata_files(invoice_model)"
              >
                Entrata
              </kendo-splitbutton>
      </li>
      <li class="nav-item ml-2 ">
        <kendo-splitbutton [data]="invoice_button_list" icon="download"
          (itemClick)="select_invoice_download_type($event)" (buttonClick)="download_invoice_files(invoice_model)">
          Export
        </kendo-splitbutton>
      </li>

    </ul>
  </div>
</div>

<div class="row card-row mt-3">
  <div class="col-lg-4">
    <div class="card">
      <div class="card-header">Details</div>
      <div class="card-body">
        <dl class="row">
          <dt class="col-sm-5">Invoice Number</dt>
          <dd class="col-sm-7">#{{ invoice_model.invoice_number }}</dd>
          <dt class="col-sm-5">Date Issued</dt>
          <dd class="col-sm-7">
            {{ invoice_model.date_sent | amDateFormat:'M/D/YYYY' }}
          </dd>
          <dt class="col-sm-5">Billing Period</dt>
          <dd class="col-sm-7">
            {{ invoice_model.billing_period_date | amDateFormat:'MMMM YYYY' }}
          </dd>
          <dt class="col-sm-5">Due Date</dt>
          <dd class="col-sm-7">
            {{ invoice_model.date_due | amDateFormat:'M/D/YYYY' }}</dd>
          <dt class="col-sm-5">Invoice Amount</dt>
          <dd class="col-sm-7">{{ invoice_model.item_amount | sbCurrency }}</dd>
          <dt class="col-sm-5">Invoice Balance</dt>
          <dd class="col-sm-7">{{ invoice_model.balance | sbCurrency }}&nbsp;&nbsp;<span *ngIf="invoice_model.settled"
              class="badge badge-success">Settled</span><span *ngIf="!invoice_model.settled"
              class="badge badge-danger">Unsettled</span>
          </dd>

        </dl>
      </div>
    </div>
  </div>

  <div class="col-lg-8">
    <div class="card">
      <div class="card-header">Transactions</div>
      <div class="card-body p-0">
        <nexus-grid [data]="invoice_model.transactions" [sort]="[{ 'field': 'created_date', 'dir': 'desc' }]">
          <kendo-grid-column field="created_date" title="Created" format="G" filter="date" [width]="130">
          </kendo-grid-column>
          <kendo-grid-column field="invoice_transaction_type.name" title="Transaction Type" [width]="100">
          </kendo-grid-column>
          <kendo-grid-column field="amount" title="Amount" filter="numeric" format="{0:c}" [width]="100">
          </kendo-grid-column>
          <kendo-grid-column field="reference" title="Reference" [width]="100"></kendo-grid-column>
          <kendo-grid-column field="voided_date" title="Voided" format="G" filter="date" [width]="140">
          </kendo-grid-column>
        </nexus-grid>
      </div>
    </div>
  </div>
</div>

<div class="row card-row mt-3">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-header">Invoice Details</div>
      <div class="card-body p-0">
        <nexus-grid [data]="invoice_model.items_summary" [sort]="[{ 'field': 'created_date', 'dir': 'desc' }]">

          <kendo-grid-column field="summary_account" [width]="180" title="Summary Account" [hidden]="true">
          </kendo-grid-column>

          <kendo-grid-column field="account" title="Account Number" [width]="130">
          </kendo-grid-column>
          <kendo-grid-column field="provider_name" title="Provider" [width]="100"></kendo-grid-column>

          <kendo-grid-column *ngIf="invoice_model.defaults_export_enabled" field="reference_id"
            title="Integration Unit Id" [width]="100">
          </kendo-grid-column>

          <kendo-grid-column field="address1" title="Address 1" [width]="100">
          </kendo-grid-column>
          <kendo-grid-column field="address2" title="Address 2" [width]="100">
          </kendo-grid-column>

          <kendo-grid-column field="ledger_code_name" title="Status" [width]="100">
          </kendo-grid-column>

          <kendo-grid-column field="property_ledger_code" title="GL Code" [width]="100">
          </kendo-grid-column>

          <kendo-grid-column *ngIf="single_family" field="group_owner_name" title="Unit Owner" [width]="100">
          </kendo-grid-column>

          <kendo-grid-column field="amount" title="Property Portion" filter="numeric" format="{0:c}" [width]="100">
          </kendo-grid-column>

          <kendo-grid-column field="start_date" title="Usage From" format="d" filter="date" [width]="130">
          </kendo-grid-column>

          <kendo-grid-column field="end_date" title="Usage To" format="d" filter="date" [width]="130">
          </kendo-grid-column>


          <kendo-grid-column field="customer_portion" title="Resident Portion" filter="numeric" format="{0:c}"
            [width]="100">
          </kendo-grid-column>

          <kendo-grid-column field="bill_amount" title="Bill Amount" filter="numeric" format="{0:c}" [width]="100">
          </kendo-grid-column>

          <kendo-grid-column field="reference" title="Reference" [width]="100">
          </kendo-grid-column>

          <kendo-grid-column [width]="80" title="Pdf">
            <ng-template kendoGridCellTemplate let-dataItem>
              <button kendoButton *ngIf="dataItem.provider_bill_file" [icon]="'pdf'"
                      (click)="downloadBillItem(dataItem)"></button>
            </ng-template>
          </kendo-grid-column>
        </nexus-grid>
      </div>
    </div>
  </div>
</div>


<div class="row card-row mt-3">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-header">Invoice Files</div>
      <div class="card-body p-0">
        <nexus-grid [data]="invoice_model.files">
          <kendo-grid-column field="date_created" title="Date Created" format="G" filter="date"></kendo-grid-column>
          <kendo-grid-column field="name" title="Name"></kendo-grid-column>
          <kendo-grid-column field="file.filename" title="File Name">
            <ng-template kendoGridCellTemplate let-dataItem>
              <nexus-file-link [file_model]="dataItem.file"></nexus-file-link>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="created_by_user.name" title="Created By"></kendo-grid-column>
        </nexus-grid>
      </div>
    </div>
  </div>
</div>
