<app-page-header title="Final Billing Approval"></app-page-header>
<div>This report will show all the On-Demand Estimated Final Bills for residents that are moving out, as well as any outstanding Actual Charges they might have.</div>
<div class="row card-row">
    <div [class.col-xl-7]="on_demand_efb" [class.col-xl-12]="!on_demand_efb" class="col-lg-12 mb-4">
        <nexus-grid #efbGrid [data]="on_demand_efbs" [rowClass]="rowClass" [selectable]="{mode: 'single'}" (selectionChange)="selectionChange(efbGrid, $event)">
            <kendo-grid-column field="customer_info.group.address1" title="Address" [width]="200"></kendo-grid-column>
            <kendo-grid-column field="customer_info.group.address2" title="Unit" [width]="100"></kendo-grid-column>
            <kendo-grid-column field="customer_info.customer.name" title="Resident" [width]="200"></kendo-grid-column>
            <kendo-grid-column field="usage_start" title="Usage From" format="d" filter="date" [width]="150"></kendo-grid-column>
            <kendo-grid-column field="usage_end" title="Usage To" format="d" filter="date" [width]="150"></kendo-grid-column>
            <kendo-grid-column field="customer_total" title="Total Estimation" format="c2" [width]="160" class="k-text-right" [sticky]="true">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span>{{dataItem.customer_total | sbCurrency}}</span>
                    <fa-icon *ngIf="flagLine(dataItem)" class="ml-1 flag" [icon]="flag"></fa-icon>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="customer_total_outstanding" title="Total Outstanding" format="c2" [width]="170" class="k-text-right" [sticky]="true">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span>{{dataItem.customer_total_outstanding | sbCurrency}}</span>
                    <fa-icon *ngIf="flagLine(dataItem)" class="ml-1 flag" [icon]="flag"></fa-icon>
                </ng-template>
            </kendo-grid-column>
        </nexus-grid>
    </div>
    <div class="col-xl-5 col-lg-12" *ngIf="on_demand_efb">
        <div class="card efb-charges-card">
            <div class="card-header">
                <div class="row align-items-center">
                    <div class="col-sm-1 align-items-center">
                        <div>
                            <button title="Previous" (click)="selectPrevious()" class="float-left mr-1 btn btn-outline-primary chevron-button">
                                <fa-icon [icon]="chevronLeft"></fa-icon>
                            </button>
                        </div>
                    </div>
                    <div class="col-sm-10 text-md-center">
                        <div><b>FINAL BILLING CHARGES</b></div>
                        <div>{{on_demand_efb.customer_info.group.address1}} {{on_demand_efb.customer_info.group.address2}}</div>
                    </div>
                    <div class="col-sm-1 align-items-center">
                        <button title="Next" (click)="selectNext()" class="float-right ml-1 btn btn-outline-primary chevron-button">
                            <fa-icon [icon]="chevronRight"></fa-icon>
                        </button>
                    </div>
                </div>
                <div class="mb-0 mt-1 ml-1 mr-1 text-center row">
                    <div class="col-sm-4">
                        <button type="button" class="col-sm-12 btn h-100 button-text-size" [class.btn-primary]="allowAllActions" [class.btn-outline-primary]="!allowAllActions" (click)="recordAllAction('Approve')" [disabled]="!allowAllActions || !session.current_property.final_billing_approval">Approve & Invoice All Charges</button>
                    </div>
                    <div class="col-sm-4">
                        <button type="button" class="col-sm-12 btn h-100 button-text-size" [class.btn-primary]="allowAllActions" [class.btn-outline-primary]="!allowAllActions" (click)="recordAllAction('Default')" [disabled]="!allowAllActions || !session.current_property.final_billing_approval">Default All to Next Property Invoice</button>
                    </div>
                    <div class="col-sm-4">
                        <button type="button" class="col-sm-12 btn h-100 button-text-size" [class.btn-danger]="allowAllActions" [class.btn-outline-danger]="!allowAllActions" *ngIf="!reject_clicked" (click)="rejectOnDemandEFB()" [disabled]="!allowAllActions || !session.current_property.final_billing_approval">Report Final Billing Issue</button>
                        <button type="button" class="col-sm-12 btn btn-outline-danger h-100 button-text-size" *ngIf="reject_clicked && allowAllActions" (click)="confirmRejectClicked()" [disabled]="!allowAllActions || !session.current_property.final_billing_approval">Report Final Billing Issue</button>
                    </div>
                </div>
            </div>

            <nexus-card-body *ngFor="let charge of on_demand_efb.charges">
                <div class="card">
                    <div class="card-header">
                        <span class="category-name">{{charge.provider_category.name}}</span>
                        <div class="float-right text-right">
                            <div>{{charge.expected_bill.provider.name}}</div>
                            <div class="small text-sm-right">{{charge.expected_bill.account_number}}</div>
                        </div>
                    </div>
                    <nexus-card-body>
                        <div *ngIf="containsActualCharges(charge)" class="alert actual-alert-banner alert-info text-center" role="alert">The Resident has Actual Charges that have <strong>not yet been invoiced</strong> or <strong>invoiced but not yet paid</strong>.<br/>Your approval action here will be applied to all Actual & Estimated Charges.</div>
                        <kendo-chart style="height:200px" [seriesColors]="chart_palette">
                            <kendo-chart-legend [visible]="false">
                            </kendo-chart-legend>
                            <kendo-chart-category-axis>
                                <kendo-chart-category-axis-item
                                    [categories]="charge.chart_categories">
                                </kendo-chart-category-axis-item>
                            </kendo-chart-category-axis>
                            <kendo-chart-value-axis>
                                <kendo-chart-value-axis-item
                                    [visible]="false"
                                    [crosshair]="{ visible: false }"
                                    [type]="'numeric'">
                                </kendo-chart-value-axis-item>
                            </kendo-chart-value-axis>
                            <kendo-chart-series>
                                <kendo-chart-series-item
                                    [data]="charge.py_chart_data"
                                    field="amount"
                                    categoryField="month_label"
                                    colorField="value_color"
                                    type="column"
                                    name="Previous"
                                    [stack] = "{group:'a'}">
                                    <kendo-chart-series-item-tooltip>
                                        <ng-template let-dataItem="dataItem">
                                            <div>{{ dataItem.month + '/' + dataItem.year}}</div>
                                            <div>{{ dataItem.amount | sbCurrency }}</div>
                                        </ng-template>
                                    </kendo-chart-series-item-tooltip>
                                </kendo-chart-series-item>

                                <kendo-chart-series-item
                                    [data]="charge.cy_chart_data_actual"
                                    field="amount"
                                    categoryField="month_label"
                                    colorField="value_color"
                                    type="column"
                                    name="Current"
                                    [stack] = "{group:'b'}">
                                    <kendo-chart-series-item-tooltip>
                                        <ng-template let-dataItem="dataItem">
                                            <div>{{ dataItem.month + '/' + dataItem.year}}</div>
                                            <div>{{ dataItem.amount | sbCurrency }}</div>
                                            <div>Actual</div>
                                        </ng-template>
                                    </kendo-chart-series-item-tooltip>
                                </kendo-chart-series-item>

                                <kendo-chart-series-item
                                    [data]="charge.cy_chart_data_actual_invoiced_unpaid"
                                    field="amount"
                                    categoryField="month_label"
                                    colorField="value_color"
                                    type="column"
                                    name="Current"
                                    [stack] = "{group:'b'}">
                                    <kendo-chart-series-item-tooltip>
                                        <ng-template let-dataItem="dataItem">
                                            <div>{{ dataItem.month + '/' + dataItem.year}}</div>
                                            <div>{{ dataItem.amount | sbCurrency }}</div>
                                            <div>Actual (Invoiced, Unpaid)</div>
                                        </ng-template>
                                    </kendo-chart-series-item-tooltip>
                                </kendo-chart-series-item>
                                <kendo-chart-series-item
                                    [data]="charge.cy_chart_data_actual_uninvoiced"
                                    field="amount"
                                    categoryField="month_label"
                                    colorField="value_color"
                                    type="column"
                                    name="Current"
                                    [stack] = "{group:'b'}">
                                    <kendo-chart-series-item-tooltip>
                                        <ng-template let-dataItem="dataItem">
                                            <div>{{ dataItem.month + '/' + dataItem.year}}</div>
                                            <div>{{ dataItem.amount | sbCurrency }}</div>
                                            <div>Actual (Uninvoiced)</div>
                                        </ng-template>
                                    </kendo-chart-series-item-tooltip>
                                </kendo-chart-series-item>
                                <kendo-chart-series-item
                                    [data]="charge.cy_chart_data_estimated"
                                    field="amount"
                                    categoryField="month_label"
                                    colorField="value_color"
                                    type="column"
                                    name="Current"
                                    [stack] = "{group:'b'}">
                                    <kendo-chart-series-item-tooltip>
                                        <ng-template let-dataItem="dataItem">
                                            <div>{{ dataItem.month + '/' + dataItem.year}}</div>
                                            <div>{{ dataItem.amount | sbCurrency }}</div>
                                            <div>Estimated</div>
                                        </ng-template>
                                    </kendo-chart-series-item-tooltip>
                                </kendo-chart-series-item>
                            </kendo-chart-series>


                        </kendo-chart>
                        <div *ngIf="charge.increase_warning" class="text-center increase-warning">The Estimated Charge is higher than previous periods because consumption is expected to increase due to weather.</div>
                        <hr/>
                        <div>
                            <div class="row font-italic text-sm">
                                <div class="col-sm-3 text-sm">Resident</div>
                                <div class="col-sm-4 text-sm-center">Type</div>
                                <div class="col-sm-3 text-sm-center">Usage</div>
                                <div class="col-sm-2 text-sm-right">Amount</div>
                            </div>
                            <div class="row align-items-center mt-1 pr-1" *ngFor="let portion of charge.portions">
                                <div class="col-sm-3 text-md">{{portion.name}}</div>
                                <div class="col-sm-4 text-md-center">
                                    <button kendoButton rounded="full" *ngIf="portion.estimated" class="custom-estimated-rounded k-icon-sm mb-2" style="pointer-events: none;">Estimated</button>
                                    <button kendoButton rounded="full" *ngIf="!(portion.estimated || portion.actual_invoiced_unpaid || portion.actual_uninvoiced)" class="custom-actual-rounded k-icon-sm mb-2" style="pointer-events: none;">Actual</button>
                                    <button kendoButton rounded="full" *ngIf="portion.actual_invoiced_unpaid" class="custom-invoiced-unpaid-rounded k-icon-sm mb-2" style="pointer-events: none;">Actual (Invoiced, Unpaid)</button>
                                    <button kendoButton rounded="full" *ngIf="portion.actual_uninvoiced" class="custom-uninvoiced-rounded k-icon-sm mb-2" style="pointer-events: none;">Actual (Uninvoiced)</button>
                                </div>
                                <div class="col-sm-3 text-md-center">{{portion.start_date | kendoDate:'d'}} - {{portion.end_date | kendoDate:'d'}}</div>
                                <div class="col-sm-2 text-md-right">
                                    <div class="row">
                                        <!-- <kendo-numerictextbox *ngIf="charge.approval_action == 'Pending'" class="form-control allowed-amount-input" [class.col-sm-6]="portion.approved_amount != portion.amount" [class.col-sm-12]="portion.approved_amount == portion.amount" id="allowed-amount-input" format="c" [(ngModel)]="portion.approved_amount"></kendo-numerictextbox> -->
                                        <div *ngIf="charge.approval_action == 'Pending'" [class.col-sm-6]="portion.approved_amount != portion.amount" [class.col-sm-12]="portion.approved_amount == portion.amount" >
                                            <div>{{portion.approved_amount | sbCurrency}}</div>
                                        </div>

                                        <div *ngIf="charge.approval_action != 'Pending'" [class.col-sm-6]="portion.approved_amount != portion.amount" [class.col-sm-12]="portion.approved_amount == portion.amount">
                                            <div>{{portion.approved_amount | sbCurrency}}</div>
                                        </div>
                                        <div *ngIf="portion.approved_amount != portion.amount" class="col-sm-6 text-md-right text-muted font-italic small">
                                            <div>Original Amount</div>
                                            <div>{{portion.amount | sbCurrency}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row mt-4">
                            <div class="col-sm-12 font-italic text-sm">Notes</div>
                            <div class="col-sm-12">
                                <kendo-textarea class="w-100" [readonly]="charge.approval_action != 'Pending'" [(ngModel)]="charge.approval_note"></kendo-textarea>
                            </div>
                        </div>   -->
                        <nexus-form-result [model]="charge.form_result" [addClass]="'mt-4 mb-1 text-md-center'"></nexus-form-result>
                    </nexus-card-body>
                </div>
            </nexus-card-body>
            <div class="text-center">
                <button type="button" class="col-sm-2 btn btn-outline-primary float-left ml-1 mb-1" (click)="selectPrevious()">Previous</button>
                {{nav_position}}
                <button type="button" class="col-sm-2 btn btn-outline-primary float-right mr-1 mb-1" (click)="selectNext()">Next</button>
            </div>
        </div>
    </div>
</div>

<kendo-dialog title="Report Final Billing Issue" *ngIf="reject_clicked" (close)="closeDialog()" [minWidth]="400" [width]="800">
    <div class="col-sm-12 text-md-center">
        <div>{{on_demand_efb.customer_info.group.address1}} {{on_demand_efb.customer_info.group.address2}}</div>
    </div>
    <div class="col-sm-12 font-italic text-sm">Please describe the issue...</div>
    <div class="col-sm-12">
        <kendo-textarea class="w-100" [(ngModel)]="report_note"></kendo-textarea>
    </div>
    <nexus-form-result [model]="report_form_result" [addClass]="'mt-4 mb-1 text-md-center'"></nexus-form-result>
    <kendo-dialog-actions>
        <button kendoButton (click)="closeDialog()">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="confirmRejectClicked()">Report</button>
    </kendo-dialog-actions>
  </kendo-dialog>
