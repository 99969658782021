import { Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import {  PreBillStepsModel } from 'app/models/pre-bill-step.model';
import { PreBillStepsService } from 'app/services/pre-bill-steps.service';

@Injectable()
export class PreBillStepsResolver implements Resolve<PreBillStepsModel> {

    constructor(private service: PreBillStepsService) 
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<PreBillStepsModel> {
        return this.service.GetSteps(route.params.pre_bill_id);
    }
}
