import { Component, NgZone } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {saveAs} from 'file-saver';
import { UtilityService } from '../../services/utility.service';

import { UtilitiesSummaryModel, UtilitiesServiceCategorySummary, UtilitiesBillsArgument, UtilitiesSummaryArgument, UtilitiesStatusSummary } from '../../models/utilities.model';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { ProviderModel } from 'app/models/provider.model';
import { ConfigService } from 'app/services/config.service';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { ProviderBillChargeModel } from 'app/models/provider-bill-charge.model';
import { ProviderBillService } from '../../services/provider-bill.service';
import { FileModel } from 'app/models/file.model';
import { UtilityDetailsModel } from 'app/models/utility-details.model';

interface Item {
  text: string,
  value: number
}

interface UtilitiesStatusSummaryGroup {
  provider_name: string;
  summaries: Array<UtilitiesStatusSummary>;
}

@Component({
  selector: 'app-utilities',
  templateUrl: './utilities.component.html',
  styleUrls: ['./utilities.component.scss']
})
export class UtilitiesComponent extends NexusComponent {
  public bills_argument: UtilitiesBillsArgument = new UtilitiesBillsArgument();
  public download_pdfs_argument: UtilitiesBillsArgument = new UtilitiesBillsArgument();
  public bills_model: Array<UtilityDetailsModel>;
  public bills_start_date: Date;
  public bills_end_date: Date;
  public bills_filter_collapsed = false;
  public bills_error: string;

  public summary_argument: UtilitiesSummaryArgument = new UtilitiesSummaryArgument();
  public summary_model: UtilitiesSummaryModel = new UtilitiesSummaryModel();
  public summary_filter_collapsed = false;
  public summary_error: string;

  public exportsCollapsed = false;
  public download_pdfs_success: string;
  public download_pdfs_error: string;

  public utilities_summary_data = [];
  public utilities_summary_chart: any;
  public utilities_legend_data = [];

  public providers: Array<ProviderModel>;

  public faLongArrowAltRight = faLongArrowAltRight;

  public viewInit: Boolean = false;

  public months: Array<Item> = [
    { text: "January", value: 1 },
    { text: "February", value: 2 },
    { text: "March", value: 3 },
    { text: "April", value: 4 },
    { text: "May", value: 5 },
    { text: "June", value: 6 },
    { text: "July", value: 7 },
    { text: "August", value: 8 },
    { text: "September", value: 9 },
    { text: "October", value: 10 },
    { text: "November", value: 11 },
    { text: "December", value: 12 }
  ];

  public bills_form = new UntypedFormGroup({
    start_month: this.CreateRequiredFormControl(),
    start_year: this.CreateRequiredFormControl(),
    end_month: this.CreateRequiredFormControl(),
    end_year: this.CreateRequiredFormControl()
  });

  public download_pdfs_form = new UntypedFormGroup({
    start_month: this.CreateRequiredFormControl(),
    start_year: this.CreateRequiredFormControl(),
    end_month: this.CreateRequiredFormControl(),
    end_year: this.CreateRequiredFormControl(),
    provider_id: this.CreateRequiredFormControl()
  });

  public summary_form = new UntypedFormGroup({
    billing_month: this.CreateRequiredFormControl(),
    billing_year: this.CreateRequiredFormControl()
  });

  constructor(private config: ConfigService, private utility_service: UtilityService, private zone: NgZone, private provider_bill_service: ProviderBillService) {
    super();
    this.event_service.on('property-changed', (model) => {
      this.setFormData();
      this.billsSubmit();
      this.summarySubmit();
      this.loadProviders();
    });
  }

  ngOnInit() {
    this.setFormData();
    this.StartSpinner('utility-summary');
    this.utility_service.GetUtilitiesSummary(this.summary_argument).subscribe(
      data => {
        this.summary_model = data;
        this.createCharts();
        this.StopSpinner('utility-summary');
      },
      err => {
        this.summary_error = err;
        this.StopSpinner('utility-summary');
      });

    this.StartSpinner('utility-bills');
    this.utility_service.GetUtilitiesBills(this.bills_argument).subscribe(
      data => {
        this.bills_model = data;
        this.bills_start_date = moment([this.bills_argument.start_year, this.bills_argument.start_month - 1, 1]).toDate();
        this.bills_end_date = moment([this.bills_argument.end_year, this.bills_argument.end_month - 1, 1]).toDate();
        this.StopSpinner('utility-bills');
      },
      err => {
        this.bills_error = err;
        this.StopSpinner('utility-bills');
      });

    this.loadProviders();
  }

  public parseSummaryMap(grp: Map<string, Array<UtilitiesStatusSummary>>): Array<UtilitiesStatusSummaryGroup> {
    let summaries = new Array<UtilitiesStatusSummaryGroup>();
    for (let key in grp) {
      summaries.push({ provider_name: key, summaries: grp[key] });
    }
    return summaries;
  }



  private setFormData() {
    this.download_pdfs_form.reset();
    this.summary_form.reset();
    this.bills_form.reset();

    this.summary_error = null;
    this.bills_error = null;
    this.download_pdfs_success = null;

    this.bills_argument.start_month = this.config.getCurrentMoment().month() + 1;
    this.bills_argument.start_year = this.config.getCurrentMoment().year();
    this.bills_argument.end_month = this.config.getCurrentMoment().month() + 1;
    this.bills_argument.end_year = this.config.getCurrentMoment().year();

    this.summary_argument.billing_month = this.config.getCurrentMoment().month() + 1;
    this.summary_argument.billing_year = this.config.getCurrentMoment().year();

    this.bills_form.patchValue(this.bills_argument);
    this.bills_form.valueChanges.subscribe((form: any) => {
      this.PopulateModelFromForm(form, this.bills_argument);
    });

    this.summary_form.patchValue(this.summary_argument);
    this.summary_form.valueChanges.subscribe((form: any) => {
      this.PopulateModelFromForm(form, this.summary_argument);
    });

    this.download_pdfs_form.patchValue(this.download_pdfs_argument);
    this.download_pdfs_form.valueChanges.subscribe((form: any) => {
      this.PopulateModelFromForm(form, this.download_pdfs_argument);
    });
  }

  ngAfterViewInit() {
    this.viewInit = true;
    this.createCharts();
  }


  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      if (this.utilities_summary_chart) {
        this.utilities_summary_chart.dispose();
      }
    });
  }

  loadProviders() {
    this.StartSpinner('providers');
    this.utility_service.GetProviders().subscribe(
      data => {
        this.providers = data;
        this.StopSpinner('providers');
      },
      err => {
        this.bills_error = err;
        this.StopSpinner('providers');
      });
  }

  billsSubmit() {

    this.bills_error = null;
    this.StartSpinner('utility-bills');

    this.utility_service.GetUtilitiesBills(this.bills_argument).subscribe(
      data => {
        this.bills_model = data;
        this.bills_start_date = moment([this.bills_argument.start_year, this.bills_argument.start_month - 1, 1]).toDate();
        this.bills_end_date = moment([this.bills_argument.end_year, this.bills_argument.end_month - 1, 1]).toDate();
        this.StopSpinner('utility-bills');
      },
      err => {
        this.bills_error = err;
        this.StopSpinner('utility-bills');
      });
  }

  summarySubmit() {
    this.summary_error = null;
    this.StartSpinner('utility-summary');

    this.utility_service.GetUtilitiesSummary(this.summary_argument).subscribe(
      data => {
        this.summary_model = data;
        this.zone.runOutsideAngular(() => {
          if (this.utilities_summary_chart) {
            this.utilities_summary_chart.dispose();
          }
        });
        this.createCharts();
        this.StopSpinner('utility-summary');
      },
      err => {
        this.summary_error = err;
        this.StopSpinner('utility-summary');
      });
  }

  exportBill(item: UtilityDetailsModel) {
    this.StartSpinner();
    if (item.source_type == "MeterConversion") {
      this.provider_bill_service.GetProviderBillPDF(item.provider_bill_id).subscribe(
        file_model => {
          saveAs(file_model.url, file_model.file_name);
          this.StopSpinner();
        },
        error => {
          this.StopSpinner();
        });
    }
    else {
      if(item.provider_bill_charge_file_id && item.provider_bill_charge_file?.file)
      {
        if (item.provider_bill_charge_file.file) {
          this.utility_service.DownloadExport(item.provider_bill_charge_file.file).subscribe(
            data => {
              saveAs(data, item.provider_bill_charge_file.file.file_name);
              this.StopSpinner();
            },
            err => {
              this.StopSpinner();
            });
        }
      }
      else
      {
        this.utility_service.CreateExport(item).subscribe(
          export_data => {
            if (export_data.file) {
              this.utility_service.DownloadExport(export_data.file).subscribe(
                data => {
                  saveAs(data, export_data.file.file_name);
                  this.StopSpinner();
                },
                err => {
                  this.StopSpinner();
                });
            }
            else {
              this.StopSpinner();
            }
          },
          err => {
            this.StopSpinner();
          });
        }
      }
  }

  downloadPDFs() {
    this.StartSpinner();
    this.download_pdfs_success = null;
    this.download_pdfs_error = null;

    this.utility_service.DownloadPDFs(this.download_pdfs_argument).subscribe(
      data => {
        this.StopSpinner();
        this.download_pdfs_success = "The download file is being compiled, you will receive a link in your e-mail shortly!";
      },
      err => {
        this.download_pdfs_error = err;
        this.StopSpinner();
      });
  }

  createCharts() {
    if (this.viewInit && this.summary_model && this.summary_model.overall_summaries) {

      this.utilities_summary_data = [];
      for (let summary of this.summary_model.overall_summaries) {
        this.utilities_summary_data.push({
          "service_category_name": summary.service_category_name,
          "amount": summary.amount,
          "color": "#" + summary.color
        });
      }
      this.zone.runOutsideAngular(() => {
        let chart = am4core.create("utilities-summary-chart", am4charts.PieChart);
        chart.data = this.utilities_summary_data;
        chart.innerRadius = am4core.percent(27);
        chart.radius = am4core.percent(90);
        chart.fontSize = 11;
        chart.fontFamily = "Verdana";

        let pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "amount";
        pieSeries.dataFields.category = "service_category_name";
        pieSeries.slices.template.propertyFields.fill = "color";
        pieSeries.slices.template.stroke = am4core.color("#ffffff");
        pieSeries.slices.template.strokeWidth = 2;
        pieSeries.slices.template.strokeOpacity = 1;
        pieSeries.labels.template.disabled = true;
        pieSeries.slices.template.states.getKey("active").properties.shiftRadius = 0;
        pieSeries.slices.template.tooltipHTML = "<center>{category}<br><b>${value.value}<b></center>";
        pieSeries.tooltip.fontSize = 11;
        pieSeries.fontFamily = "Verdana";
        pieSeries.tooltip.autoTextColor = false;
        pieSeries.tooltip.label.fill = am4core.color("#ffffff");
        var shadow = pieSeries.slices.template.filters.push(new am4core.DropShadowFilter);
        shadow.opacity = 0;
        var hoverState = pieSeries.slices.template.states.getKey("hover");
        var hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter);
        hoverShadow.opacity = 0.7;
        hoverShadow.blur = 5;
        this.utilities_summary_chart = chart;
      });
      /*this.utilities_summary_chart = this.am_charts.makeChart("utilities-summary-chart", {
          "type": "pie",
          "theme": "light",
          "addClassNames": true,
          "innerRadius": "30%",
          "radius": "45%",
          "balloonText": "[[title]]<br><b>$[[value]]</b>",
          "colorField": "color",
          "defs": {
              "filter": [{
                  "id": "shadow",
                  "width": "200%",
                  "height": "200%",
                  "feOffset": {
                      "result": "offOut",
                      "in": "SourceAlpha",
                      "dx": 0,
                      "dy": 0
                  },
                  "feGaussianBlur": {
                      "result": "blurOut",
                      "in": "offOut",
                      "stdDeviation": 5
                  },
                  "feBlend": {
                      "in": "SourceGraphic",
                      "in2": "blurOut",
                      "mode": "normal"
                  }
              }]
          },
          "labelsEnabled": false,
          "dataProvider": this.utilities_summary_data,
          "titleField": "title",
          "valueField": "value",
          "export": {
              "enabled": false
          },
          "responsive": {
              "enabled": true
          }

      });
      this.am_charts.addListener(this.utilities_summary_chart, "init", function () {
          this.am_charts.addListener(this.utilities_summary_chart.legend, "rollOverItem", function (e) {
              var wedge = e.dataItem.wedge.node;
              wedge.parentNode.appendChild(wedge);
          });
      });
      this.am_charts.addListener(this.utilities_summary_chart, "rollOverSlice", function (e) {
          var wedge = e.dataItem.wedge.node;
          wedge.parentNode.appendChild(wedge);
      });*/
    }
  }
}
