import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ReportEmbedModel, TelerikEmbedModel, TelerikReportParameter } from '../models/report-embed.model';
import { ConfigService } from './config.service';
import { EnvironmentType } from 'app/enums/environment-type.enum';

@Injectable()
export class ReportEmbedService {

    constructor(private http: HttpClient, private config: ConfigService) { }

    public GetEmbed(report_id: string): Observable<ReportEmbedModel> {
        // if(this.config.getEnvironmentType() == EnvironmentType.Demo)
        // {
        //     return throwError('Call not allowed for Demo environment!');
        // }
        return this.http.get<ReportEmbedModel>(this.config.getApiRoute('api/v3/client-portal/reports/embed/'+report_id)).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return throwError(err.message);
            }));
    }

}
