import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { InvoiceModel } from 'app/models/invoices.model';
import { InvoiceService } from 'app/services/invoice.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResidentInvoiceResolver implements Resolve<InvoiceModel> {
  constructor(private service: InvoiceService)
  {

  }

  resolve(route: ActivatedRouteSnapshot): Observable<InvoiceModel> {
      return this.service.GetInvoice(route.params.id);
  }
}
