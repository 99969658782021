import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NexusComponent } from '../nexus/nexus.component';
import { SuccessEvent, RemoveEvent, FileRestrictions, FileInfo } from '@progress/kendo-angular-upload';
import { FileModel } from 'app/models/file.model';
import { ConfigService } from 'app/services/config.service';

@Component({
  selector: 'nexus-form-file-input',
  templateUrl: './nexus-form-file-input.component.html',
  styleUrls: ['./nexus-form-file-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => NexusFormFileInputComponent),
    }
  ]
})
export class NexusFormFileInputComponent extends NexusComponent implements ControlValueAccessor {
  @Input() public multiple: boolean = false;
  @Input() public valuePrimitive: boolean = true;
  @Input() public restrictions: FileRestrictions = {
    allowedExtensions: ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'xlsm', 'xlsb', 'png', 'gif', 'jpg', 'jpeg', 'txt', 'csv', 'tiff', 'tif', 'mp3', 'mpeg', 'mpg', 'wav', 'ppt', 'pptm', 'pptx']
    };

  private staged_files: Array<FileModel> = new Array<FileModel>();
  private files: Array<FileModel> = new Array<FileModel>();
  public save_url: string;
  public remove_url: string;
  public is_disabled: boolean = false;
  public source_files: Array<FileInfo> = [];

  constructor(private config: ConfigService) {
    super();
    this.save_url = this.config.getApiRoute('api/v3/common/files');
    this.remove_url = this.config.getApiRoute('api/v3/common/files');
  }

  ngOnInit() {

  }

  private propagateChange = (value: any) => { };

  writeValue(obj: any): void {    
    this.staged_files = new Array<FileModel>();
    if (this.multiple && obj) {
      this.staged_files = obj;
    }
    else if (obj)
    {
      this.staged_files.push(obj);
    }
    this.source_files = [];
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {

  }

  setDisabledState?(isDisabled: boolean): void {
    this.is_disabled = isDisabled;
  }

  successEventHandler(e: SuccessEvent) {
    if (e.response.body) {
      let data: Array<FileModel> = e.response.body;

      if (!this.multiple) { 
        /* 
        DEVELOPER : Sylendra  DATE : 19 Feb 2021 
        Added this condition to clear the previous uploaded files.
        If user uploads a new file without deleting/clearing the previously uploaded one then to the API we are getting
        the zero index file. 
        */
        this.staged_files = [];
      }

      for (let file of data) {
        this.staged_files.push(file);
      }
     //propagate the files changes once the upload get finished
    }
  }

  removeEventHandler(e: RemoveEvent) {
    for (let file of e.files) {
      for (let i = 0; i < this.staged_files.length; i++) {
        if (file.name == this.staged_files[i].file_name) {
          e.data = { file_id: this.staged_files[i].id };
          /*
            DEVELOPER : Sylendra DATE : 19 Feb 2021
            If we haven't mention delete count for the splice method it will delete all the elements starting from
            the index passed to the splice method (splice(1))
          */
          // this.files.splice(i);
          this.staged_files.splice(i,1);
        }
      }
    }

    this.files = this.staged_files;
    if (this.multiple || this.staged_files.length == 0) {
      this.propagateChange(this.staged_files);
    }
    else if (this.staged_files.length > 0) {
      this.propagateChange(this.staged_files[0].id);
    }

  }

  /*
  DATE : 19 MAR 2021 DEVELOPER : SYLENDRA 
   To correct the validations while submitting the form
    1.Added the completeEventHandler and propagate the files changes in this method instead of doing this in success handler
    2.Added selectEventHandler . Clear the files in the selectEventHandler and propagate the files changes
  */
  completeEventHandler(e) {

    //Everytime we will fill the files here from the "files_copy" by clearing it in "selectEventHandler" and propagate the changes to the model
    this.files = this.staged_files;
    if (this.valuePrimitive) {
      if (this.multiple) {
        this.propagateChange(this.staged_files.map(p => p.id));
      }
      else if (this.staged_files.length > 0) {
        this.propagateChange(this.staged_files[0].id);
      }
    }
    else {
      if (this.multiple) {
        this.propagateChange(this.staged_files);
      }
      else if (this.staged_files.length > 0) {
        this.propagateChange(this.staged_files[0]);
      }
    }
  }
  selectEventHandler(e) {
    //Clear the files here and set "files" from the "files_copy" in the "completeEventHandler" and "removeEventHandler"
    this.files = [];
    /*
      Propagate the updated files to the model otherwise 
      it will not submit the form to API while uploading in progress in some of the cases (When user tries to "Select files" multiple times)
    */
    this.propagateChange(this.files);
  }

}
