import { ReportCategoryListModel } from './../models/reports.model';
import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { ReportService } from 'app/services/report.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsResolver implements Resolve<ReportCategoryListModel> {
  constructor(private service: ReportService)
  {

  }

  resolve(route: ActivatedRouteSnapshot): Observable<ReportCategoryListModel> {
      return this.service.GetReports();
  }
}
