import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable, of, from } from 'rxjs';
import { catchError, map, share, switchMap } from 'rxjs/operators';
import { LoginModel } from 'app/models/login.model';
import { TokenModel } from 'app/models/token.model';
import { SessionModel } from 'app/models/session.model';
import { ForgotPasswordModel } from 'app/models/forgot-password.model';
import { ResetPasswordModel } from 'app/models/reset-password.model';
import { EventService } from './event.service';
import { TokenPropertyModel } from 'app/models/token-property.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { ConfigService } from 'app/services/config.service';
import * as Sentry from "@sentry/browser";

const jwtHelper = new JwtHelperService();

@Injectable()
export class MembershipService {
    public redirectUrl: string = null;

    constructor(private http: HttpClient, private router: Router, private event_service: EventService, private config: ConfigService) {
        this.load_user();
    }

    private load_user() {
        this.GetToken().subscribe((token) => {
                if (!token) {
                return;
            }
            let jwt = jwtHelper.decodeToken(token.token);
            if (jwt) {
                Sentry.configureScope(function(scope) {
                    scope.setUser({"email": jwt["email"]});
                  });
            }
        });
    }


    public Login(argument: LoginModel): Observable<TokenModel> {
        return this.http.post<TokenModel>(this.config.getApiRoute('api/v3/session/login'), argument).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return throwError(err.error.message);
            }),
            share());

    }

    public ForgotPassword(argument: ForgotPasswordModel): Observable<{}> {
        return this.http.post(this.config.getApiRoute('api/v3/session/forgot-password'), argument).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return throwError(err.error.message);
            }),
            share());

    }

    public ResetPassword(argument: ResetPasswordModel): Observable<{}> {
        return this.http.post(this.config.getApiRoute('api/v3/session/reset-password'), argument).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return throwError(err.error.message);
            }),
            share());

    }

    public SetProperty(property: TokenPropertyModel) {
        if (localStorage.getItem('token') && localStorage.getItem('token').startsWith('{')) {
            let token = JSON.parse(localStorage.getItem('token'));
            token.current_property = property;
            this.SetToken(token);
        }
    }
    public GetProperty(): TokenPropertyModel {
        var tpm: TokenPropertyModel;
        
        if (localStorage.getItem('token') && localStorage.getItem('token').startsWith('{')) {
            let token = JSON.parse(localStorage.getItem('token'));
            tpm = token.current_property;
        }

        return tpm;
    }

    public SetToken(token: TokenModel) {
        localStorage.setItem('token', JSON.stringify(token));
        // this.load_user();
        this.event_service.sessionStart(new SessionModel(token));
    }

    public ClearToken() {
        localStorage.removeItem('token');
        this.load_user();
        this.event_service.sessionEnd();
    }

    public GetToken(): Observable<TokenModel> {
        if (localStorage.getItem('token') && localStorage.getItem('token').startsWith('{')) {
            let model: TokenModel = JSON.parse(localStorage.getItem('token'));

            if (jwtHelper.isTokenExpired(model.token)) {
                this.ClearToken();
                this.redirectUrl = this.router.url;
                this.router.navigate(['/login']);
            }
            return of(model);
        }
        else {
            return of(null);
        }
    }

    public OverrideUtilitiesLinked() {
        if (localStorage.getItem('token')) {
            let token = JSON.parse(localStorage.getItem('token'));
            token.utilities_linked = true;
            localStorage.setItem('token', JSON.stringify(token));
        }
    }

    public OverrideGoodStanding() {
        if (localStorage.getItem('token')) {
            let token = JSON.parse(localStorage.getItem('token'));
            token.good_standing = true;
            localStorage.setItem('token', JSON.stringify(token));
        }
    }

    public OverrideEnrollmentComplete() {
        if (localStorage.getItem('token')) {
            let token = JSON.parse(localStorage.getItem('token'));
            token.enrollment_complete = true;
            localStorage.setItem('token', JSON.stringify(token));
        }
    }

    public GetSession(): Observable<SessionModel> {
        return from(this.GetToken()).pipe(
            switchMap((token: TokenModel) => {
                let session: SessionModel = null;
                if (token && token.token) {
                    session = new SessionModel(token);
                }
                return of(session);
            }),
            catchError(error => {
                return of(null);
            }),
            share()
        );
    }
    public IsSuperAdmin(): Observable<boolean> {
        return this.get_session().pipe(
          map((session: SessionModel) => {
            if (session.roles.includes('SuperAdministrator')) {
              return true;
            }
            return false;
          }),
          catchError((err) => {
            return of(false);
          }),
          share()
        );
      }
      
  
      public IsAdmin(): Observable<boolean> {
        return this.get_session().pipe(
          map((session: SessionModel) => {
            if (session.roles.includes('Administrator')) {
              return true;
            }
            return false;
          }),
          catchError((err) => {
            return of(false);
          }),
          share()
        );
      }
  
      private get_session(): Observable<SessionModel> {
        return from(this.GetToken()).pipe(
          switchMap((token: TokenModel) => {
            let session: SessionModel = null;
            if (token && token.token) {
              session = new SessionModel(token);
            }
            return of(session);
          }),
          catchError(error => {
            return of(null);
          }),
          share()
        );
      }
  
}
