
import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ConfigService } from 'app/services/config.service';
import { CreateGroupGetModel } from '../models/create-group.model';
import { GroupModel } from '../models/group.model';
import { GroupOwnerModel } from '../models/group-owner.model';
import { GroupsModel } from '../models/groups.model';
import { CustomerGroupModel } from 'app/models/customer-group.model';
import { ExpectedBillModel } from 'app/models/expected-bill.model';
import { ProviderBillPortionModel } from 'app/models/provider-bill-portion.model';
import { GroupIntegrationsModel } from 'app/models/group-integrations.model';


@Injectable()
export class GroupService {

    constructor(private http: HttpClient, private config: ConfigService) { }


    public GetCreateGroup(): Observable<CreateGroupGetModel> {

        return this.http.get<CreateGroupGetModel>(this.config.getApiRoute('api/v3/client-portal/groups/create-group')).pipe(
            map(data => { 
                return data;
            }),
            catchError((err: HttpErrorResponse)  => {
                return throwError(err.error.message);
            }));
    }

    public SaveGroup(argument: GroupModel): Observable<GroupModel> {
        if (argument.id) {
        return this.http.put<GroupModel>(this.config.getApiRoute('api/v3/client-portal/groups/' + argument.id), argument).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return throwError(err.error.message);
            }));
        }
        else{
            return this.http.put<GroupModel>(this.config.getApiRoute('api/v3/client-portal/groups'), argument).pipe(
                map(data => {
                    return data;
                }),
                catchError((err: HttpErrorResponse) => {
                    return throwError(err.error.message);
                }));
            }
    }

    public GetGroupOwners(): Observable<Array<GroupOwnerModel>> {

        return this.http.get<Array<GroupOwnerModel>>(this.config.getApiRoute('api/v3/client-portal/group-owners')).pipe(
            map(data => { 
                return data;
            }),
            catchError((err: HttpErrorResponse)  => {
                return throwError(err.error.message);
            }));
    }

    public GetGroup(group_id: string): Observable<GroupModel> {

        return this.http.get<GroupModel>(this.config.getApiRoute('api/v3/client-portal/groups/'+ group_id)).pipe(
            map(data => { 
                return data;
            }),
            catchError((err: HttpErrorResponse)  => {
                return throwError(err.error.message);
            }));
    }

    public GetGroups(): Observable<GroupsModel> {

        return this.http.get<GroupsModel>(this.config.getApiRoute('api/v3/client-portal/groups')).pipe(
            map(data => { 
                return data;
            }),
            catchError((err: HttpErrorResponse)  => {
                return throwError(err.error.message);
            }));
    }

    public GetGroupCustomers(group_id: string): Observable<Array<CustomerGroupModel>> {
        return this.http.get<Array<CustomerGroupModel>>(this.config.getApiRoute('api/v3/client-portal/groups/' + group_id +'/customers')).pipe(
          map(data => {
            return data;
          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(err.error.message);
          }));
      }

      public GetGroupExpectedBills(group_id: string): Observable<Array<ExpectedBillModel>> {
        return this.http.get<Array<ExpectedBillModel>>(this.config.getApiRoute('api/v3/client-portal/groups/' + group_id + '/expected-bills')).pipe(
          map(data => {
            return data;
          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(err.error.message);
          }));
      }
      
      public GetGroupFutureBilling(group_id: string): Observable<Array<ProviderBillPortionModel>> {
        return this.http.get<Array<ProviderBillPortionModel>>(this.config.getApiRoute('api/v3/client-portal/groups/' + group_id + '/future-billing')).pipe(
          map(data => {
            return data;
          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(err.error.message);
          }));
      }

      public GetGroupIntegrations(group_id: string): Observable<GroupIntegrationsModel> {
        return this.http.get<GroupIntegrationsModel>(this.config.getApiRoute('api/v3/client-portal/groups/' + group_id +'/integrations')).pipe(
          map(data => {
            return data;
          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(err.error.message);
          }));
      }

}
