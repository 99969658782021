import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ConfigService } from '../../services/config.service';
import { GroupOwnerModel } from '../../models/group-owner.model';
import { SearchModel } from 'app/models/search.model';

@Injectable()
export class GroupOwnerService {

    constructor(private http: HttpClient, private config: ConfigService) { }

    public SearchGroupOwners(argument: SearchModel): Observable<Array<GroupOwnerModel>> {
        return this.http.post<Array<GroupOwnerModel>>(this.config.getApiRoute('api/v3/client-portal/group-owners'), argument).pipe(
          map(data => {
            return data;
          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(err.error.message);
        }));
    }   
}
