<app-page-header [title]="currentTitle"></app-page-header>

<ul class="nav nav-pills mb-3">
    <li class="nav-item">
        <a class="nav-link" [routerLink]="['expected-bills']" routerLinkActive="active">Expected Bills</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" [routerLink]="['ledger-codes']" routerLinkActive="active">Ledger Codes</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" [routerLink]="['floor-plans']" routerLinkActive="active">Floor Plans</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" [routerLink]="['user-access']" routerLinkActive="active">User Access</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" [routerLink]="['providers']" routerLinkActive="active">Providers</a>
    </li>
</ul>

<router-outlet></router-outlet>
