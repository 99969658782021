
import { throwError as observableThrowError, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { InvoicesModel, InvoiceModel } from 'app/models/invoices.model';
import { FileModel } from 'app/models/file.model';
import { ConfigService } from './config.service';
import { InvoiceTypeArgumentModel } from 'app/models/invoice-type-argument.model';
import { InvoiceItemSummary } from '../models/invoice-item-summary.model';

@Injectable()
export class InvoiceService {

    constructor(private http: HttpClient, private config: ConfigService) { }

    public GetInvoices(): Observable<InvoicesModel> {
        let model = new InvoiceTypeArgumentModel();
        model.current_date = this.config.getCurrentMoment().startOf('day').toDate();
        model.invoice_type = "property";
        return this.http.post<InvoicesModel>(this.config.getApiRoute('api/v3/client-portal/invoices'), model).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return observableThrowError(err.error.message);
            }));
    }
    public GetResidentInvoices(): Observable<InvoicesModel> {
        let model = new InvoiceTypeArgumentModel();
        model.current_date = this.config.getCurrentMoment().startOf('day').toDate();
        model.invoice_type = "resident";
        return this.http.post<InvoicesModel>(this.config.getApiRoute('api/v3/client-portal/invoices'), model).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return observableThrowError(err.error.message);
            }));
    }
    public GetGroupInvoices(group_id: string): Observable<InvoicesModel> {
        let model = new InvoiceTypeArgumentModel();
        model.current_date = this.config.getCurrentMoment().startOf('day').toDate();
        model.invoice_type = "group";
        model.group_id=group_id;
        return this.http.post<InvoicesModel>(this.config.getApiRoute('api/v3/client-portal/invoices'), model).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return observableThrowError(err.error.message);
            }));
    }
    public SearchResidentInvoices(model:InvoiceTypeArgumentModel): Observable<InvoicesModel> {
        return this.http.post<InvoicesModel>(this.config.getApiRoute('api/v3/client-portal/invoices'), model).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return observableThrowError(err.error.message);
            }));
    }


    public GetInvoice(id:string): Observable<InvoiceModel> {
      let model = new InvoiceTypeArgumentModel();
      model.current_date = this.config.getCurrentMoment().startOf('day').toDate();

      return this.http.get<InvoiceModel>(this.config.getApiRoute('api/v3/client-portal/invoices/'+id)).pipe(
          map(data => {
              return data;
          }),
          catchError((err: HttpErrorResponse) => {
              return observableThrowError(err.error.message);
          }));
  }

    public CreatePdf(id: string): Observable<FileModel> {
        return this.http.get<FileModel>(this.config.getApiRoute('api/v3/client-portal/invoices/' + id + '/export-pdf')).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return observableThrowError(err.error.message);
            }));
    }

    public CreateExcel(id: string): Observable<FileModel> {
        return this.http.get<FileModel>(this.config.getApiRoute('api/v3/client-portal/invoices/' + id + '/export-excel')).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return observableThrowError(err.error.message);
            }));
    }

    public CreateExcelReport(id: string): Observable<FileModel> {
        return this.http.get<FileModel>(this.config.getApiRoute('api/v3/client-portal/invoices/' + id + '/export-excel-report')).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return observableThrowError(err.error.message);
            }));
    }

    public CreateDefaults(id: string): Observable<FileModel> {
        return this.http.get<FileModel>(this.config.getApiRoute('api/v3/client-portal/invoices/' + id + '/export-defaults')).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return observableThrowError(err.error.message);
            }));
    }
    public CreateJournals(id: string): Observable<FileModel> {
        return this.http.get<FileModel>(this.config.getApiRoute('api/v3/client-portal/invoices/' + id + '/export-journals')).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return observableThrowError(err.error.message);
            }));
    }
    public CreateInvoices(id: string): Observable<FileModel> {
        return this.http.get<FileModel>(this.config.getApiRoute('api/v3/client-portal/invoices/' + id + '/export-invoices')).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return observableThrowError(err.error.message);
            }));
    }
    public CreateEntrataFiles(id: string): Observable<FileModel> {
        return this.http.get<FileModel>(this.config.getApiRoute('api/v3/client-portal/invoices/' + id + '/export-entrata-files')).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return observableThrowError(err.error.message);
            }));
    }

    public RequestFiles(invoice_id: string): Observable<FileModel> {
        return this.http.put<FileModel>(this.config.getApiRoute('api/v3/client-portal/invoices/' + invoice_id + '/request-files'), invoice_id).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return observableThrowError(err.error.message);
            }));
    }

    public RequestFile(invoice_item_id: string): Observable<FileModel> {
        return this.http.put<FileModel>(this.config.getApiRoute('api/v3/client-portal/invoices/' + invoice_item_id + '/request-file'), invoice_item_id).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return observableThrowError(err.error.message);
            }));
    }

    public DownloadPDFs(invoice_id: string,download_zip:boolean): Observable<FileModel> {
        return this.http.put<FileModel>(this.config.getApiRoute('api/v3/client-portal/invoices/' + invoice_id + '/export-files/'+download_zip), invoice_id).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return observableThrowError(err.error.message);
            }));
    }

  public DownloadPDF(invoice_item_id: string): Observable<FileModel> {
    return this.http.put<FileModel>(this.config.getApiRoute('api/v3/client-portal/invoices/' + invoice_item_id + '/export-file'), invoice_item_id).pipe(
      map(data => {
        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return observableThrowError(err.error.message);
      }));
  }

    public CreateInvoiceFiles(id: string): Observable<FileModel> {
        return this.http.get<FileModel>(this.config.getApiRoute('api/v3/client-portal/invoices/' + id + '/export-invoice-files')).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return observableThrowError(err.error.message);
            }));
    }

    public DownloadExport(export_model: FileModel): Observable<Blob> {
      let headers = new HttpHeaders();

      if (export_model.content_type) {
          headers.set('Accept', export_model.content_type);
      }

      return this.http.get(export_model.url, { responseType: 'blob', headers: headers }).pipe(
          map(data => {
              return new Blob([data], { type: export_model.content_type })
          }),
          catchError((err: HttpErrorResponse) => {
              return throwError(err.error.message);
          }));

  }

  public GetOneSiteDefaults(invoice_id: string): Observable<FileModel> {
    return this.http.get<FileModel>(this.config.getApiRoute('api/v3/client-portal/invoices/' + invoice_id + '/export-onesite-defaults')).pipe(
      map(data => {
        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err.error.message);
      }));
  }
}
