import { SessionModel } from './../../../models/session.model';
import { Component, Input, OnInit } from '@angular/core';
import { FilesService } from 'app/core/services/files.service';
import { FileModel } from 'app/models/file.model';

@Component({
  selector: 'nexus-file-link',
  templateUrl: './nexus-file-link.component.html',
  styleUrls: ['./nexus-file-link.component.scss']
})
export class NexusFileLinkComponent implements OnInit {

  @Input() public file_model: FileModel;
  @Input() public session_model: SessionModel;

  constructor(private file_service:FilesService) { }

  ngOnInit() {
  }

  openFile(event) {
    event.preventDefault();

    const a: HTMLAnchorElement = document.createElement("a") as HTMLAnchorElement;
    a.href = this.file_service.GetPublicFilePath(this.file_model.id,this.session_model?.user_id);
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();

    setTimeout(function() {
      document.body.removeChild(a);
    }, 100);

    return false; //stops the link's navigation...
  }

}
