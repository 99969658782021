<header>
  <div [class.container-fluid]="isAuthenticated()" [class.container]="!isAuthenticated()">
    <nav class="navbar navbar-expand-lg navbar-dark">
      <a class="navbar-brand" [routerLink]="['/']" [class.logged-out]="!isAuthenticated()">Navbar</a>
      <div class="image-container thumbnail-preview" *ngIf="co_brand_img_url">
          <img [src]="co_brand_img_url"/>
      </div>
      <div class="navbar-splitter"></div>
      <button class="navbar-toggler" type="button" (click)="toggle_nav_collapse()" aria-controls="main-nav"
        aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="main-nav" [class.collapse]="is_nav_collapsed"
        [class.show]="!is_nav_collapsed" (click)="toggle_nav_collapse()">
        <ul class="navbar-nav mr-auto" *ngIf="!isAuthenticated()">
          <li class="nav-item">
            <a class="nav-link" href="https://www.simplebills.com/residents">
              <fa-icon [icon]="faUserCircle" [fixedWidth]="true"></fa-icon> For Residents
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://www.simplebills.com/properties">
              <fa-icon [icon]="faHome" [fixedWidth]="true"></fa-icon> For Properties
            </a>
          </li>
        </ul>

        <ul class="navbar-nav mr-auto" *ngIf="isAuthenticated()">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/dashboard']">Dashboard</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/residents']">Residents</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/groups']">Units</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/utilities']">Utility Bills</a>
          </li>
          <li class="nav-item dropdown" ngbDropdown>
            <a class="nav-link dropdown-toggle" role="button" ngbDropdownToggle aria-haspopup="true" aria-expanded="false">
              Invoices
            </a>
            <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="properties-sub-nav-dropdown">
              <a class="dropdown-item" [routerLink]="['/property-invoices']">Property Invoices</a>
              <a class="dropdown-item" [routerLink]="['/resident-invoices']">Resident Invoices</a>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/reports']">Reports</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/move-in']">Move-Ins</a>
          </li>
          <li class="nav-item" *ngIf="session.current_property.workflow_admin">
            <a class="nav-link" [routerLink]="['/workflows']">Workflows</a>
          </li>
          <li class="nav-item" *ngIf="session.current_property.file_admin">
            <a class="nav-link" [routerLink]="['/files']">Files</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/resources']">Resources</a>
          </li>
        </ul>

        <ul class="navbar-nav" *ngIf="!isAuthenticated()">
          <li class="nav-item">
            <button class="btn btn-primary" [routerLink]="['/login']">Sign In</button>
          </li>
        </ul>
        <ul class="navbar-nav" *ngIf="isAuthenticated()">
          <li class="nav-item d-lg-none d-xl-inline">
            <a class="nav-link">
              <fa-icon [icon]="faUser" [fixedWidth]="true"></fa-icon> {{ session.first_name }}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="" [routerLink]="['/settings']">
              <fa-icon [icon]="faWrench" [fixedWidth]="true"></fa-icon>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link button-link" (click)="sign_out()">
              <fa-icon [icon]="faSignOutAlt" [fixedWidth]="true"></fa-icon> Sign Out
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</header>
