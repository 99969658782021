import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-settings',
    templateUrl: './setting-parent.component.html',
    styleUrls: ['./setting-parent.component.scss']
})
export class SettingsParentComponent implements OnInit {
    public currentTitle: string = "Profile";

    constructor(private route: ActivatedRoute) {}

    ngOnInit() {
    }

    ngDoCheck() {
        this.route.firstChild.data.subscribe(({ title }) => {
            this.currentTitle = title;
        });
      }
}