import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { CustomerGroupModel } from 'app/models/customer-group.model';
import { GroupService } from 'app/services/group.service';
import { Observable } from 'rxjs';

@Injectable()
export class GroupCustomersResolver implements Resolve<Array<CustomerGroupModel>> {

    constructor(private service: GroupService, private router: Router) 
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<Array<CustomerGroupModel>> {
        return this.service.GetGroupCustomers(route.parent.params.group_id);                
    }
}
