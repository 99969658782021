import { GroupModel } from "./group.model";
import { PropertyModel } from "./property.model";
import { ProviderModel } from "./provider.model";
import { LedgerCodeModel } from "./ledger-code.model";

export class ExpectedBillModel
{
    public id: string;
    public created_date: Date;
    public provider_id: string;
    public provider: ProviderModel;
    public account_number: string;
    public account_holder: string;
    public meter_number: string;
    public description: string;
    public ledger_code_id: string;
    public ledger_code: LedgerCodeModel;
    public group_id: string;
    public group: GroupModel;
    public property_id: string;
    public property: PropertyModel;
    public snooze_date: Date;
    public utilities_start_date: Date;
    public onboarding_active: boolean;
    public summary_account_number: string;
}