import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { GroupIntegrationsModel } from 'app/models/group-integrations.model';
import { GroupModel } from 'app/models/group.model';

@Component({
  selector: 'app-group-integrations',
  templateUrl: './group-integrations.component.html',
  styleUrls: ['./group-integrations.component.scss']
})
export class GroupIntegrationsComponent extends NexusComponent {

  public group_model: GroupModel;
  public group_integrations_model : GroupIntegrationsModel;

  constructor(protected route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.route.parent.data.subscribe(({ group_model  }) => {
      this.group_model = group_model;
    });

    this.route.data.subscribe(({ group_integrations_model }) => {
       this.group_integrations_model = group_integrations_model;
    });
  }
}
