import { PropertyModel } from "./property.model";
import { RegionModel } from "./region.model";
import { FloorPlanModel } from "./floor-plan.model";
import { GroupOwnerModel } from "./group-owner.model";
import { ExpectedBillModel } from "./expected-bill.model";
import { GroupTransitionModel } from "./group-transition.model";
import { CustomerGroupModel } from "./customer-group.model";

export class GroupModel {
    public id: string;
    public date_created: Date;
    public property_id: string;
    public property: PropertyModel;
    public floor_plan_id: string;
    public floor_plan: FloorPlanModel;
    public address1: string;
    public address2: string;
    public city: string;
    public region_id: string;
    public region: RegionModel;
    public zip5: string;
    public zip4: string;
    public override_address: boolean;
    public active: boolean;
    public transition_start_date: Date;
    public priority: boolean;
    public group_owner_id: string;
    public group_owner: GroupOwnerModel;
    public current_transition: GroupTransitionModel;
    public name: string;
    public notes: string;  

    public expected_bills: Array<ExpectedBillModel>;
    public customers: Array<CustomerGroupModel>;

    public group_integration_enabled: boolean;
    public create_group_enabled: boolean;
}