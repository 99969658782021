import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { GroupService } from '../services/group.service';
import { GroupModel } from '../models/group.model';

@Injectable()
export class GroupResolver implements Resolve<GroupModel> {

    constructor(private service: GroupService) 
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<GroupModel> {
        return this.service.GetGroup(route.params.group_id);
    }
}
