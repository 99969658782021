import { Component, OnInit, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { ConfigService } from './services/config.service';
import { EnvironmentType } from './enums/environment-type.enum';

@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']

})
export class AppComponent implements OnInit {
  public routeData: any;

  constructor(private router: Router, private config: ConfigService, @Inject(DOCUMENT) private document) {

  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

    var url: string;
    switch (this.config.getEnvironmentType()) {
      case EnvironmentType.Staging:
        url = "./assets/img/dev_favicon.png"
        break;
      case EnvironmentType.Demo:
        url = "./assets/img/demo_favicon.png"
        break;
      default:
        url = "./assets/img/prod_favicon.png"
        break;
    }

    const link = <HTMLLinkElement>(this.document.head.querySelector("link[rel='icon']")) || this.document.head.appendChild(this.document.createElement('link'));
    link.rel = 'icon';
    link.type = 'image/x-icon';
    link.href = url;
  }
}