import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'nexus-form-row',
  templateUrl: './nexus-form-row.component.html',
  styleUrls: ['./nexus-form-row.component.scss']
})
export class NexusFormRowComponent implements OnInit {
  @Input() public row_label: string;
  @Input() public row_sub_label: string;  
  @Input() public control_id: string;

  @Input() public label_class: string = "col-sm-4 col-md-3";
  @Input() public value_class: string = "col-sm-8 col-md-9";

  constructor() { }

  ngOnInit() {
  }

}
