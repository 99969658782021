import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, from, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { MembershipService } from '../services/membership.service';
import { TokenModel } from 'app/models/token.model';

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(private router: Router, private membership_service: MembershipService) 
    { 
    
    }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return from(this.membership_service.GetToken())
      .pipe(
        map((token : TokenModel)=>{
          if(token && token.token)
          {
              return true;
          }
          this.membership_service.redirectUrl = state.url;
          this.router.navigate(["login"]);
          return false;
        }), 
        catchError((err) => {
          this.router.navigate(["login"]);
          return of(false);
        })
      );
  
  }
}
