import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { PropertyFileModel } from 'app/models/property-file.model';
import { PropertyFilesService } from 'app/services/property-files.service';
import { Observable, of } from 'rxjs';

@Injectable()
export class PropertyFilesResolver implements Resolve<Array<PropertyFileModel>> {
  constructor(private service:PropertyFilesService){

  }

  resolve(route: ActivatedRouteSnapshot): Observable<Array<PropertyFileModel>> {
    return this.service.GetPropertyFiles();
  }
}
