import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { CustomerGroupModel } from 'app/models/customer-group.model';
import { GroupModel } from 'app/models/group.model';

@Component({
  selector: 'app-group-residents',
  templateUrl: './group-residents.component.html',
  styleUrls: ['./group-residents.component.scss']
})
export class GroupResidentsComponent extends NexusComponent{

  public group_model: GroupModel;
  public customers_model: Array<CustomerGroupModel>;
  public show_archived: boolean = false;
  public filters: FilterDescriptor[] = [];

  constructor(protected route: ActivatedRoute) {
    super();
  }

  filter() {
    if(!this.show_archived)
    {
      this.filters = [{ field: 'archived_date', operator: 'isnull' }];
    }
    else
    {
      this.filters = [];
    }
  }

  ngOnInit() {
    this.route.parent.data.subscribe(({ group_model }) => {
      this.group_model = group_model;
    });

    this.route.data.subscribe(({ customers_model }) => {
      this.customers_model = customers_model;
      this.filter();
    });

  }
}