<div class="row">
    <div class="col-sm-6">
      <nexus-card card_title="Filter">
        <nexus-card-body>        
        <nexus-form-row control_id="show-archived-input" row_label="Show Archived">
            <kendo-switch id="show-archived-input" [(ngModel)]="show_archived" (valueChange)="filter()">
            </kendo-switch>
          </nexus-form-row>
        </nexus-card-body>
      </nexus-card>
    </div>
  
  </div>
  
  <nexus-grid [data]="customers_model" [sort]="[{ 'field': 'customer.name', 'dir': 'asc' }]" [filters]="filters">
    <kendo-grid-column field="customer.name" title="Resident">
      <ng-template kendoGridCellTemplate let-dataItem>
        <nexus-customer-link [customer_model]="dataItem.customer" [text]="dataItem.customer.name"></nexus-customer-link>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="customer.email" title="E-Mail"></kendo-grid-column>
    <kendo-grid-column field="customer.phone" title="Phone">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.customer.phone | phone }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="start_date" title="Start Date" format="d" filter="date"></kendo-grid-column>
    <kendo-grid-column field="move_out_date" title="Move Out Date" format="d" filter="date"></kendo-grid-column>
    <kendo-grid-column field="end_date" title="End Date" format="d" filter="date"></kendo-grid-column>
    <kendo-grid-column field="active" title="Active" filter="boolean"></kendo-grid-column>
    <kendo-grid-column field="archived_date" title="Archived" [hidden]="!show_archived" format="G" filter="date"></kendo-grid-column>
  </nexus-grid>
