<div class="row">
    <div class="col-lg-6">
        <nexus-card card_title="Unit Information">
            <nexus-card-body>
                <form [formGroup]="group_form" (ngSubmit)="submit($event)">
                    <nexus-form-row control_id="address1-input" row_label="Address 1">
                        <input kendoTextBox class="form-control" id="address1-input" formControlName="address1" />
                    </nexus-form-row>
                    <nexus-form-row control_id="address2-input" row_label="Address 2">
                        <input kendoTextBox class="form-control" id="address2-input" formControlName="address2" />
                    </nexus-form-row>
                    <nexus-form-row control_id="city-input" row_label="City">
                        <input kendoTextBox class="form-control" id="city-input" formControlName="city" />
                    </nexus-form-row>
                    <nexus-form-row control_id="region-input" row_label="State">
                        <kendo-dropdownlist class="form-control" [data]="regions_model" textField="name" valueField="id"
                            [defaultItem]="{ name: 'Select State', id: null }" formControlName="region_id"
                            [valuePrimitive]="true">
                        </kendo-dropdownlist>
                    </nexus-form-row>
                    <nexus-form-row control_id="zip5-input" row_label="Zip 5">
                        <kendo-maskedtextbox class="form-control" id="zip5-input" mask="00000" formControlName="zip5">
                        </kendo-maskedtextbox>
                    </nexus-form-row>
                    <nexus-form-row row_label="Utilities Start Date" *ngIf="session.current_property.market.group_transitions_enabled">
                        <kendo-datepicker class="form-control" [format]="'MM/dd/yyyy'"
                            formControlName="transition_start_date"></kendo-datepicker>
                    </nexus-form-row>
                    <nexus-form-row row_label="Notes">
                        <nexus-editor formControlName="notes"></nexus-editor>
                    </nexus-form-row>

                    <nexus-form-row *ngIf="is_priority && create_group_model.priority_connection_fee">
                        Requesting utilities to be turned on earlier than {{ create_group_model.priority_date | amDateFormat:'M/D/YYYY' }} will result in an additional service
                        fee of {{ create_group_model.priority_connection_fee | currency }}
                    </nexus-form-row>
                    <nexus-form-row *ngIf="is_priority" control_id="priority" row_label="">
                        <kendo-switch id="priority" formControlName="priority"></kendo-switch> 
                        <label for="priority" class="ml-2" [class.priority-required]="group_form.controls.priority.invalid">I agree to pay the priority connection fee.</label>
                    </nexus-form-row>

                    <nexus-form-row *ngIf="!is_priority">
                        Please allow 3 business days for utility conversions to complete.
                    </nexus-form-row>
                </form>
                <nexus-form-row>
                    <button kendoButton [primary]="true" [icon]="'check'" type="button"
                        (click)="submit($event)">Submit</button>
                </nexus-form-row>
                <nexus-form-result [model]="group_result"></nexus-form-result>
            </nexus-card-body>
        </nexus-card>
    </div>
    <div class="col-lg-6">
        <nexus-card card_title="Owner Information">
            <nexus-card-body>
                <form [formGroup]="group_owner_form">
                    <nexus-form-row control_id="group-owner-input" row_label="Group Owner">
                        <nexus-form-group-owner-select id="group-owner-input" formControlName="group_owner_id"></nexus-form-group-owner-select>
                    </nexus-form-row>
                    <ng-container *ngIf="!group_model.group_owner_id">
                        <nexus-form-row><strong>Create Group Owner</strong></nexus-form-row>
                        <form [formGroup]="group_owner_form">
                            <nexus-form-row control_id="name-input" row_label="Name">
                                <input kendoTextBox class="form-control" id="name-input" formControlName="name" />
                            </nexus-form-row>
                            <nexus-form-row control_id="type-input" row_label="Type">
                                <kendo-dropdownlist id="type-input" class="form-control"
                                    [data]="group_owner_types | async" textField="name" valueField="value"
                                    [defaultItem]="{ name: 'Select Type', value: null }" formControlName="type"
                                    [valuePrimitive]="true"> </kendo-dropdownlist>
                            </nexus-form-row>
                            <nexus-form-row control_id="legal-name-input" row_label="Legal Name"
                                *ngIf="group_model.group_owner?.type == GroupOwnerType.Corporation">
                                <input kendoTextBox class="form-control" id="legal-name-input"
                                    formControlName="legal_name" />
                            </nexus-form-row>
                            <nexus-form-row control_id="tax-id-number-input" row_label="Tax ID Number">
                                <input kendoTextBox class="form-control" id="tax-id-number-input"
                                    formControlName="tax_id_number" />
                            </nexus-form-row>
                            <nexus-form-row row_label="Birthdate"
                                *ngIf="group_model.group_owner?.type == GroupOwnerType.Individual">
                                <kendo-datepicker class="form-control" [format]="'MM/dd/yyyy'"
                                    formControlName="birth_date">
                                </kendo-datepicker>
                            </nexus-form-row>
                        </form>
                    </ng-container>
                </form>
            </nexus-card-body>
        </nexus-card>
        <nexus-card card_title="Providers">
            <nexus-card-body>
                <form [formGroup]="provider_form">
                    <ng-container *ngFor="let category of create_group_model.provider_categories">
                        <nexus-form-row><strong>{{ category.name }}</strong></nexus-form-row>

                        <nexus-form-row [control_id]="category.id+'-provider'" row_label="Provider">
                            <kendo-dropdownlist [id]="category.id+'-provider'" class="form-control"
                                [data]="category.providers | orderBy:'name'" textField="name" valueField="id"
                                [defaultItem]="{ name: 'Not Selected', value: null }"
                                [formControlName]="category.id+'_provider_id'" [valuePrimitive]="true">
                            </kendo-dropdownlist>
                        </nexus-form-row>

                        <nexus-form-row [control_id]="category.id+'-active'" row_label="Utility is Currently Active">
                            <kendo-switch [id]="category.id+'-active'"
                                [formControlName]="category.id+'_onboarding_active'">
                            </kendo-switch>
                        </nexus-form-row>

                    </ng-container>
                </form>
            </nexus-card-body>
        </nexus-card>
    </div>
</div>
<p class="mt-3">
    <a [routerLink]="['/groups']">Back</a>
</p>
