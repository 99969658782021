import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { EventService } from '../../core/services/event.service';
import { InvoiceService } from '../../services/invoice.service';
import { InvoicesModel, InvoiceModel } from '../../models/invoices.model';

@Component({
    selector: 'app-invoices',
    templateUrl: './invoices.component.html',
    styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {
    public invoice_model: InvoicesModel;
    currentInvoiceCollapsed = true;
    public download_invoice_ids: Array<String> = new Array<String>();
    public entrata_button_list: Array<string> = ["All", "Defaults", "Journal", "Invoice"];
    public invoice_button_list: Array<any> = ["All", "Summary", "Details Report", "Details Export"];

    constructor(private route: ActivatedRoute, private router: Router, private event_service: EventService, private invoice_service: InvoiceService) {
    }

    ngOnInit() {
        this.route.data.subscribe(({ invoice_model }) => {
            this.invoice_model = invoice_model;
        });
    }

    createPdf(invoice: InvoiceModel) {
        this.event_service.loadingStart();

        this.invoice_service.CreatePdf(invoice.id).subscribe(
            data => {
                this.event_service.loadingFinish();
                saveAs(data.url, "invoice_" + invoice.invoice_number + ".pdf");
            },
            err => {
                this.event_service.loadingFinish();
            });
    }

    createExcel(invoice: InvoiceModel) {
        this.event_service.loadingStart();

        this.invoice_service.CreateExcel(invoice.id).subscribe(
            data => {
                this.event_service.loadingFinish();
                saveAs(data.url, "invoice_export_" + invoice.invoice_number + ".xlsx");
            },
            err => {
                this.event_service.loadingFinish();
            });
    }

    createExcelReport(invoice: InvoiceModel) {
        this.event_service.loadingStart();

        this.invoice_service.CreateExcelReport(invoice.id).subscribe(
            data => {
                this.event_service.loadingFinish();
                saveAs(data.url, "invoice_report_" + invoice.invoice_number + ".xlsx");
            },
            err => {
                this.event_service.loadingFinish();
            });
    }

    createInvoiceFiles(invoice: InvoiceModel) {
        this.event_service.loadingStart();

        this.invoice_service.CreateInvoiceFiles(invoice.id).subscribe(
            data => {
                this.event_service.loadingFinish();
                saveAs(data.url, "invoices_files_" + invoice.invoice_number + ".zip");
            },
            err => {
                this.event_service.loadingFinish();
            });
    }

    public download_invoice_files(dataItem: InvoiceModel): void {
        this.createInvoiceFiles(dataItem);
    }

    public select_invoice_download_type(item: string, dataItem: InvoiceModel) {
        switch (item) {
            case "All":
                this.createInvoiceFiles(dataItem);
                break;
            case "Summary":
                this.createPdf(dataItem);
                break;
            case "Details Report":
                this.createExcelReport(dataItem);
                break;
            case "Details Export":
                this.createExcel(dataItem);
                break;


            default:
                break;
        }
    }

    createDefaults(invoice: InvoiceModel) {
        this.event_service.loadingStart();

        this.invoice_service.CreateDefaults(invoice.id).subscribe(
            data => {
                this.event_service.loadingFinish();
                saveAs(data.url, "defaults_" + invoice.invoice_number + ".xlsx");
            },
            err => {
                this.event_service.loadingFinish();
            });
    }
    createJournals(invoice: InvoiceModel) {
        this.event_service.loadingStart();

        this.invoice_service.CreateJournals(invoice.id).subscribe(
            data => {
                this.event_service.loadingFinish();
                saveAs(data.url, "entrata_journals_" + invoice.invoice_number + ".csv");
            },
            err => {
                this.event_service.loadingFinish();
            });
    }
    createInvoices(invoice: InvoiceModel) {
        this.event_service.loadingStart();

        this.invoice_service.CreateInvoices(invoice.id).subscribe(
            data => {
                this.event_service.loadingFinish();
                saveAs(data.url, "entrata_invoices_" + invoice.invoice_number + ".csv");
            },
            err => {
                this.event_service.loadingFinish();
            });
    }
    createEntrataFiles(invoice: InvoiceModel) {
        this.event_service.loadingStart();

        this.invoice_service.CreateEntrataFiles(invoice.id).subscribe(
            data => {
                this.event_service.loadingFinish();
                saveAs(data.url, "entrata_files_" + invoice.invoice_number + ".zip");
            },
            err => {
                this.event_service.loadingFinish();
            });
    }

    downloadBills(invoice: InvoiceModel) {
        this.event_service.loadingStart();
        this.invoice_service.RequestFiles(invoice.id).subscribe(
            data => {
            this.download_invoice_ids.push(invoice.id);
                this.event_service.loadingFinish();
            },
            err => {
                this.event_service.loadingFinish();
            });
    }

    public download_entrata_files(dataItem: InvoiceModel): void {
        this.createEntrataFiles(dataItem);
    }

    public select_entrata_download_type(item: string, dataItem: InvoiceModel) {
        switch (item) {
            case "All":
                this.createEntrataFiles(dataItem);
                break;
            case "Journal":
                this.createJournals(dataItem);
                break;
            case "Defaults":
                this.createDefaults(dataItem);
                break;
            case "Invoice":
                this.createInvoices(dataItem);
                break;


            default:
                break;
        }
    }

    oneSiteDefaultsExport(invoice: InvoiceModel) {
        this.event_service.loadingStart();
        this.invoice_service.GetOneSiteDefaults(invoice.id).subscribe(
          file_model => {
            saveAs(file_model.url, "onesite_defaults_" + invoice.invoice_number + ".csv");
            this.event_service.loadingFinish();
          },
          error => {
            this.event_service.loadingFinish();
          });
      }
}
