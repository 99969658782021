import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { MembershipService } from 'app/core/services/membership.service';
import { SessionModel } from 'app/models/session.model';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { Router,NavigationStart,NavigationEnd } from '@angular/router';
import { GroupModel } from 'app/models/group.model';

@Component({
  selector: 'app-page-header',
  templateUrl: './app-page-header.component.html',
  styleUrls: ['./app-page-header.component.scss']
})
export class AppPageHeaderComponent extends NexusComponent {
  public session: SessionModel = null;
  public show_property_context = true;
  public show_resident_invoice_group_info = false;
  @Input() public title: string;
  @Input() public group_model:GroupModel;
  @Input() public report_title:string;

  constructor(public membership: MembershipService, private router: Router, private cdr: ChangeDetectorRef) {
    super();
    this.event_service.on('session-start', (model) => {
        this.session = model;
        this.shouldPropertyContextBeShown();
    });
    this.event_service.on('session-end', () => {
        this.session = null;
    });
}

ngOnInit() {
    this.membership.GetSession().subscribe(
        data => {
            this.session = data;
            if (data) {
                this.shouldPropertyContextBeShown();
            }
        },
        error => {
            this.session = null;
        });
    this.router.events.subscribe((event: any) => {
        if(event instanceof NavigationStart || event instanceof NavigationEnd) {
            this.shouldPropertyContextBeShown();
        }
        });
        if(this.group_model)
        {
            this.show_property_context = false;
            this.show_resident_invoice_group_info = true;
        }
        else{
            this.show_property_context = true;
            this.show_resident_invoice_group_info = false;
        }
}

shouldPropertyContextBeShown(){
    this.show_property_context = true;
    if( this.session && this.router.url && 
        (this.router.url.indexOf('/reports') >= 0 || this.router.url.indexOf('/report') >= 0)) {
            this.show_property_context = false;
    }
    this.cdr.detectChanges();
}

}
