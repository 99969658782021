import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MembershipService } from 'app/core/services/membership.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

    constructor(public membership: MembershipService, private router: Router) {

    }

    ngOnInit() {
        this.membership.ClearToken();
        this.router.navigate(['/login']);
    }

}
