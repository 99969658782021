import { Component, Input, QueryList } from '@angular/core';
import { CompositeFilterDescriptor, State } from '@progress/kendo-data-query';
import { FilterService, BaseFilterCellComponent, ColumnComponent } from '@progress/kendo-angular-grid';
import * as moment from 'moment/moment';

@Component({
  selector: 'app-nexus-grid-filter',
  templateUrl: './nexus-grid-filter.component.html',
  styleUrls: ['./nexus-grid-filter.component.scss']
})
export class NexusGridFilterComponent extends BaseFilterCellComponent {

  @Input('columns') public columns: QueryList<ColumnComponent>;
  @Input('state') public state: State;
  public filter_string: string = "";

  constructor(filterService: FilterService) { super(filterService); }

  keyPressed(event) {
    if (event.key === 'Enter') {
      this.filterTable();
    }
  }

  public filterTable(): void {
    this.remove_filters();
    if (this.filter_string && this.columns) {

      this.filter_string = this.filter_string.trim();

      let filter_list = [];
      for (let column of this.columns.toArray()) {
        if (column.field && !column.hidden && column.filterable) {
          filter_list.push({ field: column.field, operator: this.fullTextSearch, value: this.filter_string });
        }
      }

      const composite: CompositeFilterDescriptor = { logic: 'and', filters: [{ logic: 'or', filters: filter_list }] };
      this.applyFilter(composite);
    }
  }

  private fullTextSearch = function (item: any, value: string) {
    if (item == null || item == undefined) {
      return false;
    }

    //do not filter against guids
    if (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(item as string)) {
      return false;
    }

    let converted: any;
    if (item == false) {
      converted = 'false';
    }
    else if (item == true) {
      converted = 'true';
    }
    else if (item instanceof Date) {
      converted = moment(item).format('l LTS');
      
    }
    else if (!isNaN(item as number)) {
      converted = new Number(item).toFixed(4);
    }

    const search_str = value.toLowerCase().replace(/[^a-z0-9]/g, "");
    const item_str = JSON.stringify(item).toLowerCase().replace(/[^a-z0-9]/g, "",);

    let match: boolean = false;
    if(converted)
    {
      const converted_str = JSON.stringify(converted).toLowerCase().replace(/[^a-z0-9]/g, "",);
      if(converted_str.includes(search_str))
      {
        match = true;
        //for debugging
        //console.log(converted_str + " | " + search_str);
      }
    }
    if(item_str.includes(search_str))
    {
      match = true;
      //for debugging
      //console.log(item_str + " | " + search_str);
    }

    return match;
  }

  public clearFilters(): void {
    this.filter_string = "";
    this.remove_filters();
  }

  private remove_filters(): void {
    const composite: CompositeFilterDescriptor = { logic: 'and', filters: [] };
    this.applyFilter(composite);
  }

}
