import { NgModule, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders } from '@angular/core';
import { EventService } from './services/event.service';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SbCurrencyPipe } from './pipes/sbcurrency.pipe';
import { SbNaPipe } from './pipes/sb-na.pipe';
import { NgPipesModule } from 'ngx-pipes';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EditorModule } from '@tinymce/tinymce-angular';
import { NexusGridFilterComponent } from './components/nexus-grid-filter/nexus-grid-filter.component';
import { NexusGridComponent } from './components/nexus-grid/nexus-grid.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GridModule, ExcelModule, PDFModule } from '@progress/kendo-angular-grid';
import { InputsModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { MomentModule } from 'ngx-moment';
import { DropdownFilterComponent } from './components/dropdown-filter/dropdown-filter.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { NexusInjector } from './nexus.injector';
import { NexusFormResultComponent } from './components/nexus-form-result/nexus-form-result.component';
import { NexusComponent } from './components/nexus/nexus.component';
import { RegionService } from './services/region.service';
import { RegionsResolver } from './resolvers/regions.resolver';
import { AuthenticationGuard } from './guards/authentication.guard';
import { MembershipService } from './services/membership.service';
import { PropertyService } from './services/property.service';
import { PhonePipe } from './pipes/phone.pipe';
import { AppPageHeaderComponent } from './components/app-page-header/app-page-header.component';
import { DateInputsModule, DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { NexusFormRowComponent } from './components/nexus-form-row/nexus-form-row.component';
import { NexusCardComponent } from './components/nexus-card/nexus-card.component';
import { NexusCardBodyComponent } from './components/nexus-card-body/nexus-card-body.component';
import { NexusCardHeaderComponent } from './components/nexus-card-header/nexus-card-header.component';
import { NexusGroupNameComponent } from './components/nexus-group-name/nexus-group-name.component';
import { NexusRowComponent } from './components/nexus-row/nexus-row.component';
import { EnumService } from './services/enum.service';
import { NexusEditorComponent } from './components/nexus-editor/nexus-editor.component';
import { NexusFormFileInputComponent } from './components/nexus-form-file-input/nexus-form-file-input.component';
import { UploadModule } from '@progress/kendo-angular-upload';
import { NexusFormGroupOwnerSelectComponent } from './components/nexus-form-group-owner-select/nexus-form-group-owner-select.component';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import { GroupOwnerService } from './services/group-owner.service';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { DialogModule, DialogsModule } from '@progress/kendo-angular-dialog';
import { LocalizationService } from './services/localization.service';
import { NexusFormUserSelectComponent } from './components/nexus-form-user-select/nexus-form-user-select.component';
import { IntlModule } from '@progress/kendo-angular-intl';
import { NexusFileLinkComponent } from './components/nexus-file-link/nexus-file-link.component';
import { NexusInvoiceLinkComponent } from './components/nexus-invoice-link/nexus-invoice-link.component';
import { RouterModule } from '@angular/router';
import { NexusResidentInvoiceLinkComponent } from './components/nexus-resident-invoice-link/nexus-resident-invoice-link.component';
import { NexusCustomerLinkComponent } from './components/nexus-customer-link/nexus-customer-link.component';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { NexusGroupLinkComponent } from './components/nexus-group-link/nexus-group-link.component';

import "hammerjs";
import { NexusGridDateRangeFilterComponent } from './components/nexus-grid-date-range-filter/nexus-grid-date-range-filter.component';
import { NexusFlagButtonComponent } from './components/nexus-flag-button/nexus-flag-button.component';
import { NexusConfirmButtonComponent } from './components/nexus-confirm-button/nexus-confirm-button.component';

@NgModule({
  declarations: [
    SafeHtmlPipe,
    SafeUrlPipe,
    SbCurrencyPipe,
    SbNaPipe,
    PhonePipe,
    DropdownFilterComponent,
    NexusComponent,
    NexusGridFilterComponent,
    NexusGridComponent,
    NexusFormRowComponent,
    NexusFormResultComponent,
    NexusCardComponent,
    NexusCardBodyComponent,
    NexusCardHeaderComponent,
    NexusGroupNameComponent,
    NexusRowComponent,
    AppPageHeaderComponent,
    NexusEditorComponent,
    NexusFormFileInputComponent,
    NexusFormGroupOwnerSelectComponent,
    NexusFormUserSelectComponent,
    NexusFileLinkComponent,
    NexusInvoiceLinkComponent,
    NexusResidentInvoiceLinkComponent,
    NexusCustomerLinkComponent,
    NexusGroupLinkComponent,
    NexusGridDateRangeFilterComponent,
    NexusFlagButtonComponent,
    NexusConfirmButtonComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    GridModule,
    ExcelModule,
    PDFModule,
    InputsModule,
    DropDownsModule,
    LabelModule,
    LayoutModule,
    DialogModule,
    IntlModule,
    DateInputsModule,
    DialogsModule,
    NgbModule,
    ButtonsModule,
    TextBoxModule,
    UploadModule,
    EditorModule,
    MomentModule,
    DatePickerModule,
    NgPipesModule,
    FontAwesomeModule,
    ChartsModule
  ],
  exports: [
    NgPipesModule,
    MomentModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    GridModule,
    ExcelModule,
    PDFModule,
    InputsModule,
    ButtonsModule,
    DatePickerModule,
    DropDownsModule,
    LabelModule,
    LayoutModule,
    DialogModule,
    IntlModule,
    DateInputsModule,
    DialogsModule,
    FontAwesomeModule,
    SafeHtmlPipe,
    SafeUrlPipe,
    SbCurrencyPipe,
    SbNaPipe,
    PhonePipe,
    DropdownFilterComponent,
    NexusGridFilterComponent,
    NexusGridComponent,
    NexusFormRowComponent,
    NexusFormResultComponent,
    NexusCardComponent,
    NexusCardBodyComponent,
    NexusCardHeaderComponent,
    NexusGroupNameComponent,
    NexusRowComponent,
    AppPageHeaderComponent,
    NexusEditorComponent,
    NexusFormFileInputComponent,
    NexusFormGroupOwnerSelectComponent,
    NexusFormUserSelectComponent,
    NexusFileLinkComponent,
    NexusInvoiceLinkComponent,
    NexusResidentInvoiceLinkComponent,
    NexusCustomerLinkComponent,
    ChartsModule,
    NexusGroupLinkComponent,
    NexusFlagButtonComponent,
    NexusConfirmButtonComponent
  ]
})
export class CoreModule {
  constructor(injector: Injector, library: FaIconLibrary) {
    NexusInjector.setInjector(injector);
    library.addIcons(faTimesCircle);
  }
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        EventService,
        MembershipService,
        AuthenticationGuard,
        RegionService,
        PropertyService,
        GroupOwnerService,
        RegionsResolver,
        EnumService,
        LocalizationService
      ]
    };
  }
}
