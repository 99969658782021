<div class="bg-light">
    <div class="container">
        <div class="row">
            <div id="login-art" class="col-md-6 d-none d-md-flex">

            </div>
            <div class="col-md-6 text-center">
                <div id="login-wrapper">
                    <h2>Forgot Password?</h2>

                    <form [formGroup]="form" (ngSubmit)="submit()">
                        <div class="input-group mb-4">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <fa-icon [icon]="faAt" [fixedWidth]="true"></fa-icon>
                                </span>
                            </div>
                            <input type="text" class="form-control" placeholder="Email" formControlName="email">
                        </div>
                        <p>
                            <button kendoButton [primary]="true" icon="check">Submit</button>
                        </p>

                        <nexus-form-result [model]="form_result"></nexus-form-result>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>