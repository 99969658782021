<div style="margin-top:20px;">
  <div class="alert alert-warning" *ngIf="error_message">
    {{error_message}}
  </div>

  <div *ngIf="message" >
    <h2>{{message}}</h2>
  </div>

</div>
