import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { OnDemandEFBModel } from 'app/models/on-demand-efb.model';
import { OnDemandEFBsService } from 'app/services/on-demand-efbs.service';


@Injectable()
export class OnDemandEFBsPropertyResolver implements Resolve<Array<OnDemandEFBModel>> {
    constructor(private service: OnDemandEFBsService, private router: Router) {
    }
    resolve(route: ActivatedRouteSnapshot): Observable<Array<OnDemandEFBModel>> {
        return this.service.GetPropertyOnDemandEFBs(route.params.property_id);
    }
}