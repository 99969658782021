<div class="row">
<div class="col-lg-6">
<nexus-card  [card_title]="'Customer Information'">
    <nexus-card-body>
    <form [formGroup]="form">
      <nexus-form-row control_id="first-name-input" row_label="First Name">
        <input kendoTextBox type="text" class="form-control" id="first-name-input" formControlName="first_name" style="min-width:75%" />
      </nexus-form-row>
      <nexus-form-row control_id="last-name-input" row_label="Last Name">
        <input kendoTextBox type="text" class="form-control" id="last-name-input" formControlName="last_name" style="min-width:75%" />
      </nexus-form-row>
      <nexus-form-row control_id="email-input" row_label="E-Mail">
        <input kendoTextBox type="text" class="form-control" id="email-input" formControlName="email" style="min-width:75%" />
      </nexus-form-row>
      <nexus-form-row control_id="secondary-email-input" row_label="Secondary E-Mail">
        <input kendoTextBox type="email" class="form-control" id="secondary-email-input"
          formControlName="secondary_email"  style="min-width:75%"/>
      </nexus-form-row>
      <nexus-form-row control_id="phone-input" row_label="Phone">
        <kendo-maskedtextbox type="text" mask="(000) 000-0000" class="form-control" id="phone-input"
          formControlName="phone" style="min-width:75%"></kendo-maskedtextbox>
      </nexus-form-row>
      <nexus-form-row control_id="secondary-phone-input" row_label="Secondary Phone">
        <kendo-maskedtextbox type="text" mask="(000) 000-0000" class="form-control" id="secondary-phone-input"
          formControlName="secondary_phone" style="min-width:75%"></kendo-maskedtextbox>
      </nexus-form-row>
      <nexus-form-row row_label="Birthdate">
        <kendo-datepicker class="form-control" [format]="'MM/dd/yyyy'" formControlName="birth_date" style="min-width:75%"></kendo-datepicker>
      </nexus-form-row>
    </form>
  </nexus-card-body>
</nexus-card>
</div>
</div>