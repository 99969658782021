import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { ResourceModel } from 'app/models/resource.model';
import { SessionModel } from 'app/models/session.model';

@Component({
  selector: 'app-resource',
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.scss']
})
export class ResourceComponent extends NexusComponent {

  resource_model: ResourceModel = new ResourceModel();
  public session: SessionModel = null;

  constructor(private route: ActivatedRoute) {
    super();
    this.event_service.on('session-start', (model) => {
      this.session = model;
    });
    this.event_service.on('session-end', () => {
      this.session = null;
    });
  }

  ngOnInit(): void {
    this.route.data.subscribe(({ resource_model }) => {
      this.resource_model = resource_model;
    });
  }

}
