import { Component, OnInit, forwardRef, ViewEncapsulation, Input } from '@angular/core';
import { NexusComponent } from '../nexus/nexus.component';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'nexus-editor',
  templateUrl: './nexus-editor.component.html',
  styleUrls: ['./nexus-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => NexusEditorComponent),
    }
  ]
})
export class NexusEditorComponent extends NexusComponent implements ControlValueAccessor {
  @Input() public formControlName: string;
  public is_disabled: boolean = false;
  public loaded: boolean = false;
  public control: UntypedFormControl = new UntypedFormControl();

  public config = {
    base_url: '/tinymce', // Root for resources
    suffix: '.min',       // Suffix to use when loading resources
    plugins: 'print preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern code spellchecker save emoticons paste help',
    toolbar: 'formatselect | bold italic strikethrough forecolor backcolor emoticons | link image media pageembed | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat',
    height: 400
  };
 
  private load = () => {
    this.loaded = true;
  }

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    setTimeout(this.load, 250); //super hacky but required to fix bug https://github.com/tinymce/tinymce-angular/issues/76
    
    this.control.valueChanges.subscribe((data: any) => {
      this.propagateChange(data);
    });
  }

  private propagateChange = (value: any) => { };

  writeValue(obj: any): void {
    this.control.setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {

  }

  setDisabledState?(isDisabled: boolean): void {
    this.is_disabled = isDisabled;
  }

}
