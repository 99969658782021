import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'nexus-card-body',
  templateUrl: './nexus-card-body.component.html',
  styleUrls: ['./nexus-card-body.component.scss']
})
export class NexusCardBodyComponent implements OnInit {
  @Input() public section_title: string;

  constructor() { }

  ngOnInit() {
  }

}
