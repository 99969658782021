<div class="row card-row">


  <div [class.col-xl-7]="invoices_model" [class.col-xl-12]="!invoices_model" class="col-lg-12 mb-4">
    <div class="navbar navbar-light bg-light navbar-expand-md">
      <span class="navbar-brand">{{billing_period_date | amDateFormat:'MMMM YYYY' }}</span>


      <div class="collapse navbar-collapse">
        <ul class="navbar-nav mr-auto"></ul>

        <form [formGroup]="billing_period_search_form" (ngSubmit)="(billingPeriodSearchSubmit())">
          <kendo-dropdownlist placeholder="Billing Month" formControlName="billing_month" [data]="months"
                              textField="text" valueField="value" [valuePrimitive]="true" class="filter-control mr-1">
          </kendo-dropdownlist>
          <kendo-numerictextbox placeholder="Billing Year" formControlName="billing_year" [min]="2000" [max]="2050"
                                [autoCorrect]="true" [format]="'0'" class="filter-control mr-3"></kendo-numerictextbox>
          <button kendoButton [primary]="true" [icon]="'search'" type="submit"
                  [disabled]="!billing_period_search_form.valid">
            Search
          </button>
        </form>
      </div>
    </div>


    <div class="group-invoices">
      <nexus-grid #invoiceGrid [data]="invoices_model.invoices" [selectable]="{mode: 'single'}" (selectionChange)="selectionChange(invoiceGrid, $event)">

        <kendo-grid-column [width]="120" field="created_date" title="Created" format="G"
                           filter="date"></kendo-grid-column>
        <kendo-grid-column [width]="100" field="invoice_number" title="Invoice #">
          <ng-template kendoGridCellTemplate let-dataItem>
            <nexus-resident-invoice-link [invoice_model]="dataItem"></nexus-resident-invoice-link>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="100" field="billing_period_date" title="Period" format="d"
                           filter="date"></kendo-grid-column>
        <kendo-grid-column field="customer.first_name" title="First Name" [width]="110">
          <ng-template kendoGridCellTemplate let-dataItem>
            <nexus-customer-link [customer_model]="dataItem.customer" [text]="dataItem.customer.first_name"></nexus-customer-link>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="customer.last_name" title="Last Name" [width]="110">
          <ng-template kendoGridCellTemplate let-dataItem>
            <nexus-customer-link [customer_model]="dataItem.customer" [text]="dataItem.customer.last_name"></nexus-customer-link>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="item_amount" title="Amount" filter="numeric" format="{0:c}" [width]="100">
        </kendo-grid-column>
        <kendo-grid-column field="balance" title="Balance" filter="numeric" format="{0:c}" [width]="100">
        </kendo-grid-column>
        <kendo-grid-column field="date_due" title="Date Due" [width]="100" format="d" filter="date"></kendo-grid-column>
        <kendo-grid-column [width]="100" field="settled" title="Date Settled" [filter]="'boolean'">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span *ngIf="dataItem.settled" class="badge badge-success">{{ dataItem.settled_date | amDateFormat:'M/D/YYYY' }}</span>
            <span *ngIf="!dataItem.settled" class="badge badge-danger">Unsettled</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="late_fee_charged" title="Late Fee Charged" [width]="100">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span *ngIf="dataItem.late_fee_charged" class="badge badge-pill badge-danger">Late Fee</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="defaulted_date" title="Defaulted" [width]="100">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span *ngIf="dataItem.defaulted_date" class="badge badge-pill badge-danger">Defaulted</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [sticky]="true" [width]="100" [filterable]="false" [sortable]="false"
                           [columnMenu]="false">
          <ng-template kendoGridCellTemplate let-dataItem>
            <button kendoButton title="Download all Provider Bill Images for this Resident Invoice" class="k-button-icon k-button"
                    (click)="downloadBills(dataItem)">
                  <fa-icon [icon]="fileDownload"></fa-icon>
                  </button>
          </ng-template>
        </kendo-grid-column>
      </nexus-grid>
    </div>

  </div>



  <div class="col-xl-5 col-lg-12" *ngIf="!current_invoice">
    <div class="card invoices-card">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col-sm-1 align-items-center">
            <div>
              <button title="Previous" (click)="selectPrevious()" class="float-left mr-1 btn btn-outline-primary chevron-button">
                <fa-icon [icon]="chevronLeft"></fa-icon>
              </button>
            </div>
          </div>
          <div class="col-sm-10 text-lg-center">
            <div></div>
          </div>
          <div class="col-sm-1 align-items-center">
            <button title="Next" (click)="selectNext()" class="float-right ml-1 btn btn-outline-primary chevron-button">
              <fa-icon [icon]="chevronRight"></fa-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body text-center">
        No invoice selected.
      </div>
    </div>
  </div>




  <div class="col-xl-5 col-lg-12" *ngIf="current_invoice">
    <div class="card invoices-card">
      <div class="card-header">
        <div class="row align-items-center">


          <div class="col-sm-1 align-items-center">
            <div>
              <button title="Previous" (click)="selectPrevious()" class="float-left mr-1 btn btn-outline-primary chevron-button">
                <fa-icon [icon]="chevronLeft"></fa-icon>
              </button>
            </div>
          </div>


          <div class="col-sm-10 text-lg-center">
            <div><h3> {{current_invoice?.customer?.name}} - Invoice # {{current_invoice?.invoice_number}}</h3></div>
          </div>


          <div class="col-sm-1 align-items-center">
            <button title="Next" (click)="selectNext()" class="float-right ml-1 btn btn-outline-primary chevron-button">
              <fa-icon [icon]="chevronRight"></fa-icon>
            </button>
          </div>


        </div>
      </div>

      <div class="card-body">
        <div>
          <div class="row text-sm">
            <div class="col-sm-4 text-sm"><strong>Created: </strong>{{ current_invoice?.created_date | amDateFormat:'M/D/YYYY hh:mm:ss A'}}</div>
            <div class="col-sm-4 text-sm-left"><strong>Date Due: </strong>{{ current_invoice?.date_due | amDateFormat:'M/D/YYYY' }}</div>
            <div class="col-sm-4 text-sm-left"><strong>Late Fee Charged: </strong>{{ current_invoice?.late_fee_charged }}</div>
          </div>
          <br />
          <div class="row text-sm">
            <div class="col-sm-4 text-sm"><strong>Invoice: </strong><nexus-resident-invoice-link [invoice_model]="current_invoice"></nexus-resident-invoice-link></div>
            <div class="col-sm-4 text-sm-left"><strong>Amount: </strong>{{ current_invoice?.item_amount | sbCurrency}}</div>
            <div class="col-sm-4 text-sm-left"><strong>Defaulted: </strong>{{ current_invoice?.defaulted_date? true: false }}</div>
          </div>
          <br />
          <div class="row text-sm">
            <div class="col-sm-4 text-sm"><strong>Customer: </strong><nexus-customer-link [customer_model]="current_invoice?.customer" [text]="   current_invoice?.customer?.name"></nexus-customer-link></div>
            <div class="col-sm-4 text-sm-left"><strong>Balance: </strong>{{ current_invoice?.balance | sbCurrency}}</div>
            <div class="col-sm-4 text-sm-left"><strong>Settled: </strong>{{ current_invoice?.settled }}</div>
          </div>
          <br />
          <div class="row text-sm">
            <div class="col-sm-4 text-sm"><strong>Issued On: </strong>{{ current_invoice?.date_sent | amDateFormat:'M/D/YYYY'}}</div>
            <div class="col-sm-4 text-sm-left"><strong>Reference: </strong>{{ current_invoice?.reference }}</div>
            <div class="col-sm-4 text-sm-left"><strong>Settled Date: </strong>{{ current_invoice?.settled_date | amDateFormat:'M/D/YYYY hh:mm:ss A'}}</div>
          </div>
          <br />
        </div>


        <div>
          <nexus-grid [data]="current_invoice?.items_summary" [sort]="[{ 'field': 'created_date', 'dir': 'desc' }]">
            <kendo-grid-column field="invoice_item_type_name" title="Item Type" [width]="100">
            </kendo-grid-column>
            <kendo-grid-column field="provider_name" title="Provider" [width]="100"></kendo-grid-column>
            <kendo-grid-column field="amount" title="Amount" filter="numeric" format="{0:c}" [width]="100">
            </kendo-grid-column>
            <kendo-grid-column field="percent" title="Percent" filter="numeric" format="{0:p}" [width]="100">
            </kendo-grid-column>
            <kendo-grid-column field="start_date" title="Start" format="d" filter="date" [width]="130">
            </kendo-grid-column>
            <kendo-grid-column field="end_date" title="End" format="d" filter="date" [width]="130">
            </kendo-grid-column>
            <kendo-grid-column field="usage_days" title="Usage Days" filter="numeric" [width]="100">
            </kendo-grid-column>
            <kendo-grid-column field="ledger_code_name" title="Ledger Code" [width]="100">
            </kendo-grid-column>
            <kendo-grid-column field="customer_portion" title="Customer Portion" filter="numeric" format="{0:p}" [width]="100">
            </kendo-grid-column>
            <kendo-grid-column field="property_portion" title="Property Portion" filter="numeric" format="{0:p}" [width]="100">
            </kendo-grid-column>
            <kendo-grid-column field="bill_amount" title="Bill Amount" filter="numeric" format="{0:c}"
                               [width]="100">
            </kendo-grid-column>
            <kendo-grid-column field="reference" title="Reference" [width]="100"></kendo-grid-column>
          </nexus-grid>
        </div>
      </div>
    </div>
  </div>
</div>
