import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { EventService } from '../../core/services/event.service';


@Component({
  selector: 'app-component-loading-indicator',
  templateUrl: './component-loading-indicator.component.html',
  styleUrls: ['./component-loading-indicator.component.scss']
})
export class ComponentLoadingIndicatorComponent implements OnInit {
    public route_spinner: boolean = false;
    public processes: Array<string> = new Array<string>();

    constructor(private router: Router, private event_service: EventService) {
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.route_spinner = true;
            }
            if (event instanceof NavigationEnd) {
                this.route_spinner = false;
            }
            if (event instanceof NavigationCancel) {
                this.route_spinner = false;
            }
            if (event instanceof NavigationError) {
                this.route_spinner = false;
            }
        });

        
    }

    ngOnInit() {
        this.event_service.on('loading-start', (process) => {
            if(!this.processes.includes(process))
            {
                this.processes.push(process);
            }
        });

        this.event_service.on('loading-finish', (process) => {
            this.processes.splice(this.processes.indexOf(process), 1);
        });
    }

}
