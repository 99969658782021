import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, AbstractControl, Validators, ValidatorFn, UntypedFormGroup } from '@angular/forms';
import { NexusInjector } from '../../nexus.injector';
import { EventService } from '../../services/event.service';
@Component({
  selector: 'app-nexus',
  templateUrl: './nexus.component.html',
  styleUrls: ['./nexus.component.scss']
})
export class NexusComponent implements OnInit {
  protected event_service: EventService;

  constructor() {
    const injector = NexusInjector.getInjector();
    this.event_service = injector.get(EventService);
  }

  ngOnInit() {
  }

  public PopulateModelFromForm(form_data, model) {
    for (var propName in form_data) {
      model[propName] = form_data[propName];
    }
  }

  public StartSpinner(process: string = 'default') {
    this.event_service.loadingStart(process);
  }

  public StopSpinner(process: string = 'default') {
    this.event_service.loadingFinish(process);
  }

  public CreateFormControl(current_value = null) {
    return new UntypedFormControl(current_value);
  }
  public CreateRequiredFormControl(current_value = null) {
    return new UntypedFormControl(current_value, Validators.required);
  }

  public ClearFormControlValidator(control: AbstractControl) {
    control.clearValidators();
    control.updateValueAndValidity();
  }

  public SetFormControlValidator(control: AbstractControl, validators: ValidatorFn) {
    control.setValidators(validators);
    control.updateValueAndValidity();
  }

  public SetFormTouched(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } 
      else if (control instanceof UntypedFormGroup) {
        this.SetFormTouched(control);
      }
    });
  }
  

}
