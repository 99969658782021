import { Component, OnInit, ComponentFactoryResolver } from '@angular/core';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { ActivatedRoute } from '@angular/router';
import { PreBillStepsModel } from 'app/models/pre-bill-step.model';

@Component({
  selector: 'app-pre-bill-host',
  templateUrl: './pre-bill-host.component.html',
  styleUrls: ['./pre-bill-host.component.scss']
})
export class PreBillHostComponent extends NexusComponent {
  pre_bill_steps_model: PreBillStepsModel;

  constructor(private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.route.data.subscribe(({ pre_bill_steps_model }) => {
      this.pre_bill_steps_model = pre_bill_steps_model;
    });
  }

}
