import { ReportResolver } from './resolvers/report.resolver';
import { ReportsResolver } from './resolvers/reports.resolver';
import { ReportEmbedService } from './services/report-embed.service';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';

// Import 3rd party components
import { CommonModule } from '@angular/common';
import { TelerikReportingModule } from '@progress/telerik-angular-report-viewer';
import { AppComponent } from './app.component';


// Import routing module
import { AppRoutingModule } from './app.routing';

//import services
import { ResidentService } from './services/resident.service';
import { DashboardService } from './services/dashboard.service';
import { InvoiceService } from './services/invoice.service';
import { UtilityService } from './services/utility.service';
import { ReportService } from './services/report.service';
import { DashboardResolver } from './resolvers/dashboard.resolver';
import { ResidentsResolver } from './resolvers/residents.resolver';
import { MoveInResolver } from './resolvers/move-in.resolver';
import { ResidentResolver } from './resolvers/resident.resolver';
import { InvoicesResolver } from './resolvers/invoices.resolver';
import { CoreModule } from './core/core.module';
import { ApiInterceptor } from './interceptors/api.interceptor';
import { PreBillHostComponent } from './components/pre-bill-host/pre-bill-host.component';
import { ComponentLoadingIndicatorComponent } from './components/component-loading-indicator/component-loading-indicator.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ErrorComponent } from './components/error/error.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { HomeComponent } from './components/home/home.component';
import { ResidentsComponent } from './components/residents/residents.component';
import { MoveInComponent } from './components/move-in/move-in.component';
import { ResidentComponent } from './components/resident/resident.component';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { UtilitiesComponent } from './components/utilities/utilities.component';
import { ReportsComponent } from './components/reports/reports.component';
import { ReportComponent } from './components/report/report.component';
import { BookingComponent } from './components/booking/booking.component';
import { AppFooterComponent } from './components/app-footer';
import { AppHeaderComponent } from './components/app-header';
import { AppSubHeaderComponent } from './components/app-sub-header';
import { WorkflowHostComponent } from './components/workflow-host/workflow-host.component';
import { ConfigService } from './services/config.service';
import { SentryErrorHandler } from './handlers/sentry.error-handler';
import { PreBillStepsService } from './services/pre-bill-steps.service';
import { PreBillStepsResolver } from './resolvers/pre-bill-steps.resolver';
import { SettingsParentComponent } from './components/setting-parent/setting-parent.component';
import { GroupComponent } from './components/groups/group/group.component';
import { GroupsComponent } from './components/groups/groups.component';
import { CreateGroupComponent } from './components/groups/create-group/create-group.component';
import { GroupService } from './services/group.service';
import { GroupOwnersResolver } from './resolvers/group-owners.resolver';
import { GroupResolver } from './resolvers/group.resolver';
import { CreateGroupResolver } from './resolvers/create-group.resolver';
import { GroupsResolver } from './resolvers/groups.resolver';
import { PropertyFilesComponent } from './components/property-files/property-files.component';
import { PropertyFileComponent } from './components/property-files/property-file/property-file.component';
import { PropertyFilesResolver } from './resolvers/property-files.resolver';
import { PropertyFileResolver } from './resolvers/property-file.resolver';
import { PropertyFileDeleteModalComponent } from './components/property-files/property-file-delete-modal/property-file-delete-modal.component';
import { ProviderBillService } from './services/provider-bill.service';
import { FileDownloadComponent } from './components/file-download/file-download.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { ResidentInvoicesComponent } from './components/invoices/resident-invoices/resident-invoices.component';
import { ResidentInvoicesResolver } from './resolvers/resident-invoices.resolver';
import { ResidentInvoiceComponent } from './components/invoice/resident-invoice/resident-invoice.component';
import { ResidentInvoiceResolver } from './resolvers/resident-invoice.resolver';
import { CustomerComponent } from './components/customer/customer.component';
import { CustomerResolver } from './resolvers/customer.resolver';
import { CustomerInformationComponent } from './components/customer/customer-information/customer-information.component';
import { CustomerGroupsComponent } from './components/customer/customer-groups/customer-groups.component';
import { CustomerEmailsComponent } from './components/customer/customer-emails/customer-emails.component';
import { CustomerSmsMessagesComponent } from './components/customer/customer-sms-messages/customer-sms-messages.component';
import { CustomerPhoneCallsComponent } from './components/customer/customer-phone-calls/customer-phone-calls.component';
import { CustomerEmailsResolver } from './resolvers/customer-emails.resolver';
import { CustomerPhoneCallsResolver } from './resolvers/customer-phone-calls.resolver';
import { CustomerSmsMessagesResolver } from './resolvers/customer-sms-messages.resolver';
import { CustomerGroupsResolver } from './resolvers/customer-groups.resolver';
import { CustomerInvoicesComponent } from './components/customer/customer-invoices/customer-invoices.component';
import { CustomerInvoicesResolver } from './resolvers/customer-invoices.resolver';
import { OnDemandEFBsComponent } from './components/on-demand-efbs/on-demand-efbs.component';
import { OnDemandEFBsResolver } from './resolvers/on-demand-efbs.resolver';
import { OnDemandEFBsService } from './services/on-demand-efbs.service';
import { GroupInformationComponent } from './components/groups/group/group-information/group-information.component';
import { GroupInvoicesComponent } from './components/groups/group/group-invoices/group-invoices.component';
import { GroupInvoicesResolver } from './resolvers/group-invoices.resolver';
import { GroupResidentsComponent } from './components/groups/group/group-residents/group-residents.component';
import { GroupCustomersResolver } from './resolvers/group-customers.resolver';
import { GroupAccountsComponent } from './components/groups/group/group-accounts/group-accounts.component';
import { GroupAccountsResolver } from './resolvers/group-accounts.resolver';
import { GroupFutureBillingComponent } from './components/groups/group/group-future-billing/group-future-billing.component';
import { GroupFutureBillingCustomerPortionsResolver } from './resolvers/group-future-billing-customer-portions.resolver';
import { GroupIntegrationsComponent } from './components/groups/group/group-integrations/group-integrations.component';
import { GroupIntegrationsResolver } from './resolvers/group-integrations.resolver';
import { OnDemandEFBsPropertyResolver } from './resolvers/on-demand-efbs-property.resolver';
import { ResourcesComponent } from './components/resources/resources.component';
import { ResourceComponent } from './components/resources/resource/resource.component';
import { CustomerInvoiceItemsResolver } from './resolvers/customer-invoice-items.resolver';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        TelerikReportingModule,
        CoreModule.forRoot()
    ],
    declarations: [
        AppComponent,
        DashboardComponent,
        AppFooterComponent,
        AppHeaderComponent,
        AppSubHeaderComponent,
        ErrorComponent,
        LoginComponent,
        LogoutComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        ComponentLoadingIndicatorComponent,
        HomeComponent,
        ResidentsComponent,
        MoveInComponent,
        ResidentComponent,
        InvoicesComponent,
        UtilitiesComponent,
        ReportsComponent,
        ReportComponent,
        BookingComponent,
        SettingsParentComponent,
        PreBillHostComponent,
        WorkflowHostComponent,
        GroupComponent,
        GroupsComponent,
        CreateGroupComponent,
        PropertyFilesComponent,
        PropertyFileComponent,
        PropertyFileDeleteModalComponent,
        FileDownloadComponent,
        InvoiceComponent,
        ResidentInvoicesComponent,
        ResidentInvoiceComponent,
        CustomerComponent,
        CustomerInformationComponent,
        CustomerGroupsComponent,
        CustomerEmailsComponent,
        CustomerSmsMessagesComponent,
        CustomerPhoneCallsComponent,
        CustomerInvoicesComponent,
        OnDemandEFBsComponent,
        GroupInformationComponent,
        GroupInvoicesComponent,
        GroupResidentsComponent,
        GroupAccountsComponent,
        GroupFutureBillingComponent,
        GroupIntegrationsComponent,
        ResourcesComponent,
        ResourceComponent,
    ],
    providers: [
        DashboardService,
        ResidentService,        
        InvoiceService,
        UtilityService,
        ReportService,
        ReportEmbedService,
        PreBillStepsService,
        ProviderBillService,
        OnDemandEFBsService,
        DashboardResolver,
        ResidentsResolver,
        MoveInResolver,
        ResidentResolver,
        CustomerResolver,
        CustomerEmailsResolver,
        CustomerPhoneCallsResolver,
        CustomerSmsMessagesResolver,
        CustomerGroupsResolver,
        CustomerInvoicesResolver,
        CustomerInvoiceItemsResolver,
        InvoicesResolver,
        ResidentInvoicesResolver,
        ReportsResolver,
        ReportResolver,
        GroupService,
        GroupsResolver,
        GroupOwnersResolver,
        GroupResolver,
        CreateGroupResolver,
        PropertyFilesResolver,
        PropertyFileResolver,
        PreBillStepsResolver,
        OnDemandEFBsResolver,
        OnDemandEFBsPropertyResolver,
        GroupInvoicesResolver,
        GroupCustomersResolver,
        GroupAccountsResolver,
        GroupFutureBillingCustomerPortionsResolver,
        GroupIntegrationsResolver,
        {
            provide: LOCALE_ID,
            useValue: "en-US"
        },
        {
            provide: ErrorHandler,
            useClass: SentryErrorHandler
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptor,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [ConfigService],
            multi: true
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function appInitializerFactory(configService: ConfigService) {
    return () => configService.loadConfig();
}
