import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { MembershipService } from 'app/core/services/membership.service';
import { ResourceType } from 'app/enums/resource-type.enum';
import { ResourceModel } from 'app/models/resource.model';
import { SessionModel } from 'app/models/session.model';
import { ResourcesService } from 'app/services/resources.service';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent extends NexusComponent {

  public session: SessionModel = null;
  resources_model: Array<ResourceModel> = new Array<ResourceModel>();

  constructor(private route: ActivatedRoute, private router: Router, private membership: MembershipService,
    private resources_service: ResourcesService) {
    super();
    this.event_service.on('session-start', (model) => {
      this.session = model;
    });
    this.event_service.on('session-end', () => {
      this.session = null;
    });
  }

  ngOnInit(): void {
    this.route.data.subscribe(({ resources_model }) => {
      this.resources_model = resources_model;
    });
  }

  navigate(data:ResourceModel) {
    if (data.resource_type == ResourceType.Document) {
      window.open(data.file_document_file_id.public_url, "_blank");
    }
    else {
      window.open(`/resources/${data.id}`,"_blank")
    }
  }


}
