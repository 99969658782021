<app-page-header [title]="title" [group_model]="invoice_model.group"></app-page-header>

<div class="row card-row mt-3">
    <div class="col-lg-4">
        <div class="card">
            <div class="card-header">Resident Invoice Details</div>
            <div class="card-body">
                <dl class="row">
                    <dt class="col-sm-5">Invoice Number</dt>
                    <dd class="col-sm-7">{{ invoice_model.invoice_number }}</dd>
                    <dt class="col-sm-5">Billing Period</dt>
                    <dd class="col-sm-7">
                        {{ invoice_model.billing_period_date | amDateFormat:'MMMM YYYY' }}
                    </dd>
                    <dt class="col-sm-5">Amount</dt>
                    <dd class="col-sm-7">{{ invoice_model.item_amount | sbCurrency }}</dd>
                    <dt class="col-sm-5">Date Sent</dt>
                    <dd class="col-sm-7">
                        {{ invoice_model.date_sent | amDateFormat:'M/D/YYYY' }}
                    </dd>
                    <dt class="col-sm-5">Date Due</dt>
                    <dd class="col-sm-7">
                        {{ invoice_model.date_due | amDateFormat:'M/D/YYYY' }}</dd>
                    <dt class="col-sm-5">Balance</dt>
                    <dd class="col-sm-7">{{ invoice_model.balance | sbCurrency }}&nbsp;&nbsp;<span
                            *ngIf="invoice_model.settled" class="badge badge-success">Settled</span><span
                            *ngIf="!invoice_model.settled" class="badge badge-danger">Unsettled</span>
                    </dd>
                    <dt class="col-sm-5" *ngIf="invoice_model.settled">Settled Date</dt>
                    <dd class="col-sm-7" *ngIf="invoice_model.settled">{{ invoice_model.settled_date |
                        amDateFormat:'M/D/YYYY' }}</dd>
                    <dt class="col-sm-5">Late Fee Charged</dt>
                    <dd class="col-sm-7">{{ invoice_model.late_fee_charged?'True':'False' }}</dd>
                </dl>
            </div>
        </div>
    </div>

    <div class="col-lg-8">
        <div class="card">
            <div class="card-header">Transactions</div>
            <div class="card-body p-0">
                <nexus-grid [data]="invoice_model.transactions" [sort]="[{ 'field': 'created_date', 'dir': 'desc' }]">
                    <kendo-grid-column field="created_date" title="Created" format="G" filter="date" [width]="130">
                    </kendo-grid-column>
                    <kendo-grid-column field="created_by_user.name" title="Created By" [width]="100">
                    </kendo-grid-column>
                    <kendo-grid-column field="invoice_transaction_type.name" title="Transaction Type" [width]="100">
                    </kendo-grid-column>
                    <kendo-grid-column field="amount" title="Amount" filter="numeric" format="{0:c}" [width]="100">
                    </kendo-grid-column>
                    <kendo-grid-column field="reference" title="Reference" [width]="100"></kendo-grid-column>
                    <kendo-grid-column field="voided_date" title="Voided" format="G" filter="date" [width]="140">
                    </kendo-grid-column>
                </nexus-grid>
            </div>
        </div>
    </div>
</div>
<div class="row card-row mt-3">
    <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="d-flex align-items-center justify-content-between">
              <span class="flex-grow-1">
                Items
              </span>
              <div class="ml-auto text-right">
                <button kendoButton title="Download all Provider Bill Images for this Resident Invoice" [primary]="false" type="submit"
                            (click)="downloadBills(invoice_model)" *ngIf="show_download_invoice_bill_images">
                            <fa-icon [icon]="fileDownload"></fa-icon>
                            Download Invoice Bill Images</button>
              </div>
            </div>
          </div>
            <div class="card-body p-0">
              <nexus-grid [data]="invoice_model.items_summary" [sort]="[{ 'field': 'created_date', 'dir': 'desc' }]">
                
                <kendo-grid-column field="summary_account" [width]="180" title="Summary Account" [hidden]="true">
                </kendo-grid-column>

                <kendo-grid-column field="invoice_item_type_name" title="Item Type" [width]="100">
                </kendo-grid-column>
                <kendo-grid-column field="provider_name" title="Provider" [width]="100"></kendo-grid-column>
                <kendo-grid-column field="provider_category_name" title="Category" [width]="100">
                </kendo-grid-column>
                <kendo-grid-column *ngIf="invoice_model.defaults_export_enabled" field="reference_id"
                                   title="Integration Unit Id" [width]="100">
                </kendo-grid-column>
                <kendo-grid-column field="address1" title="Address" [width]="100">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <nexus-group-link [group_model]="dataItem.group" [text]="dataItem.group?.address1">
                    </nexus-group-link>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="address2" title="Unit" [width]="100">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <nexus-group-link [group_model]="dataItem.group" [text]="dataItem.group?.address2">
                    </nexus-group-link>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="amount" title="Amount" filter="numeric" format="{0:c}" [width]="100">
                </kendo-grid-column>
                <kendo-grid-column field="percent" title="Percent" filter="numeric" format="{0:p}" [width]="100">
                </kendo-grid-column>
                <kendo-grid-column field="start_date" title="Start" format="d" filter="date" [width]="130">
                </kendo-grid-column>
                <kendo-grid-column field="end_date" title="End" format="d" filter="date" [width]="130">
                </kendo-grid-column>
                <kendo-grid-column field="ledger_code_name" title="Ledger Code" [width]="100">
                </kendo-grid-column>
                <kendo-grid-column field="customer_portion" title="Resident Portion" filter="numeric" format="{0:c}"
                                   [width]="100">
                </kendo-grid-column>
                <kendo-grid-column field="bill_amount" title="Bill Amount" filter="numeric" format="{0:c}"
                                   [width]="100">
                </kendo-grid-column>

                <kendo-grid-column [sticky]="true" [width]="100" [filterable]="false" [sortable]="false"
                                   [columnMenu]="false">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <button kendoButton title="Download Provider Bill Image for this Invoice Item" *ngIf="dataItem.provider_bill_file"
                            (click)="downloadBillItem(dataItem)">
                     <fa-icon [icon]="fileDownload"></fa-icon>
                          </button>
                  </ng-template>
                </kendo-grid-column>

              </nexus-grid>
            </div>
        </div>
    </div>
</div>
<div class="row card-row mt-3">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">Invoice Actions</div>
            <div class="card-body p-0">
                <nexus-grid [data]="invoice_model.actions_summary"
                    [sort]="[{ 'field': 'scheduled_date', 'dir': 'asc' }]">
                    <kendo-grid-column field="notification" title="Notification"></kendo-grid-column>
                    <kendo-grid-column field="action_type_name" title="Action Type"></kendo-grid-column>
                    <kendo-grid-column field="scheduled_date" title="Scheduled" format="G" filter="date">
                    </kendo-grid-column>
                    <kendo-grid-column field="completed_date" title="Date Completed" format="G" filter="date">
                    </kendo-grid-column>
                </nexus-grid>
            </div>
        </div>
    </div>
</div>
