import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { MarketType } from 'app/enums/market-type.enum';
import { InvoiceModel } from 'app/models/invoices.model';
import { ProviderBillFileModel } from 'app/models/provider-bill-file.model';
import { InvoiceService } from 'app/services/invoice.service';
import FileSaver from 'file-saver';
import { InvoiceItemSummary } from '../../../models/invoice-item-summary.model';
import {saveAs} from 'file-saver';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-resident-invoice',
  templateUrl: './resident-invoice.component.html',
  styleUrls: ['./resident-invoice.component.scss']
})
export class ResidentInvoiceComponent extends NexusComponent  {

  invoice_model: InvoiceModel;
  title_invoice_type : string;
  title: string;
  address : string;

  single_family: boolean;
  MarketType: MarketType;

  public download_invoice_ids: Array<String> = new Array<String>();
  currentInvoiceCollapsed = true;
  public entrata_button_list: Array<string> = ["All", "Defaults", "Journal", "Invoice"];
  public invoice_button_list: Array<any> = ["All", "Summary", "Details Report", "Details Export"];

  public fileDownload = faFileDownload;

  public get show_download_invoice_bill_images(): boolean {
    return (this.invoice_model?.items_summary?.filter(x=>x.provider_bill_file)?.length ?? 0) > 0;
  }

  constructor(private route: ActivatedRoute, private router: Router, private invoice_service: InvoiceService) {
      super();
  }

  ngOnInit() {
    this.route.data.subscribe(({ invoice_model }) => {
      this.invoice_model = invoice_model;
      this.single_family = invoice_model.market.type === MarketType.SingleFamily;
        this.title = `Resident Invoice #${this.invoice_model.invoice_number} - ${this.invoice_model.customer.name}`;
    });
  }



  public select_invoice_download_type(item: string) {
    switch (item) {
      case "All":
        this.createInvoiceFiles(this.invoice_model);
        break;
      case "Summary":
        this.createPdf(this.invoice_model);
        break;
      case "Details Report":
        this.createExcelReport(this.invoice_model);
        break;
      case "Details Export":
        this.createExcel(this.invoice_model);
        break;
      default:
        break;
    }
  }

  createPdf(invoice: InvoiceModel) {
    this.event_service.loadingStart();

    this.invoice_service.CreatePdf(invoice.id).subscribe(
      data => {
        this.event_service.loadingFinish();
        saveAs(data.url, "invoice_" + invoice.invoice_number + ".pdf");
      },
      err => {
        this.event_service.loadingFinish();
      });
  }

  createExcel(invoice: InvoiceModel) {
    this.event_service.loadingStart();

    this.invoice_service.CreateExcel(invoice.id).subscribe(
      data => {
        this.event_service.loadingFinish();
        saveAs(data.url, "invoice_export_" + invoice.invoice_number + ".xlsx");
      },
      err => {
        this.event_service.loadingFinish();
      });
  }

  createExcelReport(invoice: InvoiceModel) {
    this.event_service.loadingStart();

    this.invoice_service.CreateExcelReport(invoice.id).subscribe(
      data => {
        this.event_service.loadingFinish();
        saveAs(data.url, "invoice_report_" + invoice.invoice_number + ".xlsx");
      },
      err => {
        this.event_service.loadingFinish();
      });
  }

  createInvoiceFiles(invoice: InvoiceModel) {
    this.event_service.loadingStart();

    this.invoice_service.CreateInvoiceFiles(invoice.id).subscribe(
      data => {
        this.event_service.loadingFinish();
        saveAs(data.url, "invoices_files_" + invoice.invoice_number + ".zip");
      },
      err => {
        this.event_service.loadingFinish();
      });
  }


  public download_invoice_files(dataItem: InvoiceModel): void {
    this.createInvoiceFiles(dataItem);
  }


  createDefaults(invoice: InvoiceModel) {
    this.event_service.loadingStart();

    this.invoice_service.CreateDefaults(invoice.id).subscribe(
      data => {
        this.event_service.loadingFinish();
        saveAs(data.url, "defaults_" + invoice.invoice_number + ".xlsx");
      },
      err => {
        this.event_service.loadingFinish();
      });
  }
  createJournals(invoice: InvoiceModel) {
    this.event_service.loadingStart();

    this.invoice_service.CreateJournals(invoice.id).subscribe(
      data => {
        this.event_service.loadingFinish();
        saveAs(data.url, "entrata_journals_" + invoice.invoice_number + ".csv");
      },
      err => {
        this.event_service.loadingFinish();
      });
  }
  createInvoices(invoice: InvoiceModel) {
    this.event_service.loadingStart();

    this.invoice_service.CreateInvoices(invoice.id).subscribe(
      data => {
        this.event_service.loadingFinish();
        saveAs(data.url, "entrata_invoices_" + invoice.invoice_number + ".csv");
      },
      err => {
        this.event_service.loadingFinish();
      });
  }
  createEntrataFiles(invoice: InvoiceModel) {
    this.event_service.loadingStart();

    this.invoice_service.CreateEntrataFiles(invoice.id).subscribe(
      data => {
        this.event_service.loadingFinish();
        saveAs(data.url, "entrata_files_" + invoice.invoice_number + ".zip");
      },
      err => {
        this.event_service.loadingFinish();
      });
  }

  public download_entrata_files(dataItem: InvoiceModel): void {
    this.createEntrataFiles(dataItem);
  }

  public select_entrata_download_type(item: string, dataItem: InvoiceModel) {
    switch (item) {
      case "All":
        this.createEntrataFiles(dataItem);
        break;
      case "Journal":
        this.createJournals(dataItem);
        break;
      case "Defaults":
        this.createDefaults(dataItem);
        break;
      case "Invoice":
        this.createInvoices(dataItem);
        break;


      default:
        break;
    }
  }

  exportBill(file_model: ProviderBillFileModel) {
    this.StartSpinner();

    if(file_model.id && file_model.file)
    {
      this.invoice_service.DownloadExport(file_model.file).subscribe(
        data => {
          FileSaver.saveAs(data, file_model.file.file_name);
          this.StopSpinner();
        },
        err => {
          this.StopSpinner();
        });
    }
  }

  downloadBills(invoice: InvoiceModel) {
    this.event_service.loadingStart();
    this.invoice_service.DownloadPDFs(invoice.id,true).subscribe(
      data => {
        this.download_invoice_ids.push(invoice.id);
        if(data) saveAs(data.url, data.file_name);
        this.event_service.loadingFinish();
      },
      err => {
        this.event_service.loadingFinish();
      });
  }

  downloadBillItem(invoice_item: InvoiceItemSummary) {
    this.event_service.loadingStart();
    this.invoice_service.DownloadPDF(invoice_item.invoice_item_id).subscribe(
      data => {
        this.download_invoice_ids.push(invoice_item.invoice_item_id);
        if(data) saveAs(data.url, data.file_name);
        this.event_service.loadingFinish();
      },
      err => {
        this.event_service.loadingFinish();
      });
  }
}
