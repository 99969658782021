import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomerGroupModel } from 'app/models/customer-group.model';
import { CustomerModel } from 'app/models/customer.model';
import { EmailModel } from 'app/models/email.model';
import { InvoiceItemModel } from 'app/models/invoice-item.model';
import { InvoiceModel } from 'app/models/invoices.model';
import { PhoneCallModel } from 'app/models/phone-call.model';
import { SmsMessageModel } from 'app/models/sms-message.model';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpClient, private config: ConfigService) { }

  public GetCustomer(customer_id: string): Observable<CustomerModel> {
    return this.http.get<CustomerModel>(this.config.getApiRoute('api/v3/client-portal/customer/' + customer_id)).pipe(
      map(data => {
        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err.error.message);
      }));
  }
  public GetCustomerEmails(customer_id: string): Observable<Array<EmailModel>> {
    return this.http.get<Array<EmailModel>>(this.config.getApiRoute('api/v3/client-portal/customer/' + customer_id+'/emails')).pipe(
      map(data => {
        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err.error.message);
      }));
  }
  public GetCustomerPhoneCalls(customer_id: string): Observable<Array<PhoneCallModel>> {
    return this.http.get<Array<PhoneCallModel>>(this.config.getApiRoute('api/v3/client-portal/customer/' + customer_id+'/phone-calls')).pipe(
      map(data => {
        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err.error.message);
      }));
  }
  public GetCustomerSmsMessages(customer_id: string): Observable<Array<SmsMessageModel>> {
    return this.http.get<Array<SmsMessageModel>>(this.config.getApiRoute('api/v3/client-portal/customer/' + customer_id+'/sms-messages')).pipe(
      map(data => {
        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err.error.message);
      }));
  }

  public GetCustomerGroups(customer_id: string): Observable<Array<CustomerGroupModel>> {
    return this.http.get<Array<CustomerGroupModel>>(this.config.getApiRoute('api/v3/client-portal/customer/' + customer_id+'/groups')).pipe(
      map(data => {
        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err.error.message);
      }));
  }

  public GetCustomerInvoices(customer_id: string): Observable<Array<InvoiceModel>> {
    return this.http.get<Array<InvoiceModel>>(this.config.getApiRoute('api/v3/client-portal/customer/' + customer_id+'/invoices')).pipe(
      map(data => {
        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err.error.message);
      }));
  }

  public GetCustomerInvoiceItems(customer_id: string): Observable<Array<InvoiceItemModel>> {
    return this.http.get<Array<InvoiceItemModel>>(this.config.getApiRoute('api/v3/client-portal/customer/' + customer_id+'/invoice-items')).pipe(
      map(data => {
        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err.error.message);
      }));
  }


}