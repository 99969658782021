<app-page-header title="Resident Account"></app-page-header>

<div class="row">
    <div class="col-lg-6 offset-lg-3">
        <div class="card">
            <div class="card-header" *ngIf="resident_model.id">Edit Resident Account</div>
            <div class="card-header" *ngIf="!resident_model.id">Create Resident Account</div>
            <div class="card-body">
                <form [formGroup]="groups_search_form" (ngSubmit)="groupsSearch()">
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label" [for]="groupSearchTerm">Address Search:</label>
                        <div class="col-sm-9">
                            <div class="input-group">
                                <input class="form-control" #groupSearchTerm kendoTextBox formControlName="search" />
                                <div class="input-group-append">
                                    <button class="btn btn-primary" type="submit"
                                        [disabled]="!groups_search_form.valid">Search</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-9 offset-sm-3">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="include-customers-checkbox" formControlName="include_customers" />
                                <label class="form-check-label" for="include-customers-checkbox">Include Residents in Search</label>
                            </div>
                        </div>
                    </div>

                    <nexus-form-result [model]="groups_search_result"></nexus-form-result>
                </form>

                <form [formGroup]="resident_form" (ngSubmit)="submit($event)">
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Selected Address:</label>
                        <div class="col-sm-9">
                            <div class="alert alert-warning" role="alert" *ngIf="groups_search_model?.length == 0">
                                No Addresses Found
                            </div>
                            <div *ngIf="groups_search_model?.length > 0">
                                <div *ngFor="let group of groups_search_model | slice: (current_page-1) * pageSize : current_page * pageSize" class="group-search-result">
                                    <label [attr.for]="'group-radio'+group.id">
                                        <input class="mr-1" formControlName="group_id" type="radio" [value]="group.id"
                                            [attr.id]="'group-radio'+group.id" />
                                        <strong>
                                            <span> {{ formatAddress(group)}}</span>
                                            <ng-container *ngIf="group.current_transition as transition">
                                                <br />{{ transition.group_transition_type?.status }} on
                                                {{ transition.transition_date | amDateFormat:'M/D/YYYY' }}
                                            </ng-container>
                                        </strong><br />
                                        <ul *ngIf="group.customers">
                                            <li *ngFor="let customer of group.customers">
                                                <span>{{ customer.customer.first_name }}
                                                    {{ customer.customer.last_name }}</span>&nbsp;&nbsp;&nbsp;
                                                <span>{{ customer.start_date | amDateFormat:'M/D/YYYY' }}</span>&nbsp;-&nbsp;<span>{{ customer.end_date | amDateFormat:'M/D/YYYY' }}</span>
                                            </li>
                                        </ul>
                                    </label>
                                </div>
                                <ngb-pagination [collectionSize]="groups_search_model?.length" [(page)]="current_page"
                                [pageSize]="pageSize" [maxSize]="5"></ngb-pagination>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label" [for]="firstName">First Name:</label>
                        <div class="col-sm-9">
                            <input class="form-control" #firstName kendoTextBox formControlName="first_name" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label" [for]="lastName">Last Name:</label>
                        <div class="col-sm-9">
                            <input class="form-control" #lastName kendoTextBox formControlName="last_name" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label" [for]="email">E-Mail:</label>
                        <div class="col-sm-9">
                            <input class="form-control" type="email" #email kendoTextBox formControlName="email" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label" [for]="phone">Phone:</label>
                        <div class="col-sm-9">
                            <kendo-maskedtextbox class="form-control" #phone [mask]="'(999) 000-0000'"
                                formControlName="phone"></kendo-maskedtextbox>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label font-italic" [for]="secondaryEmail">Secondary
                            E-Mail:</label>
                        <div class="col-sm-9">
                            <input class="form-control" type="email" #secondaryEmail kendoTextBox
                                formControlName="secondary_email" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label font-italic" [for]="secondaryPhone">Secondary
                            Phone:</label>
                        <div class="col-sm-9">
                            <kendo-maskedtextbox class="form-control" #secondaryPhone [mask]="'(999) 000-0000'"
                                formControlName="secondary_phone"></kendo-maskedtextbox>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Birth Date:</label>
                        <div class="col-sm-9">
                            <kendo-datepicker class="form-control" [format]="'MM/dd/yyyy'" formControlName="birth_date">
                            </kendo-datepicker>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Credit Reporting:</label>
                        <div class="col-sm-9">
                            <kendo-switch id="credit-reporting-input" formControlName="credit_reporting_enabled">
                            </kendo-switch>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Lease Start Date:</label>
                        <div class="col-sm-9">
                            <kendo-datepicker class="form-control" [format]="'MM/dd/yyyy'" formControlName="start_date">
                            </kendo-datepicker>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label">Lease End Date:</label>
                        <div class="col-sm-9">
                            <kendo-datepicker class="form-control" [format]="'MM/dd/yyyy'" formControlName="end_date">
                            </kendo-datepicker>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-9 offset-sm-3">
                            <button kendoButton [primary]="true" [icon]="'check'" type="submit"
                                [disabled]="!resident_form.valid">Submit</button>
                        </div>
                    </div>
                    <nexus-form-result [model]="resident_result"></nexus-form-result>
                </form>
            </div>
        </div>
    </div>
    <div class="col-lg-6 d-none">
        <div class="card" *ngIf="resident_model.id">
            <div class="card-header">Account Options</div>
            <div class="card-body">
                <p><button kendoButton [icon]="'reload'" [primary]="true" (click)="sendRecovery()">Send Password
                        Reset</button></p>
                <nexus-form-result [model]="recovery_result"></nexus-form-result>
            </div>
        </div>
    </div>
</div>
<p>
    <a [routerLink]="['/residents']">Back</a>
</p>