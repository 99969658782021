import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CreateGroupGetModel } from '../models/create-group.model';
import { GroupService } from '../services/group.service';

@Injectable()
export class CreateGroupResolver implements Resolve<CreateGroupGetModel> {

    constructor(private service: GroupService) 
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<CreateGroupGetModel> {
        return this.service.GetCreateGroup();
    }
}
