import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { OnDemandEFBModel } from 'app/models/on-demand-efb.model';
import { OnDemandEFBsService } from 'app/services/on-demand-efbs.service';
import { Observable, of } from 'rxjs';

@Injectable()
export class OnDemandEFBsResolver implements Resolve<Array<OnDemandEFBModel>> {
  constructor(private service: OnDemandEFBsService) 
  {

  }

  resolve(route: ActivatedRouteSnapshot): Observable<Array<OnDemandEFBModel>> {
    return this.service.GetOnDemandEFBs();
  }
}
