<div class="app-sub-header navbar navbar-expand-lg navbar-light bg-light" *ngIf="properties_model?.length > 1 && show_property_context">
    <a class="navbar-brand">Current View</a>
    <button class="navbar-toggler" type="button" aria-controls="properties-sub-nav" aria-expanded="false" aria-label="Toggle navigation" (click)="isCollapsed = !isCollapsed">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="properties-sub-nav" [ngbCollapse]="isCollapsed">
        <ul class="navbar-nav mr-auto">     
            <li class="nav-item dropdown" ngbDropdown>
                <a class="nav-link dropdown-toggle" id="properties-sub-nav-dropdown" role="button" ngbDropdownToggle aria-haspopup="true" aria-expanded="false">
                    {{ session?.current_property?.name }}
                </a>
                <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="properties-sub-nav-dropdown">
                    <div style="padding:0px 10px 0px 10px;" *ngIf="properties_model?.length > 10">
                        <input type="text" class="form-control" placeholder="Search" [(ngModel)]="search_property" (ngModelChange)="searchProperty()"/>
                    </div>
                    <a class="dropdown-item" (click)="setCurrentProperty(property)" *ngFor="let property of properties_list_model">{{ property.name }}</a>
                </div>
            </li>
        </ul>
    </div>
</div>
