import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { InvoiceService } from '../services/invoice.service';
import { InvoicesModel } from '../models/invoices.model';

@Injectable()
export class GroupInvoicesResolver implements Resolve<InvoicesModel> {

    constructor(private service: InvoiceService) 
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<InvoicesModel> {
        return this.service.GetGroupInvoices(route.parent.params.group_id);
    }
}
