import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileModel } from 'app/models/file.model';
import { ConfigService } from 'app/services/config.service';
import { Observable, throwError } from 'rxjs';
import { catchError,map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(private http: HttpClient,private config:ConfigService) { }

  public GetPublicFilePath(file_id: string, user_id: string = null): string{
    if (user_id) {
      return this.config.getApiRoute(`api/v3/common/files/${file_id}/${user_id}`);
    }
    else {
      return this.config.getApiRoute(`api/v3/common/files/${file_id}`);
    }
  }
}
