import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { CustomerService } from 'app/services/customer.service';
import { EmailModel } from 'app/models/email.model';

@Injectable()
export class CustomerEmailsResolver implements Resolve<Array<EmailModel>> {

    constructor(private service: CustomerService, private router: Router) 
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<Array<EmailModel>> {
        return this.service.GetCustomerEmails(route.parent.params.id);
    }
}