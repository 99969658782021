import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerModel } from 'app/models/customer.model';
import { PhoneCallModel } from 'app/models/phone-call.model';

@Component({
  selector: 'app-customer-phone-calls',
  templateUrl: './customer-phone-calls.component.html',
  styleUrls: ['./customer-phone-calls.component.scss']
})
export class CustomerPhoneCallsComponent implements OnInit {

  public phone_calls_model: Array<PhoneCallModel>
  public customer_model: CustomerModel
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.parent.data.subscribe(({ customer_model }) => {
      this.customer_model = customer_model;
      
    });
    this.route.data.subscribe(({ phone_calls_model }) => {
      this.phone_calls_model = phone_calls_model;
    });
  }

}
