import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faChevronLeft, faChevronRight, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { RowClassArgs, SelectionEvent } from '@progress/kendo-angular-grid';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { EventService } from 'app/core/services/event.service';
import { GroupModel } from 'app/models/group.model';
import { InvoiceTypeArgumentModel } from 'app/models/invoice-type-argument.model';
import { InvoiceModel, InvoicesModel } from 'app/models/invoices.model';
import { ResidentInvoicesSearchArgument } from 'app/models/resident-invoices.model';
import { ConfigService } from 'app/services/config.service';
import { InvoiceService } from 'app/services/invoice.service';
import moment from 'moment';
import { NexusGridComponent } from '../../../../core/components/nexus-grid/nexus-grid.component';
import {saveAs} from 'file-saver';
interface Item {
  text: string,
  value: number
}
@Component({
  selector: 'app-group-invoices',
  templateUrl: './group-invoices.component.html',
  styleUrls: ['./group-invoices.component.scss']
})
export class GroupInvoicesComponent extends NexusComponent  {

  public invoice_type:string = "group";
  public months: Array<Item> = [
    { text: "January", value: 1 },
    { text: "February", value: 2 },
    { text: "March", value: 3 },
    { text: "April", value: 4 },
    { text: "May", value: 5 },
    { text: "June", value: 6 },
    { text: "July", value: 7 },
    { text: "August", value: 8 },
    { text: "September", value: 9 },
    { text: "October", value: 10 },
    { text: "November", value: 11 },
    { text: "December", value: 12 }
  ];
  public billing_period_date: Date;
  public invoices_model: InvoicesModel;
  public current_invoice: InvoiceModel;
  public group_model: GroupModel;
  public group_invoices_search_argument: ResidentInvoicesSearchArgument = new ResidentInvoicesSearchArgument();
  public download_invoice_ids: Array<String> = new Array<String>();

  public nav_position: string;
  @ViewChild('invoiceGrid') invoice_grid: NexusGridComponent;
  public chevronLeft = faChevronLeft;
  public chevronRight = faChevronRight;
  public fileDownload = faFileDownload;

  public billing_period_search_form = new UntypedFormGroup({
    billing_month: this.CreateRequiredFormControl(),
    billing_year: this.CreateRequiredFormControl()
  });
  constructor(private config: ConfigService,private route: ActivatedRoute, private router: Router,protected event_service:EventService,  private invoice_service: InvoiceService) {
    super();
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.setFormData();
  }
  private setFormData() {
    this.billing_period_search_form.reset();
    this.group_invoices_search_argument.billing_month = this.config.getCurrentMoment().month() + 1;
    this.group_invoices_search_argument.billing_year = this.config.getCurrentMoment().year();
    this.billing_period_date = moment([this.group_invoices_search_argument.billing_year, this.group_invoices_search_argument.billing_month - 1, 1]).toDate();
    this.billing_period_search_form.patchValue(this.group_invoices_search_argument);
    this.billing_period_search_form.valueChanges.subscribe((form: any) => {
      this.PopulateModelFromForm(form, this.group_invoices_search_argument);
    });
}
ngOnInit(): void {
  this.route.data.subscribe(({ invoice_model }) => {
    this.invoices_model = invoice_model;
});
this.route.parent.data.subscribe(({ group_model }) => {
  this.group_model = group_model;

});
  this.setFormData();

  setTimeout(() => {
    this.billingPeriodSearchSubmit();
  }, 1000);

}

  billingPeriodSearchSubmit() {
    let selected_month: number = this.billing_period_search_form.get('billing_month').value;
    let selected_year: number = this.billing_period_search_form.get('billing_year').value;
    let selected_billing_period_date: Date = moment([selected_year, selected_month - 1, 1]).toDate();
    let model: InvoiceTypeArgumentModel = new InvoiceTypeArgumentModel();
    this.billing_period_date = selected_billing_period_date
    model.current_date = selected_billing_period_date;
    model.invoice_type = this.invoice_type;
    model.group_id = this.group_model.id;
    model.get_item_summaries = true;
    this.event_service.loadingStart();

    this.invoice_grid.data = [];
    this.invoices_model.invoices = [];
    this.current_invoice = null;
    this.nav_position = "";

    this.invoice_service.SearchResidentInvoices(model).subscribe(
      data => {
        this.invoices_model = data;
        this.invoice_grid.data = this.invoices_model.invoices;
        this.event_service.loadingFinish();

        setTimeout(() => {
          this.selectNext();
        }, 250);
      },
      err => {
        this.event_service.loadingFinish();
      });
  }


  public selectionChange(sender: NexusGridComponent, e: SelectionEvent) {
    if (e?.selectedRows?.length) {
      this.current_invoice = e.selectedRows[0].dataItem;
      this.nav_position = (e.selectedRows[0].index + 1) + " of " + this.invoices_model.invoices.length;
    }
    else {
      this.current_invoice = null;
      this.nav_position = "";
    }
  }


  public selectNext(next_group: boolean = false) {
    var select_index = 0;

    if (this.invoices_model) {
      var current_index = this.invoice_grid.grid_data.data.indexOf(this.current_invoice);

      select_index = current_index + 1;
      if (select_index >= this.invoice_grid.grid_data.data.length)
        select_index = 0;
    }

    let element: HTMLElement = this.invoice_grid.grid.wrapper.nativeElement.getElementsByTagName('tr')[select_index + 1].getElementsByTagName('td')[0] as HTMLElement;
    element.click();
  }
  public selectPrevious() {
    var select_index = 0;

    if (this.current_invoice) {
      var current_index = this.invoice_grid.grid_data.data.indexOf(this.current_invoice);
      select_index = (current_index - 1);
      if (select_index < 0)
        select_index = (this.invoice_grid.grid_data.total - 1);
    }

    let element: HTMLElement = this.invoice_grid.grid.wrapper.nativeElement.getElementsByTagName('tr')[select_index + 1].getElementsByTagName('td')[0] as HTMLElement;
    element.click();
  }

  downloadBills(invoice: InvoiceModel) {
    this.event_service.loadingStart();
    this.invoice_service.DownloadPDFs(invoice.id,false).subscribe(
      data => {
        this.download_invoice_ids.push(invoice.id);
        saveAs(data.url, data.file_name);
        this.event_service.loadingFinish();
      },
      err => {
        this.event_service.loadingFinish();
      });
  }
}
