<app-page-header [title]="resource_model.title"></app-page-header>

<div class="container mt-5" *ngIf="resource_model.resource_type ==='Article'">
  <div class="justify-content-center mb-3" [innerHTML]="resource_model.content | safeHtml">  </div>
</div>

<div class="container text-center mt-5" *ngIf="resource_model.resource_type ==='Image'">
  <div class="justify-content-center mb-3">
    <img [src]="resource_model.file_image_file_id?.url" class="img-fluid"/>
  </div>
</div>

<div class="container text-center mt-5" *ngIf="resource_model.resource_type ==='Video'">
  <div class="justify-content-center mb-3" [innerHTML]="resource_model.embed_html | safeHtml">
  </div>
</div>


