<nexus-grid [data]="phone_calls_model" [sort]="[{ 'field': 'created_date', 'dir': 'desc' }]">
    <kendo-grid-column field="complete_date" title="Date Called" format="G" filter="date"></kendo-grid-column>
    <kendo-grid-column field="call_status" title="Call Status"></kendo-grid-column>
    <kendo-grid-column field="twilio_number.friendly_number" title="From Number"></kendo-grid-column>
    <kendo-grid-column field="number_to" title="To Number">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.number_to | phone }}
        </ng-template>
    </kendo-grid-column>
  </nexus-grid>
  
