/*
        This error handler exists in multiple projects.  To maintain continuity, ensure that each
        of the following projects are similarly updated.

        Projects:
             - Resident-Portal
             - Client-Portal
             - Nexus-Client
*/
import * as Sentry from "@sentry/browser";
import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from "@angular/common/http";

@Injectable()
export class SentryErrorHandler implements ErrorHandler {

  constructor() {}

  extractError(error) {
    // Try to unwrap zone.js error.
    // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
    if (error && error.ngOriginalError) {
      error = error.ngOriginalError;
    }

    // We can handle messages and Error objects directly.
    if (typeof error === "string" || error instanceof Error) {
      return error;
    }

    // If it's http module error, extract as much information from it as we can.
    if (error instanceof HttpErrorResponse) {
      // The `error` property of http exception can be either an `Error` object, which we can use directly...
      if (error.error instanceof Error) {
        return error.error;
      }

      // ... or an`ErrorEvent`, which can provide us with the message but no stack...
      if (error.error instanceof ErrorEvent) {
        return error.error.message;
      }

      // ...or the request body itself, which we can use as a message instead.
      if (typeof error.error === "string") {
        return `Server returned code ${error.status} with body "${error.error}"`;
      }

      // If we don't have any detailed information, fallback to the request message itself.
      return error.message;
    }

    // Skip if there's no error, and let user decide what to do with it.
    return null;
  }

  handleError(error) {
    const extractedError = this.extractError(error) || "Handled unknown error";

    // Capture handled exception and send it to Sentry.
    const eventId = Sentry.captureException(extractedError);

    console.error(extractedError);

  }
}

/* Testing notes:
 To test the integration and filtering behaviour. Hookup the below code snippets
 to UI buttons
        throw('This is a silent error test!');//<-should be filtered

        throw('This is a error test!');//should not be filtered

        setTimeout(function(){throw('This is a silent error test! that bypasses the filter');},0);//should be filtered, but was slipping through in the older integration.

        //this is captured via Sentry API wrappers and not angular ErrorHandler
        return this.http.get("test_error.not_existing")
       .pipe(function(){
          throw("error test XMLHttpRequest");//not filtered
          }
        );

       return this.http.get("test_error.not_existing")
       .pipe(function(){
          throw("This is a silent error test!");//filtered
          }
        );

        window.acquire.push(null);//<-make aquire throw an exception, should be filtered
*/
