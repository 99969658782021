import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { EventService } from '../../core/services/event.service';
import { DashboardService } from '../../services/dashboard.service';
import { UserModel } from 'app/models/user.model';

@Component({
    selector: 'app-booking',
    templateUrl: './booking.component.html',
    styleUrls: ['./booking.component.scss']
})
export class BookingComponent implements OnInit {      
    public booking_url : string;
   
    constructor(private route: ActivatedRoute, private router: Router,  private event_service: EventService, private dashboard_service: DashboardService) { 

    } 
    ngOnInit() {
        this.route.params.subscribe((params: Params) => this.booking_url = params['booking_url']);
    }
}
