import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceModel } from 'app/models/resource.model';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {

  constructor(private http: HttpClient, private config: ConfigService) { }

  public GetResources(): Observable<Array<ResourceModel>> {
    return this.http.get<Array<ResourceModel>>(this.config.getApiRoute('api/v3/client-portal/resources')).pipe(
      map(data => data),
      catchError((err: HttpErrorResponse) => throwError(err.error.message))
    );
  }

  public GetResource(id: string): Observable<ResourceModel> {
    return this.http.get<ResourceModel>(this.config.getApiRoute(`api/v3/client-portal/resources/${id}`)).pipe(
      map(data => data),
      catchError((err: HttpErrorResponse) => throwError(err.error.message))
    );
  }
}
