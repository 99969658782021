import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { CustomerModel } from 'app/models/customer.model';

@Component({
  selector: 'customer-information',
  templateUrl: './customer-information.component.html',
  styleUrls: ['./customer-information.component.scss']
})
export class CustomerInformationComponent extends NexusComponent {
  public customer_model: CustomerModel
  constructor(private route: ActivatedRoute) {
    super(); 
  }
  
  public form = new UntypedFormGroup({
    first_name: this.CreateRequiredFormControl(),
    last_name: this.CreateRequiredFormControl(),
    email: this.CreateRequiredFormControl(),
    secondary_email: this.CreateFormControl(),
    phone: this.CreateFormControl(),
    secondary_phone: this.CreateFormControl(),
    birth_date: this.CreateFormControl(),
  });

  ngOnInit(): void {

    this.route.parent.data.subscribe(({ customer_model }) => {
      this.customer_model = customer_model;
    this.form.patchValue(this.customer_model);
    });
    this.form.valueChanges.subscribe((form_data: any) => {
      this.PopulateModelFromForm(form_data, this.customer_model);
    });
   this.form.disable()
  }

}
