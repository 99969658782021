import { PropertySelectorChoice } from './../../models/report-embed.model';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MembershipService } from 'app/core/services/membership.service';
import { SessionModel } from 'app/models/session.model';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import { TelerikReportViewerComponent } from '@progress/telerik-angular-report-viewer';
import { QuickSightEmbedModel, ReportEmbedModel, TelerikEmbedModel } from 'app/models/report-embed.model';
import { FormControl, FormGroup } from '@angular/forms';
import { ReportEmbedService } from 'app/services/report-embed.service';
import { faLowVision } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss']
})

export class ReportComponent extends NexusComponent {
    private viewer: TelerikReportViewerComponent;
    public search_property:string;
    public properties_list_model: Array<PropertySelectorChoice>;

    @ViewChild('viewer1') set content(content: TelerikReportViewerComponent) {
      if(content) { // initially setter gets called with undefined
           this.viewer = content;
      }
   }
    public viewerContainerStyle = {
      position: 'relative',
      width: '100%',
      height: '800px'
    };  

    public session: SessionModel = null;
    hidden:boolean=false;
    loaded:boolean=false;
    access_denied:boolean=false;
    report_unavailable:boolean=false;
    qs_dashboard:any;
    report_title: string;
    qs_embed:QuickSightEmbedModel;
    t_embed:TelerikEmbedModel;

    public parameters: any= {
        propertyid: '[ALL]'
    };
    public faLowVision = faLowVision;

    constructor(private route: ActivatedRoute, private router: Router, public membership: MembershipService, private service: ReportEmbedService) {
        super();
        this.event_service.on('session-start', (model) => {
            this.session = model;
        });
        this.event_service.on('session-end', () => {
            this.session = null;
        });
    }

    ngOnInit() {
        this.membership.GetSession().subscribe(
            data => {
                this.session = data;
            },
            error => {
                this.session = null;
            });

        this.route.data.subscribe(({ report_embed_model }) => {
            this.embed(report_embed_model);
        });
    }

    embed(data:ReportEmbedModel){
        this.report_title = data.name;
        this.hidden = data.hidden;
        this.access_denied=data.access_denied;
        this.report_unavailable=data.report_unavailable;
        this.loaded = true;
        if(data.quicksight_embed){
          this.qs_embed=data.quicksight_embed;
          this.showQSReport();
        }
        if(data.telerik_embed){
          this.t_embed=data.telerik_embed;
          if(this.t_embed.propertySelectorChoices?.length>0){
            this.t_embed.propertySelectorChoices.sort((a,b)=>{ return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);});
            this.properties_list_model=this.t_embed.propertySelectorChoices;
          }
          this.showTReport();
        }
      }

      showQSReport(){
        if(this.qs_embed.embed_url){
          var options = {
            url: this.qs_embed.embed_url,
            container: "#dashboardContainer",
            scrolling: "no",
            width:"100%",
            height: "AutoFit",
            loadingHeight: "800px",
            locale: "en-US",
            footerPaddingEnabled: true,
            printEnabled: true,
            parameters: this.parameters
          };
    
          this.qs_dashboard = QuickSightEmbedding.embedDashboard(options);
        }
      }
    
      showTReport(){
        if(!this.t_embed.propertyId)
             this.t_embed.propertyId=null;
      }
    
      form_once=false;
      Tlocalization=()=> {
        //console.log(this.viewer) // exposes reportviewer objects
        if (this.viewer)
        {
            if(!this.form_once){
                this.form_once=true;
                this.viewer.viewerObject.stringResources = Object.assign(this.viewer.viewerObject.stringResources, loadingStatus.SimpleBills);
            }
        } 
      }

      TSelectedProperty=()=> {
        return this.t_embed.propertySelectorChoices.filter(obj => {
        return obj.propertyId === this.t_embed.propertyId
        })[0];
      }

  THandler=(e:any,args:any)=>{
    if(e.type="ERROR" && e.target.stringResources.missingOrInvalidParameter==args){
        if(this.t_embed.showPropertySelector){
            if(document.getElementsByClassName("trv-error-message") && document.getElementsByClassName("trv-error-message")[0]){
                document.getElementsByClassName("trv-error-message")[0].innerHTML="Select a Property & other criteria needed ➞";
            }
           
        }
      }
  }
  propertyid: string = '';
  checkSecurity() {
    if (typeof this.propertyid=='undefined' || this.propertyid==''){
      this.qs_dashboard.setParameters({propertyid:'[ALL]'});
    }else{
      this.qs_dashboard.setParameters({propertyid:this.propertyid});
    }
  }

  setCurrentProperty(property_id: string) {
    var rs=this.viewer.getReportSource();
    //console.log(JSON.stringify(rs));
    if(rs.parameters.PropertyId!=property_id){
      this.t_embed.propertyId=property_id;
      rs.parameters.PropertyId=property_id;
      this.viewer.setReportSource(rs);
    }
  }
  searchProperty(){
    let text = this.search_property;
    this.properties_list_model=   this.t_embed.propertySelectorChoices.filter((val) => val.name.toLowerCase().includes(text.toLowerCase()));
  }
}



export class loadingStatus {
    // override string messages here
    static SimpleBills = {
        loadingReport: "Loading while the satellite moves into position",
        loadingReportPagesInProgress: "Loading... at least you're not on hold",
        loadedReportPagesComplete: "Crushed it! {0} pages loaded.",
        missingOrInvalidParameter: "We’ll do the heavy lifting on this report once you’ve selected all the criteria you need →"
    }
}