import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { GroupService } from '../services/group.service';
import { GroupsModel } from '../models/groups.model';

@Injectable()
export class GroupsResolver implements Resolve<GroupsModel> {

    constructor(private service: GroupService) 
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<GroupsModel> {
        return this.service.GetGroups();
    }
}
