<section id="pre-footer">
    <div class="container text-center">
      <p class="mt-5 mb-3">
        <img src="/assets/logo-green.png" />
      </p>
      <div class="d-flex justify-content-center mb-3">
        <ul class="nav">
          <li class="nav-item">
            <a class="nav-link" href="https://www.simplebills.com/properties">For Properties</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://www.simplebills.com/residents">For Residents</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://www.simplebills.com/about">About Us</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://careers-realpage.icims.com/jobs/search?ss=1&searchRelation=keyword_all&searchLocation=-12827-Woodway&mobile=false&width=1110&height=500&bga=true&needsRedirect=false&jan1offset=-360&jun1offset=-300">Careers</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://www.simplebills.com/support">Support</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://www.simplebills.com/credit-reporting">Credit Reporting</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://simplebills.zendesk.com/hc/en-us">Help Center</a>
          </li>
        </ul>
      </div>
      <hr />
      <div class="d-flex justify-content-center mb-4 mt-2">
        <ul class="nav">
          <li class="nav-item">
            <a href="https://www.facebook.com/Simplebills" target="_blank" class="social-link">
              <fa-icon [icon]="faFacebookF" [fixedWidth]="true"></fa-icon>
            </a>
          </li>
  
          <li class="nav-item">
            <a href="https://instagram.com/simplebills/" target="_blank" class="social-link">
              <fa-icon [icon]="faInstagram" [fixedWidth]="true"></fa-icon>
            </a>
          </li>
  
          <li class="nav-item">
            <a href="https://twitter.com/SimpleBillsCorp" target="_blank" class="social-link">
              <fa-icon [icon]="faTwitter" [fixedWidth]="true"></fa-icon>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
  <footer>
    <div class="container text-center">
      <p class="m-0">
        <a href="https://www.realpage.com/privacy-policy/">Privacy Policy</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a
          href="http://www.simplebills.com/terms-of-service">Terms of Service</a>&nbsp;&nbsp;|&nbsp;&nbsp;&copy; {{currentYear}} SimpleBills, a RealPage Company. All rights reserved.
      </p>
    </div>
  </footer>
