import { HttpClient, HttpErrorResponse , HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OnDemandEFBChargeModel, OnDemandEFBModel } from 'app/models/on-demand-efb.model';
import { MembershipService } from 'app/core/services/membership.service';
import { PropertyService } from 'app/core/services/property.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ConfigService } from './config.service';

@Injectable()
export class OnDemandEFBsService {

  constructor(private http: HttpClient, private config: ConfigService, public membership: MembershipService, private property_service: PropertyService) { }

  public GetOnDemandEFBs(): Observable<Array<OnDemandEFBModel>> {
    return this.http.get<Array<OnDemandEFBModel>>(this.config.getApiRoute('api/v3/client-portal/on-demand-efbs/')).pipe(
      map(data => {
          return data;
      }),
      catchError((err: HttpErrorResponse) => {
          return throwError(err.error.message);
      }));
  }

  public GetPropertyOnDemandEFBs(property_id:string): Observable<Array<OnDemandEFBModel>> {
    this.property_service.GetProperties()
        .subscribe((properties:any)=>{
            let property = properties.filter(item => item.id == property_id)[0];
            this.membership.SetProperty(property);
    });
    
    return this.http.get<Array<OnDemandEFBModel>>(this.config.getApiRoute('api/v3/client-portal/on-demand-efbs/'+ property_id)).pipe(
      map(data => {
          return data;
      }),
      catchError((err: HttpErrorResponse) => {
          return throwError(err.error.message);
      }));
  }

  public ProcessOnDemandEFBCharge(argument: OnDemandEFBChargeModel): Observable<OnDemandEFBChargeModel> {
    return this.http.put<OnDemandEFBChargeModel>(this.config.getApiRoute('api/v3/client-portal/on-demand-efbs/process'), argument).pipe(
      map(data => {
        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err.error.message);
      }));
  }
}
