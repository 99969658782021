export class NexusFormResultModel
{
  public success_message: string;
  public error_message: string;

  public Reset(){
    this.success_message = null;
    this.error_message = null;
  }

  public Incomplete() {
    this.success_message = null;
    this.error_message = "Please complete all required fields.";
  }

  public Success(message: string = '') {
    this.success_message = message;
    if(!this.success_message)
    {
      this.success_message = "Form Successfully Submitted!";
    }
    this.error_message = null;
  }

  public Error(message: string)
  {
    this.success_message = null;
    this.error_message = message;
  }
}