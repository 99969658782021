import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { CustomerService } from 'app/services/customer.service';
import { CustomerGroupModel } from 'app/models/customer-group.model';

@Injectable()
export class CustomerGroupsResolver implements Resolve<Array<CustomerGroupModel>> {

    constructor(private service: CustomerService, private router: Router) 
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<Array<CustomerGroupModel>> {
        return this.service.GetCustomerGroups(route.parent.params.id);
    }
}