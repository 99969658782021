<app-page-header title="Utility Bills"></app-page-header>


<div class="navbar navbar-light bg-light navbar-expand-md">
    <span class="navbar-brand">Summary by Billing Period</span>

    <button class="navbar-toggler" type="button" (click)="summary_filter_collapsed = !summary_filter_collapsed">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" [ngbCollapse]="summary_filter_collapsed">
        <ul class="navbar-nav mr-auto"></ul>

        <form [formGroup]="summary_form" (ngSubmit)="summarySubmit()">
            <kendo-dropdownlist placeholder="Billing Month" formControlName="billing_month" [data]="months"
                textField="text" valueField="value" [valuePrimitive]="true" class="filter-control">
            </kendo-dropdownlist>
            <kendo-numerictextbox placeholder="Billing Year" formControlName="billing_year" [min]="2000" [max]="2050"
                [autoCorrect]="true" [format]="'0'" class="filter-control"></kendo-numerictextbox>
            <button kendoButton [primary]="true" [icon]="'search'" type="submit"
                [disabled]="!summary_form.valid">Search</button>
        </form>
    </div>


</div>

<div class="row card-row">
    <div class="col-lg-4">
        <div class="card">
            <div class="card-header">Overall Summary</div>
            <div class="card-body">
                <div id="utilities-summary-chart"></div>
                <div id="utilities-legend">
                    <div class="row">
                        <div class="col-xs-6 col-md-4 col-lg-6 legend-item"
                            *ngFor="let utilities of utilities_summary_data">
                            <div class="legend-marker" [style.background]="utilities.color"></div>
                            <span class="legend-title">{{utilities.service_category_name}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-8">
        <div class="card">
            <div class="card-header">Summary Details</div>
            <div class="card-body">
              <h4><b>Utility Bills</b></h4>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Status</th>
                    <th scope="col">Total</th>
                    <th scope="col">Resident Total</th>
                    <th scope="col">Allowance</th>
                    <th scope="col">Property Total</th>
                    <th scope="col">Bill Count</th>
                  </tr>
                </thead>
                <tbody *ngFor="let kvp of parseSummaryMap(summary_model.status_summaries | orderBy:'service_category_position' | groupBy: 'provider_name')">

                  <tr>
                    <td colspan="4">
                      <strong>{{ kvp.provider_name }}</strong>
                    </td>
                  </tr>
                   <tr *ngFor="let summary of kvp.summaries | orderBy:'status_name'">

                    <td>{{ summary.status_name }}</td>
                    <td>{{ summary.amount | sbCurrency}}</td>
                    <td>{{ summary.resident_portion | sbCurrency }}</td>
                    <td>{{ summary.allowance | sbCurrency }}</td>
                    <td>{{ summary.property_portion | sbCurrency}}</td>
                    <td>{{ summary.bill_count }}</td>
                  </tr>
                </tbody>
              </table>
              <h4><b>Estimated Final Bills</b></h4>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Status</th>
                    <th scope="col">Total</th>
                    <th scope="col">Resident Total</th>
                    <th scope="col">Allowance</th>
                    <th scope="col">Property Total</th>
                    <th scope="col">Bill Count</th>
                  </tr>
                </thead>
                <tbody *ngFor="let kvp of parseSummaryMap(summary_model.estimated_final_bill_summaries| orderBy:'service_category_position' | groupBy: 'provider_name')">
                  <tr>
                    <td colspan="4">
                      <strong>{{ kvp.provider_name }}</strong>
                    </td>
                  </tr>
                  <tr *ngFor="let summary of kvp.summaries | orderBy:'status_name'">

                    <td>{{ summary.status_name }}</td>
                    <td>{{ summary.amount | sbCurrency}}</td>
                    <td>{{ summary.resident_portion | sbCurrency }}</td>
                    <td>{{ summary.allowance | sbCurrency }}</td>
                    <td>{{ summary.property_portion | sbCurrency}}</td>
                    <td>{{ summary.bill_count }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
        </div>
    </div>
</div>

<nav class="navbar navbar-light bg-light navbar-expand-md">
    <span class="navbar-brand">
        <span *ngIf="bills_start_date">{{ bills_start_date | amDateFormat:'MMMM YYYY' }}</span>
        <span *ngIf="bills_end_date && bills_start_date != bills_end_date"> -
            {{ bills_end_date | amDateFormat:'MMMM YYYY' }}</span>
    </span>

    <button class="navbar-toggler" type="button" (click)="bills_filter_collapsed = !bills_filter_collapsed">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" [ngbCollapse]="bills_filter_collapsed">
        <ul class="navbar-nav mr-auto"></ul>

        <form [formGroup]="bills_form" (ngSubmit)="billsSubmit()">
            <kendo-dropdownlist placeholder="Start Month" formControlName="start_month" [data]="months" textField="text"
                valueField="value" [valuePrimitive]="true" class="filter-control">
            </kendo-dropdownlist>
            <kendo-numerictextbox placeholder="Start Year" formControlName="start_year" [min]="2000" [max]="2050"
                [autoCorrect]="true" [format]="'0'" class="filter-control"></kendo-numerictextbox>
            <span>
                <fa-icon [icon]="faLongArrowAltRight" [fixedWidth]="true"></fa-icon>
            </span>
            <kendo-dropdownlist placeholder="End Month" formControlName="end_month" [data]="months" textField="text"
                valueField="value" [valuePrimitive]="true" class="filter-control"></kendo-dropdownlist>
            <kendo-numerictextbox placeholder="End Year" formControlName="end_year" [min]="2000" [max]="2050"
                [autoCorrect]="true" [format]="'0'" class="filter-control"></kendo-numerictextbox>
            <button kendoButton [primary]="true" [icon]="'search'" type="submit"
                [disabled]="!bills_form.valid">Search</button>
        </form>
    </div>

</nav>

<div class="alert alert-warning" role="alert" *ngIf="bills_error">{{ bills_error }}</div>

<div id="grid-columns-visibility">

</div>

<nexus-grid [data]="bills_model">
  <kendo-grid-column [width]="120" field="provider_name" title="Provider"></kendo-grid-column>
  <kendo-grid-column [width]="120" field="provider_category_name" title="Category"></kendo-grid-column>
  <kendo-grid-column [width]="120" field="summary_account_number" title="Summary Account">
    <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{dataItem.summary_account_description}}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [width]="120" field="account_number" title="Account"></kendo-grid-column>
  <kendo-grid-column [width]="130" field="ledger_code_name" title="Status"></kendo-grid-column>
  <kendo-grid-column [width]="150" field="address1" title="Address 1"></kendo-grid-column>
  <kendo-grid-column [width]="90" field="address2" title="Unit"></kendo-grid-column>
  <kendo-grid-column [width]="100" field="amount" title="Amount" format="{0:c}" [filter]="'numeric'">
  </kendo-grid-column>
  <kendo-grid-column [width]="120" field="statement_date" title="Statement Date" format="{0:MM/dd/yyyy}" filter="date">
  </kendo-grid-column>
  <kendo-grid-column [width]="100" field="start_date" title="Start" format="{0:MM/dd/yyyy}" filter="date">
  </kendo-grid-column>
  <kendo-grid-column [width]="100" field="end_date" title="End" format="{0:MM/dd/yyyy}" filter="date">
  </kendo-grid-column>
  <kendo-grid-column [width]="100" field="paid" title="Paid" [filter]="'boolean'"></kendo-grid-column>
  <kendo-grid-column [width]="100" field="date_due" title="Due" format="{0:MM/dd/yyyy}" filter="date"
                     [hidden]="true"></kendo-grid-column>
  <kendo-grid-column [width]="120" field="resident_portion" title="Resident Portion" format="{0:c}" [filter]="'numeric'"
                     [hidden]="true"></kendo-grid-column>
  <kendo-grid-column [width]="120" field="allowance_portion" title="Allowance Portion" format="{0:c}"
                     [filter]="'numeric'" [hidden]="true"></kendo-grid-column>
  <kendo-grid-column [width]="120" field="property_portion" title="Property Portion" format="{0:c}" [filter]="'numeric'"
                     [hidden]="true"></kendo-grid-column>
  <kendo-grid-column [width]="100" field="source_type_name" title="Bill Type">
  </kendo-grid-column>
  <kendo-grid-column [width]="120" field="notes" title="Notes" [hidden]="true"></kendo-grid-column>
  <!-- <kendo-grid-column [width]="80" title="Invoice">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div *ngFor="let invoice of dataItem.invoices">
        <nexus-invoice-link  [invoice_model]="invoice"></nexus-invoice-link>
      </div>
    </ng-template>
  </kendo-grid-column> -->
  <kendo-grid-column [width]="80" title="Pdf">
    <ng-template kendoGridCellTemplate let-dataItem>
      <button kendoButton *ngIf="(dataItem.provider_bill_file_id || dataItem.provider_bill_charge_file_id) && dataItem.source_type != 'MeterConversion'" [icon]="'pdf'"
              (click)="exportBill(dataItem)"></button>
      <button kendoButton *ngIf="dataItem.source_type == 'MeterConversion'" [icon]="'pdf'"
              (click)="exportBill(dataItem)"></button>
    </ng-template>
  </kendo-grid-column>
</nexus-grid>

<div class="row" style="margin-top: 20px;">
    <div class="col-lg-6">
        <div class="card">
            <div class="card-header">Download All Bill Images</div>
            <div class="card-body">
                <form [formGroup]="download_pdfs_form" (ngSubmit)="downloadPDFs()">
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label text-sm-right font-weight-bold">Start Date</label>
                        <div class="col-sm-8">
                            <kendo-dropdownlist placeholder="Start Month" formControlName="start_month" [data]="months"
                                textField="text" valueField="value" [valuePrimitive]="true" class="filter-control">
                            </kendo-dropdownlist>
                            <kendo-numerictextbox placeholder="Start Year" formControlName="start_year" [min]="2000"
                                [max]="2050" [autoCorrect]="true" [format]="'0'" class="filter-control">
                            </kendo-numerictextbox>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label text-sm-right font-weight-bold">End Date</label>
                        <div class="col-sm-8">
                            <kendo-dropdownlist placeholder="End Month" formControlName="end_month" [data]="months"
                                textField="text" valueField="value" [valuePrimitive]="true" class="filter-control">
                            </kendo-dropdownlist>
                            <kendo-numerictextbox placeholder="End Year" formControlName="end_year" [min]="2000"
                                [max]="2050" [autoCorrect]="true" [format]="'0'" class="filter-control">
                            </kendo-numerictextbox>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label text-sm-right font-weight-bold">Provider</label>
                        <div class="col-sm-8">
                            <kendo-dropdownlist class="form-control provider" [data]="providers" textField="id" valueField="id"
                                formControlName="provider_id" [valuePrimitive]="true">
                                <ng-template kendoDropDownListItemTemplate let-dataItem>
                                    <span
                                        *ngIf="dataItem">{{ dataItem.name }}</span>
                                </ng-template>
                                <ng-template kendoDropDownListValueTemplate let-dataItem>
                                    <span
                                        *ngIf="dataItem">{{ dataItem.name }}</span>
                                </ng-template>
                            </kendo-dropdownlist>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-6 offset-sm-2">
                            <button kendoButton [primary]="false" [icon]="'pdf'" type="submit">Download All</button>
                        </div>
                    </div>
                </form>
                <ngb-alert [type]="'success'" [dismissible]="true" *ngIf="download_pdfs_success">
                    {{ download_pdfs_success }}
                </ngb-alert>
                <ngb-alert [type]="'warning'" [dismissible]="false" *ngIf="download_pdfs_error">
                    {{ download_pdfs_error }}
                </ngb-alert>
            </div>
        </div>
    </div>
</div>
