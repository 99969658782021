import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { ResourceModel } from 'app/models/resource.model';
import { ResourcesService } from 'app/services/resources.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResourcesResolver implements Resolve<Observable<Array<ResourceModel>>> {
  constructor(private service: ResourcesService, private router: Router)
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<Array<ResourceModel>> {
        return this.service.GetResources();
    }
}
