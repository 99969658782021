import { throwError, Observable, from } from 'rxjs';

import { map, catchError, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpInterceptor,
    HttpErrorResponse,
    HttpSentEvent,
    HttpHeaderResponse,
    HttpProgressEvent,
    HttpResponse,
    HttpUserEvent
} from '@angular/common/http';
import { MembershipService } from '../core/services/membership.service';

import { TokenModel } from 'app/models/token.model';
import { ReportService } from 'app/services/report.service';
import { ConfigService } from 'app/services/config.service';
import { Router } from '@angular/router';
import { LocalizationService } from 'app/core/services/localization.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        public service: MembershipService,
        private config: ConfigService,
        private localization_service: LocalizationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        if (!this.config.isApiRoute(request.url)) {
            return next.handle(request);
        }

        return from(this.service.GetToken()).pipe(
            switchMap((token: TokenModel) => {
                if (token && token.token) {
                    if(request.headers.get('isEmailWorkFlow')!= undefined){

                        request = request.clone({
                            setHeaders: {
                                Authorization: `Bearer ${token.token}`,
                            }
                        });
                    }else{

                        request = request.clone({
                            setHeaders: {
                                Authorization: `Bearer ${token.token}`,
                                PropertyId: token.current_property != null ? token.current_property.id : null
                            }
                        });
                    }
                }

                this.localization_service.LocalizeObjectToAPI(request.body);

                return next.handle(request).pipe(map((event: HttpResponse<any>) => {
                    if (event instanceof HttpResponse) {
                        this.localization_service.LocalizeObjectFromAPI(event.body);
                    }
                    return event;
                }), catchError((err: HttpErrorResponse) => {
                    if (err.status == 401) {
                        this.service.ClearToken();
                        this.router.navigateByUrl('/login', { replaceUrl: true });
                    }

                    return throwError(err);
                }));
            })
        );
    }
}