
export class UtilitiesSummaryModel {
    public overall_summaries: Array<UtilitiesServiceCategorySummary>;
    public status_summaries: Array<UtilitiesStatusSummary>;
    public billing_period_date: Date;
    public estimated_final_bill_summaries:Array<UtilitiesStatusSummary>;
    
}


export class UtilitiesServiceCategorySummary {
    public service_category_name: string;
    public amount: number;
    public bill_count: number;
    public color: string;

}


export class UtilitiesStatusSummary {
    public status_name: string;
    public bill_count: number;
    public amount: number;
    public customer_portion: number;
    public property_portion: number;
    public resident_portion: number;
    public allowance: number;

}

export class UtilitiesBillsArgument {
    public start_month: number;
    public start_year: number;
    public end_month: number;
    public end_year: number;
    public provider_id: string;
}

export class UtilitiesSummaryArgument {
    public billing_month: number;
    public billing_year: number;    
}