import { MarketType } from "app/enums/market-type.enum";
import { ResourceType } from "app/enums/resource-type.enum";
import { FileModel } from "./file.model";
import { ResourceCategoryModel } from "./resource-category.model";
import { ResourceImplementationTypeAssignmentModel } from "./resource-implementation-type-assignment.model";
import { ResourceMarketTypeAssignmentModel } from "./resource-market-type-assignment.model";
import { UserModel } from "./user.model";

export class ResourceModel {
  id: string;
  title: string;
  description: string;
  resource_category_id: string;
  thumbnail_file_id: string;
  url: string;
  resource_type: ResourceType;
  image_file_id: string;
  content: string;
  document_file_id: string;
  embed_html: string;
  created_by_user_id: string;
  date_created: string;
  date_updated: string;
  resource_category: ResourceCategoryModel;
  file_thumbnail_file_id: FileModel;
  file_image_file_id: FileModel;
  file_document_file_id: FileModel;
  user: UserModel;
  resource_market_type_assignments: Array<ResourceMarketTypeAssignmentModel>;
  resource_implementation_type_assignments: Array<ResourceImplementationTypeAssignmentModel>;
  market_type_assignments: Array<MarketType>;
  implementation_type_ids: Array<string>;
}
