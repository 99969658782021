import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'nexus-card-header',
  templateUrl: './nexus-card-header.component.html',
  styleUrls: ['./nexus-card-header.component.scss']
})
export class NexusCardHeaderComponent implements OnInit {
  @Input() public card_title: string;

  constructor() { }

  ngOnInit() {
  }

}
