<kendo-dialog *ngIf="property_file_model"  (close)="closeDialog()">
    <kendo-dialog-titlebar>
        Delete File
    </kendo-dialog-titlebar>
    <p style="margin: 30px; text-align: center;">Are you sure you want to delete the Property file?</p>
    <kendo-dialog-actions>
        <button kendoButton (click)="onCancel($event)">No</button>
        <button kendoButton [primary]="true" (click)="onDelete($event)">Yes</button>
    </kendo-dialog-actions>
</kendo-dialog>
