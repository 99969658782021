import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DashboardService } from '../services/dashboard.service';
import { DashboardModel } from '../models/dashboard.model';

@Injectable()
export class DashboardResolver implements Resolve<DashboardModel> {

    constructor(private service: DashboardService) 
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<DashboardModel> {
        return this.service.GetDashboard();
    }
}
