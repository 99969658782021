import { Component, ChangeDetectorRef } from '@angular/core';
import { Router,NavigationStart,NavigationEnd } from '@angular/router';
import { SessionModel } from 'app/models/session.model';
import { TokenPropertyModel } from 'app/models/token-property.model';
import { MembershipService } from 'app/core/services/membership.service';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { PropertyService } from 'app/core/services/property.service';

@Component({
    selector: 'app-sub-header',
    templateUrl: './app-sub-header.component.html',
    styleUrls: ['./app-sub-header.component.scss']

})
export class AppSubHeaderComponent extends NexusComponent {
    public session: SessionModel = null;
    public properties_model: Array<TokenPropertyModel>;
    public properties_list_model: Array<TokenPropertyModel>;
    public isCollapsed = true;
    public search_property:string;
    public show_property_context = true;

    constructor(public membership: MembershipService, private router: Router, private property_service: PropertyService, private cdr: ChangeDetectorRef) {
        super();
        this.event_service.on('session-start', (model) => {
            this.session = model;
            this.loadProperties();
            this.shouldPropertyContextBeShown();
        });
        this.event_service.on('session-end', () => {
            this.session = null;
            this.properties_model = null;
        });
    }

    ngOnInit() {
        this.membership.GetSession().subscribe(
            data => {
                this.session = data;
                if (data) {
                    this.loadProperties();
                    this.shouldPropertyContextBeShown();
                }
            },
            error => {
                this.session = null;
            });

        this.router.events.subscribe((event: any) => {
            if(event instanceof NavigationStart || event instanceof NavigationEnd) {
                this.shouldPropertyContextBeShown();
            }
            });
    }

    shouldPropertyContextBeShown(){
        this.show_property_context = true;
        if( this.session  && this.router.url && 
            (this.router.url.indexOf('/reports') >= 0 || this.router.url.indexOf('/report') >= 0)) {
                this.show_property_context = false;
        }
        this.cdr.detectChanges();
    }

    loadProperties()
    {
        this.StartSpinner();
        this.property_service.GetProperties().subscribe(
            data => {
                this.properties_model = data;
                this.properties_list_model = data;
                this.StopSpinner();
            },
            error => {
                this.properties_model = null;
                this.StopSpinner();
            }
        );
    }

    setCurrentProperty(property: TokenPropertyModel) {
        this.membership.SetProperty(property);
        if (this.router.url) {
            if (this.router.url.indexOf('/utilities') >= 0) {
                this.event_service.propertyChanged(property);
            }
            else if (this.router.url.indexOf('/residents') >= 0) {
                this.router.navigate(['/residents']);
            }
            else if (this.router.url.indexOf('/settings') >= 0) {
                this.router.navigate(['/settings']);
            }
            else if (this.router.url.indexOf('/pre-bill') >= 0) {
                this.router.navigate(['/dashboard']);
            }
            else if (this.router.url.indexOf('/workflows') >= 0)
            {
              this.router.navigate(['/dashboard']);
            }
            else if (this.router.url.indexOf('/groups/') >= 0)
            {
                this.router.navigate(['/groups']);
            }
            else if (this.router.url.indexOf('/files/') >= 0)
            {
                this.router.navigate(['/files']);
            }
            else if (this.router.url.indexOf('/resident-invoice') >= 0) {
                this.router.navigate(['/resident-invoices']);
            }
            else if (this.router.url.indexOf('/property-invoice') >= 0) {
                this.router.navigate(['/property-invoices']);
            }
            else if (this.router.url.indexOf('/customer') >= 0) {
                this.router.navigate(['/residents']);
            }
            else {
                this.router.navigate([this.router.url]);
            }
        }
    }

    searchProperty(){
     let text = this.search_property;
     this.properties_list_model=   this.properties_model.filter((val) => val.name.toLowerCase().includes(text.toLowerCase()));
    }
}
