<nexus-grid [data]="provider_bill_portions_model" [sort]="[{ 'field': 'created_date', 'dir': 'desc' }]">
    <kendo-grid-column field="date_created" title="Created" format="G" filter="date" [width]="130"></kendo-grid-column>
    <kendo-grid-column field="customer.name" title="Resident" [width]="120">
      <ng-template kendoGridCellTemplate let-dataItem>
        <nexus-customer-link [customer_model]="dataItem.customer" [text]="dataItem.customer.name"></nexus-customer-link>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="provider_bill_charge.expected_bill.provider.name" title="Provider" [width]="120">
    </kendo-grid-column>
  
    <kendo-grid-column field="amount" title="Amount" filter="numeric" format="{0:c}" [width]="100"></kendo-grid-column>
    <kendo-grid-column field="billing_period_date" title="Period" format="d" filter="date" [width]="100"></kendo-grid-column>
    <kendo-grid-column field="provider_bill_charge.amount" title="Bill Amount" filter="numeric" format="{0:c}" [width]="120">
    </kendo-grid-column>
    <kendo-grid-column field="provider_bill_charge.resident_portion" title="Resident Portion" filter="numeric" format="{0:c}"
      [width]="120">
    </kendo-grid-column>
    <kendo-grid-column field="provider_bill_charge.property_portion" title="Property Portion" filter="numeric" format="{0:c}"
      [width]="120">
    </kendo-grid-column>
    <kendo-grid-column field="start_date" title="Start" format="d" filter="date" [width]="100"></kendo-grid-column>
    <kendo-grid-column field="end_date" title="End" format="d" filter="date" [width]="100"></kendo-grid-column>
    <kendo-grid-column field="usage_days" title="Days" filter="numeric" [width]="70"></kendo-grid-column>
    <kendo-grid-column field="ledger_code.name" title="Ledger Code" [width]="120"></kendo-grid-column>
  </nexus-grid>
