<app-page-header title="Resident - {{ customer_model?.name}}"></app-page-header>

<ul class="nav nav-pills mb-3 mt-4">
    <li class="nav-item" *ngFor="let item of items">
        <a class="nav-link" [routerLink]="item.route" routerLinkActive="active">{{ item.name }}</a>
    </li>   
</ul>

  <div class="mt-5">
    <router-outlet></router-outlet>
  </div>
