import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { PreBillStepsModel } from 'app/models/pre-bill-step.model';

@Injectable()
export class PreBillStepsService {

  constructor(private http: HttpClient, private config: ConfigService) { }

  public GetSteps(pre_bill_id: string): Observable<PreBillStepsModel> {
    return this.http.get<PreBillStepsModel>(this.config.getApiRoute('api/v3/client-portal/pre-bills/' + pre_bill_id + '/steps')).pipe(
        map(data => {
            return data;
        }),
        catchError((err: HttpErrorResponse) => {
            return throwError(err.message);
        }));
  }

}
