import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { ExpectedBillModel } from 'app/models/expected-bill.model';
import { GroupModel } from 'app/models/group.model';

@Component({
  selector: 'app-group-accounts',
  templateUrl: './group-accounts.component.html',
  styleUrls: ['./group-accounts.component.scss']
})
export class GroupAccountsComponent extends NexusComponent {
  public group_model: GroupModel;
  public expected_bills_model: Array<ExpectedBillModel>;
  public show_archived: boolean = false;
  public filters: FilterDescriptor[] = [];

  constructor(protected route: ActivatedRoute) {
    super();
  }

  filter() {
    if(!this.show_archived)
    {
      this.filters = [{ field: 'archived_date', operator: 'isnull' }];
    }
    else
    {
      this.filters = [];
    }
  }

  ngOnInit() {
    this.route.parent.data.subscribe(({ group_model }) => {
      this.group_model = group_model;
    });

    this.route.data.subscribe(({ expected_bills_model }) => {
      this.expected_bills_model = expected_bills_model;
      this.filter();
    });


  }
}