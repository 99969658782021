<div class="bg-light">
    <div class="container">
        <div class="row">
            <div id="login-art" class="col-md-6 d-none d-md-flex">

            </div>
            <div class="col-md-6 text-center">
                <div id="login-wrapper">
                    <h2>Reset Password</h2>

                    <form [formGroup]="form" method="post" (ngSubmit)="submit()">
                        <div class="input-group mb-4">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <fa-icon [icon]="faKey" [fixedWidth]="true"></fa-icon>
                                </span>
                            </div>
                            <input type="password" class="form-control" placeholder="Password"
                                formControlName="password" required validateEqual="confirm_password" autocomplete="off"/>
                        </div>
                        <div class="input-group mb-4">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <fa-icon [icon]="faKey" [fixedWidth]="true"></fa-icon>
                                </span>
                            </div>
                            <input type="password" class="form-control" placeholder="Confirm Password"
                                formControlName="confirm_password" required validateEqual="password" reverse="true" autocomplete="off"/>
                        </div>
                        <p>
                            <button kendoButton [primary]="true" icon="check">Reset</button>
                        </p>
                        <nexus-form-result [model]="form_result"></nexus-form-result>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>