import { Component, OnInit, Input } from '@angular/core';
import { GroupModel } from 'app/models/group.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'nexus-group-link',
  templateUrl: './nexus-group-link.component.html',
  styleUrls: ['./nexus-group-link.component.scss']
})
export class NexusGroupLinkComponent implements OnInit {
  @Input() public enabled: Observable<boolean>;
  @Input() public group_model: GroupModel;
  @Input() public text: string;

  constructor() { }

  ngOnInit() {
  }

}
