import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PropertyFileModel } from 'app/models/property-file.model';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class PropertyFilesService {

  constructor(private http: HttpClient, private config: ConfigService) { }

  
  public GetPropertyFiles():Observable<Array<PropertyFileModel>>{
    return this.http.get<Array<PropertyFileModel>>(this.config.getApiRoute('api/v3/client-portal/property-files')).pipe(
      map(data => {
        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err.error.message);
      }));
  }

  public GetPropertyFile(property_file_id:string):Observable<PropertyFileModel>{
    return this.http.get<PropertyFileModel>(this.config.getApiRoute('api/v3/client-portal/property-files/'+property_file_id)).pipe(
      map(data => {
        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err.error.message);
      }));
  }

  public DeletePropertyFile(property_file_id:String){
    return this.http.delete(this.config.getApiRoute('api/v3/client-portal/property-files/'+property_file_id)).pipe(
      map(data => {
        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err.error.message);
      }));
  }
  
  public SavePropertyFile(model:PropertyFileModel):Observable<PropertyFileModel>{
    if (model.id) {
      return this.http.put<PropertyFileModel>(this.config.getApiRoute('api/v3/client-portal/property-files/' + model.id), model).pipe(
        map(data => {
          return data;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err.error.message);
        }));
    }
    else {
      return this.http.put<PropertyFileModel>(this.config.getApiRoute('api/v3/client-portal/property-files'), model).pipe(
        map(data => {
          return data;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err.error.message);
        }));
    }

}

}
