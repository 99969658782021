import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerModel } from 'app/models/customer.model';
import { EmailModel } from 'app/models/email.model';

@Component({
  selector: 'app-customer-emails',
  templateUrl: './customer-emails.component.html',
  styleUrls: ['./customer-emails.component.scss']
})
export class CustomerEmailsComponent implements OnInit {

  public emails_model: Array<EmailModel>
  public customer_model: CustomerModel
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.parent.data.subscribe(({ customer_model }) => {
      this.customer_model = customer_model;
      
    });
    this.route.data.subscribe(({ emails_model }) => {
      this.emails_model = emails_model;
    });
  }

}
