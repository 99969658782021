<nexus-grid [data]="groups_model">
  <kendo-grid-column field="group.address1" title="Address1">
    <ng-template kendoGridCellTemplate let-dataItem>
      <nexus-group-link [group_model]="dataItem.group" [text]="dataItem.group?.address1">
      </nexus-group-link>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="group.address2" title="Unit">
    <ng-template kendoGridCellTemplate let-dataItem>
      <nexus-group-link [group_model]="dataItem.group" [text]="dataItem.group?.address2">
      </nexus-group-link>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="group.city" title="City"></kendo-grid-column>
  <kendo-grid-column field="group.region.name" title="State"></kendo-grid-column>
  <kendo-grid-column field="group.zip5" title="Zip"></kendo-grid-column>
  <kendo-grid-column field="start_date" title="Start Date" format="{0:MM/dd/yyyy}" filter="date">
  </kendo-grid-column>
  <kendo-grid-column field="move_out_date" title="Move Out Date" format="{0:MM/dd/yyyy}" filter="date">
  </kendo-grid-column>
  <kendo-grid-column field="end_date" title="End Date" format="{0:MM/dd/yyyy}" filter="date">
  </kendo-grid-column>
  <kendo-grid-column field="active" title="Active"></kendo-grid-column>
</nexus-grid>
