import { Component } from '@angular/core';
import { GroupModel } from 'app/models/group.model';
import { NexusFormResultModel } from 'app/models/nexus-form-result.model';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RegionModel } from 'app/models/region.model';
import { GroupOwnerModel } from 'app/models/group-owner.model';
import { GroupOwnerType } from 'app/enums/group-owner-type.enum';
import { EnumModel } from 'app/models/enum.model';
import { Observable } from 'rxjs';
import { EnumService } from 'app/core/services/enum.service';
import { ProviderModel } from 'app/models/provider.model';
import * as moment from 'moment-business-days';
import { v4 as uuid } from 'uuid';
import { ExpectedBillModel } from 'app/models/expected-bill.model';
import { GroupService } from 'app/services/group.service';
import { CreateGroupGetModel } from 'app/models/create-group.model';
import { EventService } from 'app/core/services/event.service';
import { MembershipService } from 'app/core/services/membership.service';
import { SessionModel } from 'app/models/session.model';

@Component({
  selector: 'app-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.scss']
})
export class CreateGroupComponent extends NexusComponent {

  public regions_model: Array<RegionModel>;
  public create_group_model: CreateGroupGetModel;
  public group_model: GroupModel = new GroupModel();
  public group_result: NexusFormResultModel = new NexusFormResultModel();
  public GroupOwnerType = GroupOwnerType;
  public group_owner_types: Observable<Array<EnumModel>>;
  public is_priority: boolean;
  public session: SessionModel = null;
  
  public group_form = new UntypedFormGroup({
    address1: this.CreateRequiredFormControl(),
    address2: this.CreateFormControl(),
    city: this.CreateRequiredFormControl(),
    region_id: this.CreateRequiredFormControl(),
    zip5: this.CreateRequiredFormControl(),
    transition_start_date: this.CreateFormControl(),
    notes: this.CreateFormControl(),
    priority: this.CreateFormControl()
  });

  public group_owner_form = new UntypedFormGroup({
    group_owner_id: this.CreateFormControl(),
    name: this.CreateRequiredFormControl(),
    type: this.CreateFormControl(),
    legal_name: this.CreateFormControl(),
    tax_id_number: this.CreateFormControl(),
    birth_date: this.CreateFormControl()
  });

  public provider_form = new UntypedFormGroup({
  });

  constructor(private route: ActivatedRoute, private router: Router, private enum_service: EnumService, private group_service: GroupService, private membership: MembershipService, public event_service: EventService) {
    super();
    this.group_owner_types = this.enum_service.GetEnum('GroupOwnerType');
    this.event_service.on('session-start', (model) => {
      this.session = model;
    });
    this.event_service.on('session-end', () => {
      this.session = null;
    });
  }

  ngOnInit(): void {
    
    this.membership.GetSession().subscribe(
      data => {
        this.session = data;
      },
      error => {
        this.session = null;
      });      
    this.route.data.subscribe(({ regions_model, create_group_model }) => {
      this.regions_model = regions_model;
      this.create_group_model = create_group_model;

      for (let category of this.create_group_model.provider_categories) {
        let empty = new ProviderModel();
        empty.id = uuid();
        empty.name = 'None';
        category.providers.push(empty);

        this.provider_form.addControl(category.id + "_provider_id", this.CreateFormControl());
        this.provider_form.addControl(category.id + "_onboarding_active", this.CreateFormControl());
      }
    });
   
    this.group_form.valueChanges.subscribe((form: any) => {         
      this.PopulateModelFromForm(form, this.group_model);
    });

    if(this.session.current_property.market.group_transitions_enabled)
    {     
      this.SetFormControlValidator(this.group_form.get('transition_start_date'), Validators.required);
    }

    this.group_form.get('transition_start_date').valueChanges.subscribe((usd: Date) =>{
      this.is_priority = usd < moment(this.create_group_model.priority_date).startOf('day').toDate();
      if(this.is_priority)
      {
        this.SetFormControlValidator(this.group_form.get('priority'), Validators.requiredTrue);
      }
      else
      {
        this.SetFormControlValidator(this.group_form.get('priority'), Validators.nullValidator);
      }
    });

    this.group_owner_form.get('group_owner_id').valueChanges.subscribe(group_owner_id => {
      this.group_model.group_owner_id = group_owner_id;
      this.ClearFormControlValidator(this.group_owner_form.get('name'));
      this.ClearFormControlValidator(this.group_owner_form.get('type'));
      if(group_owner_id)
      {
        this.SetFormControlValidator(this.group_owner_form.get('name'), Validators.required);
        this.SetFormControlValidator(this.group_owner_form.get('type'), Validators.required);
      }
    });

    this.group_owner_form.valueChanges.subscribe((form: any) => {
      if (this.group_model.group_owner == null) {
        this.group_model.group_owner = new GroupOwnerModel();
      }
      this.PopulateModelFromForm(form, this.group_model.group_owner);
    });
  }

  submit(event) {
    this.group_result.Reset();
    
    this.SetFormTouched(this.group_form);
    this.SetFormTouched(this.group_owner_form);
    this.SetFormTouched(this.provider_form);

    if (this.group_form.invalid) {
      this.group_result.Incomplete();
      return;
    }

    if (!this.group_model.group_owner_id) {
      if (this.group_owner_form.invalid) {
        this.group_result.Incomplete();
        return;
      }
    }

    if (this.provider_form.invalid) {
      this.group_result.Incomplete();
      return;
    }

    this.group_model.expected_bills = new Array<ExpectedBillModel>();

    for (let category of this.create_group_model.provider_categories) {
      if (this.provider_form.get(category.id + "_provider_id").value) {
        let expected_bill = new ExpectedBillModel();
        expected_bill.provider_id = this.provider_form.get(category.id + "_provider_id").value;
        expected_bill.onboarding_active = this.provider_form.get(category.id + "_onboarding_active").value || false;

        this.group_model.expected_bills.push(expected_bill);
      }
    }  

    this.StartSpinner();
    this.group_service.SaveGroup(this.group_model).subscribe(
      data => {
        this.group_model = data;
        this.StopSpinner();
        this.group_result.Success('Group Saved!');
        this.router.navigate(['/groups']);
      },
      err => {
        this.group_result.Error(err);
        this.StopSpinner();
      });
  }

}
